import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import {
  UserProvider,
  AlertProvider,
  NotificationsProvider,
  ChatProvider,
} from "context";
import { Router } from "../";
import {
  AlertsContainer,
  ScrollToTop,
  CookiesContainer,
  OfflineAlert,
  OffersAlert,
} from "components";
import Header from "Views/Header";
import Footer from "Views/Footer";
import { useCacheBuster } from "hooks";

const App = () => {
  const [show, setShow] = useState(!navigator.onLine);
  const [loading, isLatestVersion, refreshCacheAndReload] = useCacheBuster();

  if (!loading && !isLatestVersion) {
    refreshCacheAndReload();
  }

  return (
    loading || (
      <UserProvider>
        <AlertProvider>
          <ChatProvider>
            <NotificationsProvider>
              <BrowserRouter>
                {show && <OfflineAlert handleClose={() => setShow(false)} />}
                <CookiesContainer />
                <Header />
                <ScrollToTop />
                <OffersAlert />
                <div className="center-container">
                  <Router />
                  <AlertsContainer />
                </div>
                <Footer />
              </BrowserRouter>
            </NotificationsProvider>
          </ChatProvider>
        </AlertProvider>
      </UserProvider>
    )
  );
};

export default App;
