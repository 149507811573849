import React, { useState, useRef, useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { changeUrl, addVideo } from "components/VideoField/functions";
import { FormGroup } from "components";
import { AlertContext } from "context";

const SendVideo = ({ id, token, refresh, isNew }) => {
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");
  const [validated, setValidated] = useState(false);
  const alertC = useRef(useContext(AlertContext));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      try {
        if (isNew) {
          await addVideo(token, { name: id, url });
          alertC.current.showAlert("Pomyślnie dodano film.", "success");
        } else {
          await changeUrl(token, { name: id, url });
          alertC.current.showAlert("Pomyślnie zmieniono film.", "success");
        }
        setShow(false);
        refresh();
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
    }
    setValidated(true);
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title as="h3">
            {isNew ? "Dodaj film" : "Zmień film"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            as={Form}
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e)}
          >
            <FormGroup
              type="text"
              val={url}
              setVal={setUrl}
              header="Podaj link do filmu"
              required
              incorrect="To pole jest wymagane."
              id="url"
            />

            <Button type="submit" variant="primary" className="mt-2">
              Prześlij
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Button
        variant="primary"
        className={`${!isNew && "mt-3"}`}
        onClick={() => setShow(true)}
      >
        {isNew ? "Dodaj film" : "Zmień film"}
      </Button>
    </>
  );
};

export default SendVideo;
