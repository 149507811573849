import { useState, useEffect, useCallback } from "react";
import packageJson from "../../../package.json";

global.appVersion = packageJson.version;

// npm version patch — for releases with only bug fixes
// npm version minor — for releases with new features w/ or w/o bug fixes
// npm version major — for major releases or breaking features

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const useCacheBuster = (props) => {
  const [cacheObject, setCacheObject] = useState({
    loading: true,
    isLatestVersion: false,
  });

  const { loading, isLatestVersion } = cacheObject;

  const refreshCacheAndReload = useCallback(() => {
    console.log("Clearing cache and hard reloading...");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
  }, []);

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );
          setCacheObject({ loading: false, isLatestVersion: false });
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          setCacheObject({ loading: false, isLatestVersion: true });
        }
      });
  }, []);

  return [loading, isLatestVersion, refreshCacheAndReload];
};

export default useCacheBuster;
