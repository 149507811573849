import React, { useState, useContext, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FormGroup } from "components";
import { sendSection } from "Views/ContactFoundation/ContactFoundation.api";
import { AlertContext } from "context";

const SectionForm = ({
  handleClose,
  show,
  token,
  section,
  handleRefresh,
  disabled,
  setDisabled,
}) => {
  const [title, setTitle] = useState(section?.title || "");
  const [validated, setValidated] = useState(false);

  const alertC = useRef(useContext(AlertContext));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setDisabled(true);
      try {
        await sendSection(token, title, section?.id);
        alertC.current.showAlert(
          section
            ? "Pomyślnie edytowano sekcję."
            : "Pomyślnie przesłano sekcję.",
          "success"
        );
        handleRefresh();
        handleClose();
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDisabled(false);
    }
    setValidated(true);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{section ? "Edytuj sekcję" : "Dodaj sekcję"}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        as={Form}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <FormGroup
          header="Nazwa sekcji"
          val={title}
          setVal={setTitle}
          id="title"
          required
          incorrect="Nazwa sekcji jest wymagana."
          length={{ min: 1, max: 100 }}
        />
        <Button variant="primary" type="submit">
          Prześlij
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SectionForm;
