const paths = {
  DASHBOARD: "/",
  CV_CREATOR: "/cvEditor",
  CV_EDITOR: "/cvEditor/:id",
  REGISTER: "/newAccount/",
  REGISTER_ADMIN: "/newAccount/:role(staff)",
  REGULATIONS: "/regulations",
  FOOTER: "/footer",
  LOGIN: "/login",
  USER: "/user",
  OFFER_FORM: "/offerForm/:id?",
  JOB_OFFERS: "/jobOffers",
  CV_APPROVAL: "/cvApproval",
  MY_OFFERS: "/myOffers/:id?",
  CONTACT_PAGE: "/safetyPhones",
  BLOG_PAGE_FILTERED: "/blog/:cat",
  BLOG_FORM: "/blog/newPost",
  BLOG_EDIT: "/blog/newPost/:id/",
  JOB_OFFER_DETAILS: "/jobOffers/:id",
  MY_CVS: "/myCVs",
  USER_APPROVAL: "/userApproval",
  CV_CORRECTION: "/cvCorrection/:id",
  STEPS: "/steps",
  CHATS: "/chats",
  CHAT_DETAILS: "/chats/:id",
  USER_LIST: "/userList",
  OFFER_APPROVAL: "/offerApproval",
  CHANGE_DATA: "/changeData/:id",
  PASSWORD_RESET: "/newPassword",
  NEW_PASSWORD: "/newPassword/:id",
  BLOGS: "/blogs",
  BLOG: "/blogs/:id",
  BLOG_POST: "/blogs/post/:id",
  BLOG_SELECT: "/blog/create/newSelect",
  BLOG_WORKSHOP: "/blog/create/newWorkshop",
  BLOG_WORKSHOP_EDIT: "/blog/edit/workshop/:id",
  ASK_EXPERT: "/askExpert",
  ERROR: "/error",
  JOB_OFFERS_ENUMS_CREATOR: "/jobEnumsCreator",
  KEYS_GENERATOR: "/keysGenerator",
  WORKSHOPS: "/workshops",
  WORKSHOP_POST: "/workshops/post/:id",
  OFFLINE: "/offline",
  APPLICATIONS: "/applications",
  PROJECT: "/aboutProject",
  CONTACT_FOUNDATION: "/contact",
  DOCUMENTS_EDITOR: "/documents",
  PERMISSION_CHANGE: "/permissionChange/:id",
  CALENDAR: "/calendar",
  ACCEPT_USER: "/facilityVerification/:id",
  RESEND_EMAIL_VERIFICATION: "/resendEmail",
  TERMS_ACCEPTED: "/termsAcceptance/:id",
};
export { paths };
