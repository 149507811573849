import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "context";
import { Container } from "react-bootstrap";
import { getBlogs } from "./api";
import header from "assets/blog/blogs_background.svg";
import BlogCategory from "./components/BlogCategory";
import { ManageBlogsButton } from "./components/ManageBlogsButton/ManageBlogsButton";
import { NewBlogButton } from "./components/NewBlogButton/NewBlogButton";
import { staffTypes } from "constants/staffTypes";

const Blogs = () => {
  const user = useContext(UserContext);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const loadBlogs = async () => {
      setLoading(true);
      try {
        let res = await getBlogs(abortController.signal);
        for (let i = 0; i < res.length; i++)
          if (res[i]?.description?.length > 80)
            res[i].description = res[i].description.substring(0, 80) + "...";
        setBlogs(res);
      } catch (e) {
        console.log(e);
        if (!abortController.signal.aborted) {
          setError(true);
        }
      }

      setLoading(false);
    };
    loadBlogs();
    return () => abortController.abort();
  }, []);

  return (
    <Container className="font-only blogs-background">
      <img src={header} className="w-100" alt={"blogs_background"} />
      {loading ? (
        <div className="blogs-loading">ŁADOWANIE</div>
      ) : !error ? (
        <div className="padding__text">
          {blogs.map((blog) => (
            <BlogCategory blog={blog} key={blog.id} />
          ))}
          {blogs.length === 0 ? (
            <div className="blogs-loading">BRAK BLOGÓW</div>
          ) : null}

          {user?.data?.group_type?.includes(staffTypes.BLOG_CREATOR) ? (
            <div className="blogs__buttonsWrapper">
              <NewBlogButton user={user} />
              <ManageBlogsButton user={user} />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="blogs-loading">WYSTĄPIŁ BŁĄD</div>
      )}
    </Container>
  );
};

export default Blogs;
