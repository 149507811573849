import React, { useState, useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Form, Container, Row, Col, Button } from "react-bootstrap";
import { FormGroup, Alert } from "components";
import { useMutation, useQuery } from "hooks";
import { sendConfirmation } from "./ConfirmUser.api";
import { getDocuments } from "Views/DocumentsEditor/DocumentsEditor.api";
import snakecaseKeys from "snakecase-keys";
import documents from "constants/documents";
import proxy from "config/api";
const { GENERAL_POLICY } = documents;

const ACCEPT = "accept";
const REJECT = "reject";

const ConfirmUser = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const history = useHistory();

  const { id } = useParams();

  const [data, error, loading] = useQuery([], getDocuments);

  const policyUrl = useMemo(
    () => data.find((document) => document.id === GENERAL_POLICY)?.document_url,
    [data]
  );
  const LinkWrapper = useCallback(
    ({ children }) =>
      policyUrl ? (
        <a
          href={`${proxy.plainFiles}${policyUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-1"
          data-testid="link"
        >
          {children}
        </a>
      ) : (
        <span className="text-muted ml-1" data-testid="muted">
          {children}
        </span>
      ),
    [policyUrl]
  );

  const { onSubmit, validated, loadingConf } = useMutation(
    (params) =>
      sendConfirmation({
        data: snakecaseKeys({
          firstName,
          lastName,
        }),
        id,
        type: ACCEPT,
        ...params,
      }),
    "Pomyślnie zatwierdzono użytkownika.",
    {
      redirectFn: () => history.push("/"),
    }
  );

  return (
    <Container>
      <Card>
        <Card.Header as="h2">Potwierdź rejestrację podopiecznego</Card.Header>
        <Card.Body>
          <p>
            {`Ja, niżej podpisany, wyrażam zgodę na rejestrację mojego podopiecznego na stronie usamodzielnieni.pl.`}
          </p>
          <Row as={Form} noValidate validated={validated} onSubmit={onSubmit}>
            <Col md={4}>
              <FormGroup
                val={firstName}
                setVal={setFirstName}
                header="Imię"
                showHeader={false}
                id="first_name"
                required
                length={{ min: 1, max: 60 }}
                incorrect="To pole jest wymagane."
              />
            </Col>
            <Col md={4}>
              <FormGroup
                val={lastName}
                setVal={setLastName}
                header="Nazwisko"
                showHeader={false}
                id="last_name"
                required
                length={{ min: 1, max: 60 }}
                incorrect="To pole jest wymagane."
              />
            </Col>
            <Col md={12}>
              <p className="text-justify">
                Właścicielem Portalu internetowego www.usamodzielnieni.pl jest
                Fundacja One Day z siedzibą w Warszawie (02- 778), Filipiny
                Płaskowickiej 46/79, wpisana do Rejestru Stowarzyszeń Krajowego
                Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st.
                Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego
                Rejestru Sądowego, pod numerem KRS: 0000530905, posiadająca
                numer NIP: 9512385835, posiadająca status organizacji pożytku
                publicznego (dalej "Fundacja").
                <LinkWrapper
                  href={`${proxy.plainFiles}${policyUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1"
                >
                  Link do pełnej Polityki Prywatności znajduje się tutaj.
                </LinkWrapper>
              </p>
            </Col>
            <Col md={12} className="mb-4">
              <Button
                data-testid="submit"
                type="submit"
                className="confirmUser__button mr-3"
              >
                Zatwierdź
              </Button>
            </Col>
            <Col>
              <p className="text-justify">
                Aby zablokować podopiecznemu dostęp do aplikacji, kliknij
                poniższy przycisk:
              </p>
              <Button
                data-testid="submit-reject"
                variant="danger"
                type="button"
                className="confirmUser__button"
                onClick={(e) =>
                  onSubmit(e, {
                    message: "Pomyślnie odrzucono użytkownika.",
                    type: REJECT,
                  })
                }
              >
                Zablokuj
              </Button>
            </Col>
          </Row>
          <Alert loading={loadingConf || loading} error={error} />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ConfirmUser;
