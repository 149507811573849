import proxy from "config/api";
import { errorMessage } from "constants/other";

export const getPosts = async (token, category, topic, signal) => {
  let url =
    topic === null
      ? proxy.blog + "category/" + category + "/posts/?no_topic=true"
      : proxy.blog + "category/" + category + "/posts/?topic=" + topic;

  const Authorization = token ? `Token ${token}` : null;
  const headers = {
    "Content-Type": "application/json",
    Authorization,
  };

  const response = await fetch(url, { method: "GET", headers, signal });

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401) {
    throw response.status;
  } else {
    throw new Error(await errorMessage(response));
  }
};

export const getMorePosts = async (token, url) => {
  const Authorization = token ? `Token ${token}` : null;
  const headers = {
    "Content-Type": "application/json",
    Authorization,
  };

  const response = await fetch(url, { method: "GET", headers });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw response.status;
  }
};

export const getTopics = async (category, signal) => {
  let url = proxy.blog + "category/" + category + "/topics/";
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers, signal });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw response.status;
  }
};

export const getBlogs = async (signal) => {
  let url = proxy.blog + "categories/";
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers, signal });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error(await errorMessage(response));
  }
};
