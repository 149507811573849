import React, { useEffect, useState, useRef, useContext } from "react";
import { Modal, Button, Alert, Form } from "react-bootstrap";
import { FormGroup } from "components";
import { AlertContext, UserContext } from "context";
import { getWorkshops, sendEvent } from "Views/Calendar/Calendar.api";
import { getWorkshops as getLabels } from "Views/Workshops/api";
import {
  TAG_PLACEHOLDER,
  filterWorkshops,
  getISODate,
  EVENT_PLACEHOLDER,
} from "constants/calendarDays";
import { returnUrl } from "constants/other";

const EventForm = ({ show, handleClose, labels, event, handleRefresh }) => {
  const [name, setName] = useState(event?.name || "");
  const [label, setLabel] = useState(event?.label?.id || -1);
  const [url, setUrl] = useState(
    event?.url?.includes(returnUrl()) ? event?.url : ""
  );
  const [dateStart, setDateStart] = useState(
    event ? new Date(event?.date_start) : new Date()
  );
  const [dateEnd, setDateEnd] = useState(
    event ? new Date(event?.date_end) : new Date()
  );
  const [timeStart, setTimeStart] = useState(event?.time_start || "12:00");
  const [timeEnd, setTimeEnd] = useState(event?.time_end || "12:01");

  const [checked, setChecked] = useState(
    event ? !event?.url?.includes(returnUrl()) : false
  );
  const [external, setExternal] = useState(
    event?.url?.includes(returnUrl()) ? "" : event?.url
  );

  const [validated, setValidated] = useState(false);
  const [workshops, setWorkshops] = useState([]);

  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchWorkshops = async () => {
      if (!abortController.signal.aborted) {
        setLoading(true);
      }
      const actualDate = new Date();
      try {
        let res = await getWorkshops(abortController.signal);
        const resLabels = await getLabels(abortController.signal);
        res = await getWorkshops(abortController.signal, res.count);
        if (!abortController.signal.aborted) {
          const workshops = filterWorkshops(
            res.results,
            resLabels,
            actualDate.getFullYear()
          );

          setWorkshops(workshops);
        }
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(err.message);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    };
    fetchWorkshops();
    return () => abortController.abort();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      const date_start = getISODate(dateStart);
      const date_end = getISODate(dateEnd);

      const eventObject = {
        name,
        label: label !== -1 ? label : null,
        url: checked
          ? external
          : url.includes(returnUrl())
          ? url
          : `${returnUrl()}${url}`,
        date_start,
        date_end,
        time_start: timeStart,
        time_end: timeEnd,
      };

      try {
        await sendEvent(user.token, eventObject, event?.id);
        alertC.current.showAlert("Pomyślnie przesłano wydarzenie", "success");
        handleClose();
        handleRefresh();
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
    }
    setValidated(true);
  };

  const message = error ? (
    <Alert variant="danger">{error.message}</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : null;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Dodaj wydarzenie</Modal.Title>
      </Modal.Header>
      <Modal.Body
        as={Form}
        onSubmit={handleSubmit}
        validated={validated}
        noValidate
      >
        <FormGroup
          id="event_name"
          val={name}
          setVal={setName}
          header="Nazwa wydarzenia"
          length={{ min: 1, max: 120 }}
          required
          incorrect="Nazwa wydarzenia jest wymagana."
        />
        <Form.Group controlId="event_tag">
          <Form.Label>Etykieta</Form.Label>
          <Form.Control
            as="select"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          >
            <option key={-1} value={-1} disabled>
              {TAG_PLACEHOLDER}
            </option>
            {labels.map((label) => (
              <option key={label.id} value={label.id}>
                {label.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="event_url">
          <Form.Label>Link do wydarzenia</Form.Label>
          <Form.Control
            as="select"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            disabled={checked}
          >
            <option key={EVENT_PLACEHOLDER} value={""} disabled>
              {EVENT_PLACEHOLDER}
            </option>
            {workshops.map(({ year, url, title }) => (
              <option key={url} value={url}>
                {`${title} - ${year}`}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="event_checkUrl">
          <Form.Check
            type="checkbox"
            label="Link zewnętrzny"
            checked={checked}
            onChange={() => setChecked((prevState) => !prevState)}
          />
        </Form.Group>
        {checked && (
          <FormGroup
            header="Link do strony zewnętrznej"
            id="event_externalUrl"
            val={external}
            setVal={setExternal}
            length={{ min: 1, max: 300 }}
          />
        )}
        <FormGroup
          id="event_date_start"
          type="date"
          val={dateStart}
          setVal={(value) => {
            setDateStart(value);
            if (value > dateEnd) {
              setDateEnd(value);
            }
          }}
          header="Data rozpoczecia wydarzenia"
        />
        <FormGroup
          id="event_date_end"
          type="date"
          val={dateEnd}
          setVal={setDateEnd}
          dateMin={dateStart}
          header="Data zakończenia wydarzenia"
        />
        <FormGroup
          id="event_time_start"
          type="time"
          val={timeStart}
          setVal={setTimeStart}
          header="Godzina rozpoczęcia:"
          required
          incorrect="Data rozpoczęcia jest wymagana."
        />
        <FormGroup
          id="event_time_end"
          type="time"
          val={timeEnd}
          setVal={setTimeEnd}
          header="Godzina zakończenia:"
          required
          incorrect="Data zakończenia jest wymagana."
        />
        {message}
        <Button type="submit" className="mt-3">
          Prześlij
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default EventForm;
