import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const getContacts = async (signal) => {
  const url = `${proxy.contactFoundation}sections/`;

  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { headers, method: "GET", signal });

  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const sendSection = async (token, title, id) => {
  const url = id
    ? `${proxy.contactFoundation}section/${id}/`
    : `${proxy.contactFoundation}section/new/`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    headers,
    method: id ? "PATCH" : "POST",
    body: JSON.stringify({ title }),
  });

  if (response.status === 200 && id) {
    return true;
  }
  if (response.status === 201 && !id) {
    return true;
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const sendContact = async (token, contact, id) => {
  const url = id
    ? `${proxy.contactFoundation}${id}/`
    : `${proxy.contactFoundation}new/`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    headers,
    method: id ? "PATCH" : "POST",
    body: JSON.stringify(contact),
  });

  if (response.status === 200 && id) {
    return true;
  }
  if (response.status === 201 && !id) {
    return true;
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const deleteContact = async (token, id) => {
  const url = `${proxy.contactFoundation}${id}/`;

  const headers = {
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    headers,
    method: "DELETE",
  });

  if (response.status === 204) {
    return true;
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const deleteSection = async (token, id) => {
  const url = `${proxy.contactFoundation}section/${id}/`;

  const headers = {
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    headers,
    method: "DELETE",
  });

  if (response.status === 204) {
    return true;
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const moveSection = async (token, dir, id) => {
  const url = `${proxy.contactFoundation}section/${id}/${dir}/`;

  const headers = {
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    headers,
    method: "PATCH",
  });

  if (response.status === 204) {
    return true;
  } else {
    throw new Error(await errorMessageText(response));
  }
};
