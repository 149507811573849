import React, { useState, useEffect, useRef, useContext } from "react";
import { Card, Button, Alert, Accordion } from "react-bootstrap";

import { DeletionModal } from "components";
import { ExpertCreator } from "../";
import { deleteExpert } from "../../functions";

import { AlertContext } from "context";

const ExpertList = ({ token, refresh, experts, error, loading }) => {
  const alertC = useRef(useContext(AlertContext));

  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);

  const [idDel, setIdDel] = useState(-1);

  const handleDelModal = (id) => {
    setShowModal(true);
    setIdDel(id);
  };

  useEffect(() => {
    const handleDelete = async (id) => {
      try {
        await deleteExpert(token, id);
        alertC.current.showAlert("Poprawnie usunięto eksperta.", "success");
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDeletionConfirmed(false);
      refresh((prevState) => !prevState);
    };

    if (deletionConfirmed) handleDelete(idDel);
  }, [deletionConfirmed, idDel, refresh, token]);

  const message = error ? (
    <Alert variant="danger">Błąd pobierania listy ekspertów</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : experts.length === 0 ? (
    <Alert variant="info">Brak dodanych ekspertów.</Alert>
  ) : null;

  return (
    <Accordion>
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDeletionConfirmed}
        question={"Czy na pewno chcesz usunąć tego eksperta?"}
      />

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h4>Lista ekspertów</h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Accordion>
            <Card.Body className="px-0">
              {message ||
                experts.map((data) => (
                  <div className="mb-3" key={data.id}>
                    <div className="expertList__item__titleWrapper">
                      <h4>{`${data.first_name} ${data.last_name}${
                        data.role ? ` - ${data.role}` : ""
                      } `}</h4>
                      <Button
                        className="ml-2"
                        variant="danger"
                        onClick={() => handleDelModal(data.id)}
                      >
                        X
                      </Button>
                    </div>
                    <ExpertCreator
                      key={data.id}
                      data={data}
                      token={token}
                      refresh={refresh}
                    />
                  </div>
                ))}
            </Card.Body>
          </Accordion>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ExpertList;
