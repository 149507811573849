import React from "react";
import logo from "assets/main_photo.png";

const MainPhoto = () => {
  return (
    <div className="mainPhoto">
      <img src={logo} alt="Logo strony Usamodzielnieni" />
    </div>
  );
};

export default MainPhoto;
