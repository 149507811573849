import proxy from "config/api";
import { errorMessageText } from "constants/other.js";

export const sendData = async (credentials) => {
  const url = proxy.account + "login/";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(credentials),
    headers: {
      "Content-Type": "application/json",
      Origin: null,
    },
  });

  if (response.status === 200) {
    const data = await response.json().then((data) => data);
    return {
      status: response.status,
      ...data,
    };
  } else {
    throw response.status;
  }
};

export const checkOffersCount = async (token) => {
  const url = `${proxy.job}user/offers-in-city/`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
      Origin: null,
    },
  });

  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};
