import React, { useEffect, useState } from "react";
import { Accordion, Card, Button, Alert } from "react-bootstrap";
import { getCategories } from "Views/BlogPostCreator/functions";
import { CategoryTile } from "../";

const CategoryChooser = ({ token, categoryActive, setCategoryActive }) => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const res = await getCategories(token);
        setCategories(res);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    };
    fetchCategories();
  }, [token]);

  const message = error ? (
    <Alert variant="danger">Nie udało się załadować kategorii</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : categories.length === 0 ? (
    <Alert variant="info">Brak kategorii</Alert>
  ) : null;

  return (
    <Accordion className="mt-3">
      <Card>
        <Card.Header className="categoryChooser__header">
          <Accordion.Toggle
            as={Button}
            variant="link"
            className="p-0"
            eventKey="0"
          >
            Wybierz kategorię
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <div className="categoryTile__wrapper">
            {message ||
              categories.map((category) => (
                <CategoryTile
                  category={category}
                  key={category.id}
                  active={categoryActive}
                  setActive={setCategoryActive}
                />
              ))}
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default CategoryChooser;
