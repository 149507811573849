import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import { FormGroup } from "components";
import { postStart } from "../../functions";
import { AlertContext } from "context";

const StartStepCreator = ({ show, token, handleHide, refresh }) => {
  const [title, setTitle] = useState("");
  const alertContext = useRef(useContext(AlertContext));
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        const res = await postStart(token, title);
        refresh(res?.id);
        alertContext.current.showAlert("Dodano pytanie startowe", "success");
        handleHide();
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
    }
    setValidated(true);
  };

  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>Dodaj pytanie startowe</Modal.Header>
      <Modal.Body
        noValidate
        validated={validated}
        as={Form}
        onSubmit={(e) => handleSubmit(e)}
      >
        <FormGroup
          header="Podaj tytuł"
          id="start_title"
          val={title}
          setVal={setTitle}
          required
          incorrect="To pole jest wymagane."
        />
        <Button type="submit">Prześlij</Button>
      </Modal.Body>
    </Modal>
  );
};

StartStepCreator.propTypes = {
  token: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleHide: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default StartStepCreator;
