import React, { useState, useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { approveFileSize } from "utils/approveFile/approveFile";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { FormGroup } from "components";
import { postStep, attachmentUpload } from "../../functions";
import { AlertContext } from "context";

const StepForm = ({ choice, token, show, handleHide, refresh, step }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState("");
  const [is_visible, setIsVisible] = useState(true);

  const fileInput = useRef(null);
  const [videoInput, setVideoInput] = useState("");
  const [file, setFile] = useState(null);

  const [validated, setValidated] = useState(false);
  const alertContext = useRef(useContext(AlertContext));

  useEffect(() => {
    if (step) {
      setTitle(step.title);
      setDescription(step.description || "");
      setAttachment(step.attachment_url || "");
      setIsVisible(step.is_visible);
      setVideoInput(
        step.attachment_url?.includes("https://www.youtube.com/") ||
          step.attachment_url?.includes("https://www.youtu.be/")
          ? step.attachment_url
          : ""
      );
    }
  }, [step]);

  const onPhotoChange = async (files) => {
    if (!approveFileSize(files[0])) {
      alertContext.current.showAlert(
        "Wybrany plik jest za duży. Maksymalna wielkość to 15 mb."
      );
    } else {
      setFile(files[0]);
    }
  };

  const sendPhoto = async (id) => {
    setIsLoading(true);
    try {
      await attachmentUpload(token, id, file);
      alertContext.current.showAlert("Pomyślnie przesłano zdjęcie", "success");
    } catch (err) {
      alertContext.current.showAlert(err.message);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        const data = { title, choice, is_visible };
        if (attachment) {
          data.attachment = attachment;
        }
        if (description) {
          data.description = description;
        }
        const res = await postStep(token, data, step?.id);
        if (file !== null) {
          await sendPhoto(res.id);
        }
        refresh(choice);
        alertContext.current.showAlert(
          step?.id ? "Pomyślnie edytowano krok" : "Pomyślnie dodano krok",
          "success"
        );
        handleHide();
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
    }
    setValidated(true);
  };

  const submitVideo = async () => {
    if (
      videoInput.includes("https://www.youtube.com/") ||
      videoInput.includes("https://www.youtu.be/")
    ) {
      setAttachment(videoInput);
      setFile(null);
      alertContext.current.showAlert("Zaakceptowano film.", "success");
    } else {
      alertContext.current.showAlert(
        "Dodałeś niepoprawny film, link powinien zaczynać się od: https://www.youtube.com/ || https://www.youtu.be/"
      );
    }
  };

  const message = isLoading ? (
    <Alert className="mt-3" variant="info">
      Trwa dodawanie zdjęcia...
    </Alert>
  ) : null;

  const createTitle = step?.id ? "Edytuj krok" : "Stwórz nowy krok";

  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>{createTitle}</Modal.Header>
      <Modal.Body
        noValidate
        validated={validated}
        as={Form}
        onSubmit={(e) => handleSubmit(e)}
      >
        <FormGroup
          header="Podaj tytuł"
          id="step_title"
          val={title}
          setVal={setTitle}
          required
          incorrect="To pole jest wymagane."
        />
        <FormGroup
          header="Podaj opis"
          id="step_description"
          length={{ min: 1 }}
          val={description}
          setVal={setDescription}
          type="textarea"
        />
        <Form.Check
          inline
          label="Pokaż opis"
          type="checkbox"
          className="mb-3"
          checked={is_visible}
          onChange={() => setIsVisible((prevState) => !prevState)}
          id={"isVisibleCheck"}
        />
        <div>
          <Form.File
            name="photo"
            id="steps_header_photo"
            label={file?.name ? file.name : "Wybierz zdjęcie na nagłówek kroku"}
            custom
            ref={fileInput}
            onChange={(e) => onPhotoChange(e.target.files)}
            accept="image/*"
            data-browse="Dodaj"
          />
          {message}

          <p className="my-1">Lub:</p>

          <Form.Control
            data-testid="videoField"
            value={videoInput}
            className="mb-1"
            onChange={(e) => setVideoInput(e.target.value)}
            placeholder="Np. https://www.youtube.com/watch?v=K9wbrl-0SJE"
          />
          <Button className="blogCreator__button mb-3" onClick={submitVideo}>
            Dodaj film
          </Button>
        </div>
        <Button type="submit">Prześlij</Button>
      </Modal.Body>
    </Modal>
  );
};

StepForm.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    index: PropTypes.number,
  }),
  token: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  choice: PropTypes.number.isRequired,
  handleHide: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default StepForm;
