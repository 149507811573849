import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Items = ({ items, onCutClick, getItemId, getItemName }) => (
  <ul className="list-inline">
    {items.map((item, i) => (
      <li key={getItemId(item)} className="list-inline-item">
        <Button
          variant="dark"
          id={getItemId(item)}
          className="list-inline-item"
          onClick={() => onCutClick(i)}
        >
          {getItemName(item)} <FontAwesomeIcon icon={faTimes} />
        </Button>
      </li>
    ))}
  </ul>
);

export default Items;
