import { uploadPhoto } from "../../functions";
import proxy from "config/api";
const controller = new AbortController();
const { signal } = controller;

class ImageAdapter {
  constructor(loader, id, token, setId, setLoading, isPost) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.id = id;
    this.token = token;
    this.setId = setId;
    this.setLoading = setLoading;
    this.isPost = isPost;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._sendPhoto(file, resolve, reject);
        })
    );
  }
  // Aborts the upload process.
  abort() {
    controller.abort();
  }

  _sendPhoto = async (file, resolve, reject) => {
    this.setLoading(true);
    try {
      const res = await uploadPhoto(
        this.id,
        file,
        this.token,
        signal,
        this.isPost
      );

      this.setId((prevState) => [...prevState, res]);
      resolve({ default: `${proxy.plainFiles}${res.attachment_url}/` });
    } catch (err) {
      reject("Błąd przesłania zdjęcia: ", err);
    }
    this.setLoading(false);
  };
}

export default ImageAdapter;
