const DEFAULT_INPUT = "-- Wybierz --";

export const returnUrl = () => {
  return window.location.href.slice(
    0,
    window.location.href.search(window.location.pathname)
  );
};

function YouTubeGetID(url) {
  var ID = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
}

const errorMessage = async (response) => {
  if (response.status === 404) {
    return window.location.replace(`${returnUrl()}/error`);
  }
  return errorMessageText(response);
};

const mapObjectFunction = (message) => {
  let newError = "";
  Object.keys(message).forEach((key) => {
    return (newError += `${isNaN(parseInt(key, 10)) ? `${key}: ` : ""}${
      typeof message[key] === "object"
        ? mapObjectFunction(message[key])
        : message[key]
    } `);
  });
  return newError;
};

const errorMessageText = async (response) => {
  const messages = await response.json().then((description) => description);
  let err = "";
  err += mapObjectFunction(messages);
  return err;
};

const isOdd = (number) => number % 2;

export { DEFAULT_INPUT, errorMessage, errorMessageText, YouTubeGetID, isOdd };
