import React from "react";
import { CategoryChooser, TopicSelector } from "../";
import { Alert } from "react-bootstrap";

const PostSelects = ({
  token,
  activeTopic,
  setCategoryActive,
  setActiveTopic,
  category,
}) => {
  return (
    <>
      <CategoryChooser
        token={token}
        categoryActive={category}
        setCategoryActive={setCategoryActive}
      />
      {category ? (
        <TopicSelector
          category={category}
          token={token}
          activeTopic={activeTopic}
          setActiveTopic={setActiveTopic}
        />
      ) : (
        <Alert variant="info" className="mt-3">
          Przed wybraniem tematu wybierz kategorię
        </Alert>
      )}
    </>
  );
};

export default PostSelects;
