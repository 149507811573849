import React, { Suspense, useState, useRef, useContext } from "react";
import { Modal, Button, Alert, Form } from "react-bootstrap";
import { FormGroup } from "components";
import { AlertContext, UserContext } from "context";
import { sendLabel } from "Views/Calendar/Calendar.api";
const ColorPicker = React.lazy(() =>
  import("components/ColorPicker/ColorPicker")
);

const LabelForm = ({ show, handleClose, label, handleRefresh }) => {
  const [color, setColor] = useState(label?.color || "#F3C431");
  const [name, setName] = useState(label?.name || "");

  const [validated, setValidated] = useState(false);

  const alertC = useRef(useContext(AlertContext));

  const user = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      const labelObject = { name, color };
      try {
        await sendLabel(user.token, labelObject, label?.id);
        alertC.current.showAlert("Pomyślnie przesłano etykietę.", "success");
        handleClose();
        handleRefresh();
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
    }
    setValidated(true);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Dodaj etykietę</Modal.Title>
      </Modal.Header>
      <Modal.Body
        as={Form}
        onSubmit={handleSubmit}
        validated={validated}
        noValidate
      >
        <FormGroup
          id="label_name"
          val={name}
          setVal={setName}
          header="Nazwa"
          length={{ min: 1, max: 30 }}
          required
          incorrect="Nazwa etykiety jest wymagana."
        />
        <Form.Group controlId="label_color">
          <Form.Label>Kolor:</Form.Label>
          <Suspense fallback={<Alert variant="info">Ładowanie...</Alert>}>
            <ColorPicker background={color} setBackground={setColor} />
          </Suspense>
        </Form.Group>
        <Button type="submit" className="mt-3">
          Prześlij
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default LabelForm;
