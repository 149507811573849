import React, { useState, useEffect, useRef, useContext } from "react";
import { AlertContext } from "context";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { staffTypes } from "constants/staffTypes";
import { userTypes } from "constants/userTypes";
import PropTypes from "prop-types";
import { DeletionModal } from "components";
import { deleteStep, moveStep } from "Views/Steps/functions";
import { StepForm } from "Views/Steps/components";

const StepButtons = ({ user, step, refresh, choice, first, last }) => {
  const alertContext = useRef(useContext(AlertContext));

  const [showModal, setShowModal] = useState(false);
  const [delConfirmed, setDelConfirmed] = useState(false);

  const [showCreateStep, setShowCreateStep] = useState(false);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const handleDelete = async () => {
      setDisabled(true);
      try {
        await deleteStep(user.token, step.id);
        alertContext.current.showAlert("Poprawnie usunięto krok.", "success");
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
      setDisabled(false);
      setDelConfirmed(false);
      refresh(choice);
    };

    if (delConfirmed) handleDelete();
  }, [choice, delConfirmed, refresh, step.id, user.token]);

  const handleMove = async (dir) => {
    setDisabled(true);
    try {
      await moveStep(user.token, step.id, dir);
      alertContext.current.showAlert("Poprawnie przesunięto krok.", "success");
    } catch (err) {
      alertContext.current.showAlert(err.message);
    }
    setDisabled(false);
    refresh(choice);
  };

  return (
    user.type === userTypes.STAFF &&
    user.data.group_type.includes(staffTypes.BLOG_MODERATOR) && (
      <>
        <DeletionModal
          show={showModal}
          setShow={setShowModal}
          delConfirmed={setDelConfirmed}
          question={"Czy na pewno chcesz usunąć ten krok?"}
        />
        <StepForm
          show={showCreateStep}
          choice={choice}
          token={user.token}
          refresh={refresh}
          step={step}
          handleHide={() => setShowCreateStep(false)}
        />
        <Button
          variant="danger"
          className="mx-1"
          disabled={disabled}
          onClick={() => setShowModal(true)}
        >
          X
        </Button>
        {!first && (
          <Button
            variant="info"
            className="mr-1"
            disabled={disabled}
            onClick={() => handleMove("up")}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </Button>
        )}
        {!last && (
          <Button
            variant="info"
            className="mr-1"
            disabled={disabled}
            onClick={() => handleMove("down")}
          >
            <FontAwesomeIcon icon={faArrowDown} />
          </Button>
        )}
        <Button onClick={() => setShowCreateStep(true)}>Edytuj krok</Button>
      </>
    )
  );
};

StepButtons.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    index: PropTypes.number.isRequired,
  }),
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  choice: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default StepButtons;
