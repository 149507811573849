import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { DEFAULT_INPUT } from "constants/other";

const Filter = ({ setFilters }) => {
  const [wasRead, setWasRead] = useState(DEFAULT_INPUT);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (wasRead !== DEFAULT_INPUT) {
      setFilters((prevState) => ({
        ...prevState,
        was_read: wasRead,
      }));
    }
  };

  const clearFilters = () => {
    setFilters({
      page: 1,
      pageSize: 10,
    });
    setWasRead(DEFAULT_INPUT);
  };
  return (
    <Form className="search__form my-3" onSubmit={handleSubmit}>
      <div>
        <Form.Control
          as={"select"}
          value={wasRead}
          onChange={(e) => {
            const value = e.target.value;
            setWasRead(value);
          }}
          data-testid="filtr"
        >
          <option disabled>{"-- Wybierz --"}</option>
          <option value={true}>Przeczytane</option>
          <option value={false}>Nieprzeczytane</option>
        </Form.Control>

        <div>
          <Button type="submit" className="mr-1 mt-1">
            Filtruj
          </Button>
          <Button
            onClick={clearFilters}
            className="mt-1"
            variant="outline-primary"
          >
            Usuń filtry
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default Filter;
