import React from "react";
import proxy from "config/api";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const Contact = ({ contact }) => {
  return (
    <div className="contact__container">
      <div className="contact__wrapper">
        {contact.logo_url && (
          <img
            alt="Logo kontaktu"
            className="contact__logo"
            src={
              navigator.onLine
                ? `${proxy.plainFiles}${contact.logo_url}`
                : contact.logo_url
            }
          />
        )}
        <header className="contact__header">
          <h3 className="contact__header__title">{contact.title}</h3>
          <div className="contact__number">
            <p className="text-muted mb-0">Kliknij w numer aby zadzwonić</p>
            <a
              className="contact__phone_number"
              href={`tel:+48${contact.phone_number}`}
            >
              <FontAwesomeIcon icon={faPhone} />
              <span className="ml-1">{contact.phone_number}</span>
            </a>
          </div>
        </header>
      </div>
      <p className="contact__description">{contact.description}</p>
    </div>
  );
};

Contact.propTypes = {
  contact: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    logo_url: PropTypes.string,
    phone_number: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default Contact;
