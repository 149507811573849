import React from "react";
import { Row, Col } from "react-bootstrap";
import { IndexLinkContainer } from "react-router-bootstrap";
import proxy from "config/api";

const JobOfferInfo = ({ context, offer, ...rest }) => {
  return (
    <Row {...rest} className="job-offers-offer mx-0">
      {offer.companyLogo && (
        <Col md={3} xs={4} xl={2} className="job-offers-offer-col">
          <img
            src={`${proxy.plainFiles}${offer.companyLogo}`}
            alt="offer_img"
          />
        </Col>
      )}
      <Col
        md={offer?.companyLogo ? 7 : 10}
        xs={offer?.companyLogo ? 8 : 12}
        xl={offer?.companyLogo ? 8 : 10}
        className="job-offers-offer-col align-self-center"
      >
        <Row>
          <Col
            md={12}
            xs={12}
            className="job-offers-offer-name job-offers-offer-col"
          >
            <h4 className="jobOffer__offerTitle">{offer.title}</h4>
          </Col>
          <Col md={6} xs={6} className="p-0">
            <Col
              md={12}
              xs={12}
              className="job-offers-offer-companyName job-offers-offer-col"
            >
              {offer.companyName}
            </Col>
            <Col
              md={12}
              xs={12}
              className="job-offers-offer-salary job-offers-offer-col"
            >
              {`${offer.salaryMin.split(".")[0]} - ${
                offer.salaryMax.split(".")[0]
              } PLN`}
            </Col>
          </Col>
          <Col md={6} xs={6} className="p-0">
            <Col
              md={12}
              xs={12}
              className="job-offers-offer-description text-right job-offers-offer-col"
            >
              {offer.voivodeship}
            </Col>
            <Col
              md={12}
              xs={12}
              className="job-offers-offer-description text-right job-offers-offer-col"
            >
              {offer.companyAddress.city}
            </Col>
          </Col>
        </Row>
      </Col>
      <Col
        md={2}
        xs={12}
        className="d-flex align-items-center justify-content-center job-offers-offer-col job-offers-offer-button-background"
      >
        <IndexLinkContainer to={`/jobOffers/${offer.id}`}>
          <span className="text-align-center job-offers-offer-button">
            SPRAWDŹ
          </span>
        </IndexLinkContainer>
      </Col>
    </Row>
  );
};

export default JobOfferInfo;
