import React from "react";
import { staffTypes } from "constants/staffTypes";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";

const EditPostButton = ({ user, category, postId }) => {
  if (user?.data?.group_type?.includes(staffTypes.BLOG_CREATOR)) {
    return (
      <LinkContainer to={"/blog/newPost/" + postId}>
        <Button className="blogs-edit-button mr-3">
          <span className="align-self-center">EDYTUJ POST</span>
        </Button>
      </LinkContainer>
    );
  } else return null;
};

export default EditPostButton;
