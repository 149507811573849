import React, { useState } from "react";
import { Container, Card } from "react-bootstrap";
import { EnumForm, ListOfEnums } from "./components";

const JobOffersEnumsCreator = () => {
  const [refresh, setRefresh] = useState(false);
  const handleRefresh = () => setRefresh((prevState) => !prevState);
  return (
    <Container>
      <ListOfEnums refresh={refresh} refreshFunc={handleRefresh} />
      <Card>
        <Card.Header as="h3">Kreator kategorii/rodzajów umów</Card.Header>
        <Card.Body>
          <EnumForm type="category" refresh={handleRefresh} />
          <EnumForm type="type" refresh={handleRefresh} />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default JobOffersEnumsCreator;
