import proxy from "config/api";
import { errorMessage } from "constants/other";

export const getBlogPost = async (token, id, signal) => {
  let url = proxy.blog + "blogpost/" + id + "/";
  const Authorization = token ? `Token ${token}` : null;
  const headers = {
    "Content-Type": "application/json",
    Authorization,
  };

  const response = await fetch(url, { method: "GET", headers, signal });
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401) {
    throw response.status;
  } else {
    throw new Error(await errorMessage(response));
  }
};

export const removePost = async (id, token) => {
  let url = proxy.blog + "blogpost/" + id + "/";
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + token,
  };

  const response = await fetch(url, { method: "DELETE", headers });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw response.status;
  }
};

export const postComment = async (id, token, comment) => {
  let url = proxy.blog + id + "/comment/";
  let data = {
    content: comment,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + token,
  };

  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  if (response.status === 201) {
    return await response.json();
  } else {
    throw response.status;
  }
};

export const removeComment = async (id, token) => {
  let url = proxy.blog + "comment/" + id + "/";
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + token,
  };

  const response = await fetch(url, { method: "DELETE", headers });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw response.status;
  }
};
