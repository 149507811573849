import React from "react";
import ramka from "assets/shapes/ramka.svg";
import { ReactSVG } from "react-svg";
import proxy from "config/api";

const PostTile = ({ post, shape, cover }) => {
  return (
    <div className="postTile">
      <ReactSVG
        className="postTile__shape"
        beforeInjection={(svg) => {
          svg.querySelector("path").setAttribute("fill", post.background_color);
        }}
        src={
          shape
            ? `${proxy.plainFiles}${shape}`
            : `${proxy.plainFiles}${post.background_url}`
        }
      />

      <div className="postTile__photoWrapper">
        {(post.cover || cover) && (
          <img
            src={cover ? URL.createObjectURL(cover) : post.cover}
            alt="cover_photo"
            className="postTile__cover"
          />
        )}

        <ReactSVG src={ramka} className="postTile__border" />
      </div>
      <div className="postTile__content">
        <h4 className="text-align-center postTile__header">
          {post.title || "Podaj tytuł"}
        </h4>
      </div>
    </div>
  );
};

export default PostTile;
