import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Accordion, Alert, Card, Container, Button } from "react-bootstrap";
import { UserContext } from "context/UserContext";
import { getMyOffers } from "./functions/apiCalls";
import MyOffer from "./components/MyOffer";
import { useLocation, useParams } from "react-router-dom";
import qs from "query-string";
import { Pagination } from "components";

const MyOffersPage = () => {
  const context = useContext(UserContext);
  const location = useLocation();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [activeOffer, setActiveOffer] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 10,
  });
  const [count, setCount] = useState(0);

  const { id } = useParams();

  const isArchived = useMemo(() => {
    if (id === "archived") return true;
    else if (id === "active") return false;
    else return null;
  }, [id]);

  const isActive = useMemo(
    () => !isArchived && isArchived !== null,
    [isArchived]
  );

  const handleRefresh = useCallback(
    () => setRefresh((prevState) => !prevState),
    []
  );

  const createTitle = useMemo(() => {
    if (isArchived) {
      return "Moje oferty - zarchiwizowane";
    } else if (isActive) {
      return "Moje oferty - aktywne";
    }
  }, [isActive, isArchived]);

  useEffect(() => {
    const abortController = new AbortController();
    const loadOffers = async (token, setOffers) => {
      if (!abortController.signal.aborted) setLoading(true);
      try {
        let res = await getMyOffers(
          token,
          {
            ...filters,
            archived: isArchived,
          },
          abortController.signal
        );
        setCount(res.count);
        if (res.count > 0) {
          setOffers(res.results);
        } else {
          setOffers([]);
        }
      } catch (err) {
        if (!abortController.signal.aborted) setError(true);
      }
      if (!abortController.signal.aborted) setLoading(false);
    };
    loadOffers(context.token, setOffers);
    return () => abortController.abort();
  }, [context.token, filters, id, isActive, isArchived, refresh]);

  const message = loading ? (
    <Alert variant="info" className="m-3">
      Ładuję...
    </Alert>
  ) : error ? (
    <Alert variant="danger" className="m-3">
      Nie udało się załadować ofert.
    </Alert>
  ) : offers.length === 0 ? (
    <Alert variant="info" className="m-3">
      Brak ofert
    </Alert>
  ) : null;

  const infoAboutActive = isArchived && (
    <Alert variant="danger" className="mt-3">
      Uwaga! Jeżeli aktywujesz ofertę wszystkie aplikacje na nią zostaną
      automatycznie usunięte
    </Alert>
  );

  const queryParams = qs.parse(location.search, { parseNumbers: true });
  if (
    typeof queryParams.page === "number" &&
    queryParams.page !== filters.page
  ) {
    setFilters({ ...filters, page: queryParams.page });
  }

  const buttonContent = isArchived
    ? "Przejdź do aktywnych ofert"
    : "Przejdź do ofert zarchiwizowanych";

  const buttonLink = isArchived ? "/myOffers/active" : "/myOffers/archived";

  return (
    <Container className="d-flex flex-column">
      <Button href={buttonLink} className="align-self-center">
        {buttonContent}
      </Button>
      <Card>
        <Card.Header as={"h2"}>{createTitle}</Card.Header>
        <Card.Body className="p-0">
          {infoAboutActive}
          {message ? message : null}
          <Accordion>
            {offers.map((offer) => (
              <MyOffer
                offer={offer}
                key={offer.id}
                activeOffer={activeOffer}
                setActiveOffer={setActiveOffer}
                handleRefresh={handleRefresh}
              />
            ))}
          </Accordion>
        </Card.Body>
        <Card.Footer className="py-0">
          <Pagination
            current={filters.page}
            max={Math.ceil(count / filters.pageSize)}
          />
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default MyOffersPage;
