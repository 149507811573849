import React from "react";
import { Card } from "react-bootstrap";
import { weekDays, getMonthId } from "constants/calendarDays";
import { CalendarTile } from "../";

const CalendarGrid = ({ month, year, onChange, value, daysArray }) => {
  const handleDateChange = (day, events) => {
    onChange(new Date(`${year}-${getMonthId(month) + 1}-${day}`));
  };

  return (
    <Card.Body className="p-0">
      <div className="calendar__tilesContainer calendar__tilesContainer--dayNames">
        {weekDays.map((weekDay) => (
          <div className="calendar__tileDayName" key={weekDay}>
            <p className="text-align-center mb-0 font-weight-bold">{weekDay}</p>
          </div>
        ))}
      </div>
      <div className="calendar__tilesContainer calendar__tilesContainer--days">
        {daysArray.map(({ day, events }) =>
          !day ? (
            <p className="calendar__tile h-100" key={day}>
              {day}
            </p>
          ) : (
            <CalendarTile
              key={day}
              active={day === value.getDate()}
              handleClick={() => handleDateChange(day, events)}
              events={events}
            >
              {day}
            </CalendarTile>
          )
        )}
      </div>
    </Card.Body>
  );
};

export default CalendarGrid;
