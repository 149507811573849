import React from "react";
import { Card } from "react-bootstrap";
import { FormGroup } from "components";
import DatePicker from "react-datepicker";

const FACILITY_EMAIL = "facilityEmail";
const BIRTHDAY = "birthday";

const LessThan16Form = ({
  facilityEmail,
  birthday,
  handleChange,
  emailOk,
  checked,
}) => (
  <Card className="mb-3">
    <Card.Header as="h4">Dane osoby poniżej 16 roku życia</Card.Header>
    <Card.Body>
      <FormGroup
        type="email"
        val={facilityEmail}
        setVal={(value) => handleChange(FACILITY_EMAIL, value)}
        header="Mail do placówki/opiekuna"
        required
        incorrect="Adres email jest wymagany i musi się różnić od adresu email
            użytkownika."
        id={FACILITY_EMAIL}
        isInvalid={checked && !emailOk}
        showHeader={false}
      />

      <DatePicker
        id="birthDate"
        className="form-control"
        locale="pl"
        placeholderText="Data urodzenia"
        dateFormat="yyyy-MM-dd"
        onChange={(birthDate) => handleChange(BIRTHDAY, birthDate)}
        withPortal
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        required
        selected={birthday}
        minDate={(() => {
          const now = new Date();
          const oneYearFromNow = new Date();
          oneYearFromNow.setFullYear(now.getFullYear() - 16);
          const correctDate = new Date(oneYearFromNow);
          correctDate.setDate(oneYearFromNow.getDate() + 1);
          return correctDate;
        })()}
        maxDate={new Date(Date.now())}
      />
    </Card.Body>
  </Card>
);

export default LessThan16Form;
