import React from "react";
import { EditorModal } from "../";
import { Badge, Button, Accordion } from "react-bootstrap";
import proxy from "config/api";

const ListItem = ({
  object,

  deleteCat,
  refresh,
  categories,
  i,
}) => {
  return (
    <div className="tree__categoryObject" key={object.category.id}>
      {object.category.header_pc_url && (
        <>
          <h3>Wersja stacjonarna</h3>
          <div className="tree__imageWrapper">
            <img
              className="tree__imageCat"
              src={`${proxy.plainFiles}${object.category.header_pc_url}`}
              alt="Obrazek kategorii"
            />
          </div>
        </>
      )}
      {object.category.header_mobile_url && (
        <>
          <h3>Wersja mobilna</h3>
          <div className="tree__imageWrapper">
            <img
              className="tree__imageCat"
              src={`${proxy.plainFiles}${object.category.header_mobile_url}`}
              alt="Obrazek kategorii"
            />
          </div>
        </>
      )}
      <h4 className="m-0">
        Kategoria: {object.category.name}
        <Accordion.Toggle
          as={Button}
          eventKey={i + 1}
          variant="primary"
          className="ml-1"
        >
          Edytuj
        </Accordion.Toggle>
        <Button
          variant="danger"
          onClick={() => deleteCat(object.category.id)}
          className="ml-1"
        >
          X
        </Button>
      </h4>
      {object.topics.map((topic) => (
        <Badge key={topic.id} variant="info" className="p-2 mr-1 mb-1 mt-1">
          {topic.name}
        </Badge>
      ))}
      <EditorModal
        refresh={refresh}
        categories={categories}
        id={i + 1}
        data={object}
      />
    </div>
  );
};

export default ListItem;
