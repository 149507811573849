import React, { useState, useEffect, useContext, useRef } from "react";
import YouTube from "react-youtube";
import { SendVideo, DeleteVideo } from "./components";
import { UserContext } from "context";
import { getVideos } from "./functions";
import { Alert } from "react-bootstrap";
import { staffTypes } from "constants/staffTypes";
import { userTypes } from "constants/userTypes";
import { YouTubeGetID } from "constants/other";

const VideoField = ({ id }) => {
  const [video, setVideo] = useState(null);
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const user = useContext(UserContext);
  const player = useRef(null);

  useEffect(() => {
    const fetchVideo = async () => {
      const abortController = new AbortController();
      setLoading(true);
      try {
        const response = await getVideos(abortController.signal);
        const videoById = response.find((videoRes) => videoRes.name === id);
        setVideo(videoById);
      } catch (err) {
        setErr(true);
      }
      setLoading(false);
    };
    fetchVideo();
  }, [id, refresh]);

  const handleRefresh = () => setRefresh((prevState) => !prevState);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const conditional =
    user?.type === userTypes.STAFF &&
    user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR);

  const msg = err ? (
    <Alert variant="danger">Błąd ładowania filmu.</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : null;

  return (
    msg || (
      <>
        {video ? (
          <div className="videoField__container my-3">
            {video && (
              <YouTube
                className="videoField__video"
                videoId={YouTubeGetID(video?.url)}
                opts={opts}
                onReady={onReady}
                ref={player}
              />
            )}
            {conditional && (
              <>
                <SendVideo id={id} refresh={handleRefresh} token={user.token} />
                <DeleteVideo
                  id={id}
                  refresh={handleRefresh}
                  token={user.token}
                />
              </>
            )}
          </div>
        ) : (
          <div className="videoField__containerWithoutVideo">
            {conditional && (
              <>
                <Alert variant="info">Tutaj możesz wstawić film.</Alert>
                <SendVideo
                  id={id}
                  refresh={handleRefresh}
                  token={user.token}
                  isNew
                />
              </>
            )}
          </div>
        )}
      </>
    )
  );
};

export default VideoField;
