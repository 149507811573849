import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "react-bootstrap";
import { DeletionModal } from "components";
import { ContactForm } from "../";
import { deleteContact } from "Views/ContactFoundation/ContactFoundation.api";
import { AlertContext, UserContext } from "context";
import { staffTypes } from "constants/staffTypes";

const Contact = ({
  contact,
  sections,
  handleRefresh,
  disabled,
  setDisabled,
}) => {
  const [show, setShow] = useState(false);

  const [showDel, setShowDel] = useState(false);
  const [delConfirmed, setDelConfirmed] = useState(false);

  const alertC = useRef(useContext(AlertContext));
  const user = useContext(UserContext);

  useEffect(() => {
    const handleDelete = async () => {
      setDelConfirmed(false);
      setDisabled(true);
      try {
        await deleteContact(user.token, contact.id);
        alertC.current.showAlert("Poprawnie usunięto kontakt.", "success");
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDisabled(false);
      handleRefresh();
    };

    if (delConfirmed) handleDelete();
  }, [contact.id, delConfirmed, handleRefresh, setDisabled, user.token]);

  const isAdmin = user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR);

  const { name, phone_number, email } = contact;
  return (
    <div className="mb-3">
      <div className="mb-2">
        <ContactForm
          sections={sections}
          token={user.token}
          handleClose={() => setShow(false)}
          show={show}
          contact={contact}
          disabled={disabled}
          setDisabled={setDisabled}
          handleRefresh={handleRefresh}
        />
        {isAdmin && (
          <>
            <Button
              className=""
              disabled={disabled}
              onClick={() => setShow(true)}
            >
              Edytuj
            </Button>
            <DeletionModal
              show={showDel}
              setShow={setShowDel}
              delConfirmed={setDelConfirmed}
              question={"Czy na pewno chcesz usunąć ten kontakt?"}
            />
            <Button
              variant="danger"
              className="ml-2"
              disabled={disabled}
              onClick={() => setShowDel(true)}
            >
              X
            </Button>
          </>
        )}
      </div>
      <p className="mb-2">
        <strong>{name}</strong>
      </p>
      {email && (
        <p className="mb-0">
          email:{" "}
          <a href={`mailto: ${email}`} className="mb-0  text-info">
            {email}
          </a>
        </p>
      )}
      {phone_number && (
        <p className="mb-0">
          tel:{" "}
          <a href={`tel:+48${phone_number}`} className="mb-0">
            {phone_number}
          </a>
        </p>
      )}
    </div>
  );
};

export default Contact;
