import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import React from "react";

export const NewBlogButton = ({ user }) => {
  return (
    <LinkContainer to={"/blog/newPost"}>
      <Button className="blogs-category-button">DODAJ NOWY POST</Button>
    </LinkContainer>
  );
};
