import React from "react";
import { Container, Card, Form, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import {
  HomeDataForm,
  PersonalDataForm,
  CompanyDataForm,
  AccountForm,
  TypeSelection,
  CheckBox,
  RegistrationInfo,
  Over16Buttons,
  LessThan16Form,
} from "./components";
import { getISODate } from "constants/calendarDays";
import { UserContext } from "context";
import { sendData } from "./functions/sendData";
import { withAlertContext } from "components";
import { staffTypes } from "constants/staffTypes";

const STANDARD_HEADER = "Podopiecznym";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personalData: null,
      homeData: null,
      companyData: { company_nip: "" },
      accountData: null,
      over16years: true,
      emailOk: true,
      account_type:
        this.props.match.params.role !== "staff"
          ? STANDARD_HEADER
          : [staffTypes.VERIFICATION],
      validated: false,
      checked: false,
      passwordOK: true,
      redirect: false,
      disabled: false,
      regulations: this.props.match.params.role === "staff",
      role: null,
      newsletter: false,
      cookiesLink: "",
      birthday: "",
      facilityEmail: "",
    };
  }

  handleSubmit = (data, event) => {
    const form = event.currentTarget;
    event.preventDefault();
    let failCount = 0;
    const { password, passwordR, email } = data.accountData || {};
    const { facilityEmail, over16years } = this.state;
    this.setState({ passwordOk: true, checked: true });
    if (process.env.REACT_APP_REGISTER_ON === "false") {
      failCount++;
      event.stopPropagation();
    }
    if (form.checkValidity() === false) {
      failCount++;
      event.stopPropagation();
    }
    if (password !== passwordR) {
      failCount++;
      this.setState({ passwordOk: false });
      event.stopPropagation();
    }
    if (!over16years && email === facilityEmail) {
      failCount++;
      this.setState({ emailOk: false });
      event.stopPropagation();
    }
    if (failCount > 0) {
      return false;
    }
    return true;
  };

  cutType = (e) => {
    let permissions = this.state.account_type;
    let itemIdx = permissions.indexOf(e.target.name);
    if (itemIdx > -1) {
      permissions.splice(itemIdx, 1);
      this.setState({
        account_types: permissions,
      });
    }
  };

  selectType = (e) => {
    if (this.props.match.params.role === "staff") {
      let permissions = this.state.account_type;
      permissions = permissions.push(e.target.name);
      this.setState({
        account_types: permissions,
      });
    } else {
      this.setState({
        account_type: e.target.value,
      });
    }
  };

  renderSection = () => {
    if (this.state.account_type === STANDARD_HEADER) {
      return (
        <HomeDataForm
          data={this.state.homeData}
          onBlur={(homeData) => this.setState({ homeData })}
        />
      );
    } else if (this.state.account_type === "Pracodawcą") {
      return (
        <CompanyDataForm
          data={this.state.companyData}
          onBlur={(companyData) => this.setState({ companyData })}
        />
      );
    }
  };

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  setValidated = () => {
    this.setState({
      validated: true,
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      this.props.history.push("/user");
    }
  };

  setRole = (e) => {
    this.setState({ role: e.target.value });
  };

  handleResponse = async (e) => {
    this.setState({ disabled: true });
    const { over16years, facilityEmail, birthday, account_type } = this.state;
    const standardValues =
      account_type === STANDARD_HEADER
        ? {
            over_16_years: over16years,
            facility_email: over16years ? null : facilityEmail,
            birthday: over16years ? null : birthday,
          }
        : {};

    const data = {
      personalData: {
        ...this.state.personalData,
        phone_number:
          this.state.personalData?.phone_number &&
          "+48" + this.state.personalData.phone_number,
      },
      ...standardValues,
      homeData: this.state.homeData,
      companyData: this.state.companyData,
      accountData: this.state.accountData,
      account_type: this.state.account_type,
      role: this.state.role,
      terms_accepted: this.state.regulations,
      newsletter: this.state.newsletter,
      facility_email: facilityEmail,
      over16years,
      birthday: birthday && getISODate(birthday),
    };

    const isOK = this.handleSubmit(data, e);
    this.setValidated();
    if (isOK) {
      try {
        const contextData = await sendData(this.context.token, data).then(
          (response) => {
            return response;
          }
        );
        const { status } = contextData;
        if (status === 201) {
          if (this.props.match.params.role !== "staff") {
            const { token, type, data } = contextData;
            this.context.login(token, type, data);
          }
          return this.setRedirect();
        }
      } catch (error) {
        this.props.alertContext.showAlert(Object.values(error)[0]);
      }
    }
    this.setState({ disabled: false });
  };

  regulationsCheck = (
    <p>
      Akceptuję <Link to="/regulations/policy">regulamin aplikacji</Link> oraz
      <Link to="/regulations/privacy_and_cookies">
        {" "}
        politykę prywatności i cookies
      </Link>
    </p>
  );

  handleLinkChange = (link) => {
    this.setState({
      cookiesLink: link,
    });
  };

  render() {
    const {
      validated,
      accountData,
      personalData,
      redirect,
      disabled,
      cookiesLink,
      newsletter,
      over16years,
      birthday,
      facilityEmail,
      account_type,
      emailOk,
      checked,
    } = this.state;

    const { renderSection, handleResponse, renderRedirect } = this;
    return (
      <Container className="loginPage loginPage__register">
        <Card className="loginPage__card">
          <Card.Header as="h2" className="loginPage__header">
            Rejestracja
          </Card.Header>
          <Card.Body className="registerPage__body">
            <TypeSelection
              isAdmin={this.props.match.params.role === "staff"}
              selectType={this.selectType}
              cutType={this.cutType}
              current={this.state.account_type}
              setRole={this.setRole}
            />
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleResponse(e)}
              className="loginPage__form primary"
            >
              <section className="row">
                <PersonalDataForm
                  data={personalData}
                  onBlur={(personalData) => this.setState({ personalData })}
                  isAdmin={this.props.match.params.role === "staff"}
                />
                {renderSection()}
                <AccountForm
                  data={accountData}
                  onBlur={(accountData) => this.setState({ accountData })}
                  isAdmin={this.props.match.params.role === "staff"}
                  passwordOk={this.state.passwordOk}
                  checked={this.state.checked}
                />
              </section>
              {account_type === STANDARD_HEADER && (
                <>
                  {" "}
                  <Over16Buttons
                    over16years={over16years}
                    setOver16Years={(value) =>
                      this.setState({ over16years: value })
                    }
                  />
                  {!over16years && (
                    <LessThan16Form
                      birthday={birthday}
                      facilityEmail={facilityEmail}
                      emailOk={emailOk}
                      checked={checked}
                      handleChange={(key, value) => {
                        this.setState({ [key]: value });
                      }}
                    />
                  )}
                </>
              )}
              {this.props.match.params.role !== "staff" && (
                <>
                  <CheckBox
                    checked={this.state.regulations}
                    handleChange={() =>
                      this.setState((prevState) => ({
                        regulations: !prevState.regulations,
                      }))
                    }
                    handleLinkChange={this.handleLinkChange}
                  />
                  <Form.Group controlId="newsletterCheck">
                    <Form.Check
                      type="checkbox"
                      checked={newsletter}
                      onChange={() =>
                        this.setState((prevState) => ({
                          newsletter: !prevState.newsletter,
                        }))
                      }
                      label={
                        "Wyrażam zgodę na otrzymywanie wiadomości e-mail o wydarzeniach, kursach, szkoleniach, organizowanych przez Fundację One Day lub jej partnerów."
                      }
                    />
                  </Form.Group>
                </>
              )}
              <small className="d-block mb-3">* Wymagane</small>
              <Button
                variant="primary"
                className="loginPage__button"
                type="submit"
                data-testid="submitBtn"
                disabled={
                  disabled || process.env.REACT_APP_REGISTER_ON === "false"
                }
              >
                {disabled ? "Ładowanie..." : "Utwórz konto"}
              </Button>
            </Form>
            {this.props.match.params.role !== "staff" ? (
              <div className="loginPage__links">
                <Link to="/login" className="loginPage__link">
                  Masz już konto? Zaloguj się!
                </Link>
              </div>
            ) : null}
            <RegistrationInfo link={cookiesLink} />
          </Card.Body>
        </Card>
        {renderRedirect(redirect)}
      </Container>
    );
  }
}

RegisterPage.contextType = UserContext;

export default withRouter(withAlertContext(RegisterPage));
