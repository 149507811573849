import React from "react";
import { Card, Form } from "react-bootstrap";

class PersonalDataForm extends React.Component {
  onChange = (onBlur, data, e) => {
    const name = e.target.name;
    const value = e.target.value;
    onBlur({ ...data, [name]: value });
  };

  render() {
    let { onBlur, data, isAdmin } = this.props;
    let { onChange } = this;
    return (
      <Card bg="light" className="loginPage__cardSection px-0 col-lg mx-lg-3">
        <Card.Header as="h4" className="">
          Dane osobowe
        </Card.Header>
        <Card.Body className="">
          <Form.Group controlId="formGroupFirstName" className="">
            <Form.Control
              name="first_name"
              type="text"
              placeholder="Imię"
              onChange={(e) => onChange(onBlur, data, e)}
              required
              minLength="1"
              maxLength="30"
              pattern="^[a-zA-ZąćęłńóśźżZĄĆĘŁŃÓŚŹŻ]+$"
            />
            <Form.Control.Feedback type="invalid">
              Pole imię nie może być puste oraz może składać się tylko z liter.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGroupLastName" className="">
            <Form.Control
              name="last_name"
              type="text"
              placeholder="Nazwisko"
              onChange={(e) => onChange(onBlur, data, e)}
              required
              minLength="1"
              maxLength="30"
              pattern="^[a-zA-ZąćęłńóśźżZĄĆĘŁŃÓŚŹŻ]+$"
            />
            <Form.Control.Feedback type="invalid">
              Pole nazwisko nie może być puste oraz może składać się tylko z
              liter.
            </Form.Control.Feedback>
          </Form.Group>

          {!isAdmin && (
            <Form.Group controlId="formGroupPhoneNumber">
              <Form.Control
                name="phone_number"
                type="tel"
                required
                pattern="[0-9]{3}[0-9]{3}[0-9]{3}"
                placeholder="Numer telefonu"
                onChange={(e) => onChange(onBlur, data, e)}
              />
              <Form.Control.Feedback type="invalid">
                Podaj numer telefonu
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                Numer telefonu powinien być w formacie 123456789
              </Form.Text>
            </Form.Group>
          )}
        </Card.Body>
      </Card>
    );
  }
}
export default PersonalDataForm;
