import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import { getChoices, postRelation } from "Views/Steps/functions";
import { AlertContext } from "context";
import { PropTypes } from "prop-types";

const RelationsForm = ({ refresh, choice, token }) => {
  const [choices, setChoices] = useState([]);
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [childChoice, setChildChoice] = useState();

  const alertContext = useRef(useContext(AlertContext));

  useEffect(() => {
    const abortController = new AbortController();
    const fetchChoices = async () => {
      if (!abortController.signal.aborted) setLoading(true);
      try {
        const res = await getChoices(
          token,
          {
            level_gt: 0,
            exclude: [...choice.paths.map(({ id }) => id), choice.id],
          },
          abortController.signal
        );
        setChoices(res);
        setChildChoice(res[0]?.id);
      } catch (err) {
        if (!abortController.signal.aborted) setError(true);
      }
      if (!abortController.signal.aborted) setLoading(false);
    };
    fetchChoices();
    return () => abortController.abort();
  }, [choice.id, choice.level, choice.paths, token]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleChange = (e) => {
    setChildChoice(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postRelation(token, childChoice, choice.id);
      alertContext.current.showAlert("Pomyślnie dołączono pytanie.", "success");
      refresh(choice.id);
      handleClose();
    } catch (err) {
      alertContext.current.showAlert(err.message);
    }
  };

  const message = loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : error ? (
    <Alert variant="danger">Nie udało się pobrać możliwych połączeń</Alert>
  ) : choices.length === 0 ? (
    <Alert variant="info">Brak istniejących pytań do dodania.</Alert>
  ) : null;

  return (
    <>
      <Button
        className="mx-1 steps-choice-edit-button"
        variant="primary"
        onClick={handleShow}
      >
        Dodaj istniejące pytanie
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Dodaj istniejące pytanie</Modal.Title>
        </Modal.Header>
        <Modal.Body as={Form} onSubmit={handleSubmit}>
          {message || (
            <Form.Group controlId="relation__select">
              <Form.Label>
                Wybierz pytanie które chcesz połączyć z istniejącym pytaniem
              </Form.Label>
              <Form.Control
                as="select"
                custom
                value={childChoice}
                onChange={handleChange}
              >
                {choices.map(({ id, title }) => (
                  <option value={id} key={id}>
                    {title}
                  </option>
                ))}
              </Form.Control>
              <Button variant="primary" type="submit">
                Prześlij
              </Button>
            </Form.Group>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

RelationsForm.propTypes = {
  choice: PropTypes.shape({
    id: PropTypes.number,
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,
      })
    ),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        description: PropTypes.string,
        attachment_url: PropTypes.string,
      })
    ),
    previous: PropTypes.number,
    title: PropTypes.string.isRequired,
  }),
  token: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default RelationsForm;
