import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const resendEmail = async (token) => {
  const url = `${proxy.termsAcceptance}resend_email/`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    method: "POST",
    headers,
  });

  if (response.status === 201) {
    return response.json();
  }
  throw new Error(await errorMessageText(response));
};
