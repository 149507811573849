import React, { useContext, useState } from "react";
import { UserContext } from "context";
import { Alert, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { userTypes } from "constants/userTypes";

const OffersAlert = () => {
  const [show, setShow] = useState(true);

  const user = useContext(UserContext);

  const history = useHistory();
  const handleRedirect = () => {
    history.push(`/jobOffers?city=${user?.data?.facility_address?.city}`);
  };

  return user?.offersForUser && show && user.type === userTypes.STANDARD ? (
    <Alert
      className="offlineAlert d-flex flex-column align-items-start"
      dismissible
      onClose={() => setShow(false)}
      variant="info"
    >
      {`Liczba ofert pracy w twoim mieście: ${user.offersForUser}`}
      <Button onClick={handleRedirect} className="mt-2">
        {" "}
        Kiliknij i sprawdź!
      </Button>
    </Alert>
  ) : null;
};

export default OffersAlert;
