import React, { useState, useEffect, useContext, useMemo } from "react";
import { Container, Button, ButtonGroup, Alert } from "react-bootstrap";
import {
  EventForm,
  LabelForm,
  EventList,
  LabelList,
  LabelFilter,
} from "./components";
import { ReactCalendar } from "components";
import { months } from "constants/calendarDays";
import { getEvents, getLabels } from "Views/Calendar/Calendar.api";
import { getISODate, getMonthId } from "constants/calendarDays";
import { UserContext } from "context";
import { staffTypes } from "constants/staffTypes";
import { userTypes } from "constants/userTypes";

const Calendar = () => {
  const [showEventForm, setShowEventForm] = useState(false);
  const [showTagForm, setShowTagForm] = useState(false);
  const [showLabelList, setShowLabelList] = useState(false);

  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  const [actualMonth, setActualMonth] = useState(months[date.getMonth()]);
  const [actualYear, setActualYear] = useState(date.getFullYear());

  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => setRefresh((prevState) => !prevState);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [labels, setLabels] = useState([]);

  const [daysEvents, setDaysEvents] = useState([]);
  const [filters, setFilters] = useState({});

  const user = useContext(UserContext);

  useEffect(() => {
    //efect for fetching events by date variable
    const fetchEvents = async () => {
      setLoading(true);
      const numericMonth = getMonthId(actualMonth);
      const from = getISODate(new Date(actualYear, numericMonth, 2));
      const daysInMonth = 33 - new Date(actualYear, numericMonth, 32).getDate();
      const to = getISODate(new Date(actualYear, numericMonth, daysInMonth));
      try {
        const response = await getEvents(from, to, filters);
        const responseLabels = await getLabels();
        setLabels(responseLabels);
        const mappedResponse = response.map((event) => {
          const label = responseLabels.filter(
            (label) => event.label === label.id
          )[0];
          return { ...event, label };
        });
        setEvents(mappedResponse);
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    };
    fetchEvents();
  }, [actualMonth, actualYear, filters, refresh]);

  const message = loading ? (
    <Alert variant="info">Ładowanie wydarzeń...</Alert>
  ) : error ? (
    <Alert variant="danger">{error}</Alert>
  ) : null;

  const handleOpenTag = () => setShowTagForm(true);
  const handleOpenEvent = () => setShowEventForm(true);
  const handleOpenTagList = () => setShowLabelList(true);

  const todayEvent = useMemo(() => {
    let firstDay = new Date(date.getFullYear(), date.getMonth()).getDay();
    if (firstDay === 0) {
      firstDay = 7;
    }

    return daysEvents[date.getDate() - 2 + firstDay];
  }, [date, daysEvents]);
  return (
    <Container className="p-0">
      {user?.type === userTypes.STAFF &&
        user?.data?.group_type.includes(staffTypes.BLOG_MODERATOR) && (
          <ButtonGroup className="mb-3 px-2">
            <Button className="mr-2" onClick={handleOpenTag}>
              Dodaj etykietę
            </Button>
            <Button className="mr-2" onClick={handleOpenEvent}>
              Dodaj wydarzenie
            </Button>
            <Button onClick={handleOpenTagList}>Pokaż listę etykiet</Button>
          </ButtonGroup>
        )}
      <h1 className="px-2 mb-3 my-4">Nadchodzące wydarzenia</h1>
      <LabelFilter labels={labels} filters={filters} setFilters={setFilters} />
      <ReactCalendar
        value={date}
        onChange={setDate}
        handleMonth={setActualMonth}
        handleYear={setActualYear}
        events={events}
        loading={loading}
        setDaysEvents={setDaysEvents}
      />
      {message}
      <EventList
        date={date}
        labels={labels}
        handleRefresh={handleRefresh}
        todayEvents={todayEvent?.events}
      />
      <EventForm
        show={showEventForm}
        handleClose={() => setShowEventForm(false)}
        labels={labels}
        handleRefresh={handleRefresh}
      />
      <LabelForm
        show={showTagForm}
        handleClose={() => setShowTagForm(false)}
        handleRefresh={handleRefresh}
      />
      <LabelList
        labels={labels}
        show={showLabelList}
        handleClose={() => setShowLabelList(false)}
        handleRefresh={handleRefresh}
      />
    </Container>
  );
};

export default Calendar;
