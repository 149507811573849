import React, { useEffect, useState, useRef, useContext } from "react";
import { Button, Row, Alert } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import PostTile from "../PostTile";
import { getMorePosts, getPosts } from "../../api";
import { AlertContext, UserContext } from "context";
import { useHistory } from "react-router-dom";

const BlogTopic = ({ category, topic }) => {
  const [posts, setPosts] = useState([]);
  const [postsData, setPostsData] = useState({ count: 0, next: "" });
  const [error, setError] = useState(false);
  const history = useHistory();
  const alertC = useRef(useContext(AlertContext));
  const user = useContext(UserContext);

  const loadMorePosts = async () => {
    try {
      let res = await getMorePosts(user.token, postsData.next);
      let morePosts = posts;
      for (const post of res.results) {
        morePosts.push(post);
      }
      setPosts(morePosts);
      setPostsData({ next: res.next, count: postsData.count });
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const loadPosts = async (category, topic) => {
      try {
        let res = await getPosts(
          user.token,
          category,
          topic.id,
          abortController.signal
        );
        setPosts(res.results);
        setPostsData({ count: res.count, next: res.next });
      } catch (e) {
        if (e === 401) {
          history.push("/login");
        }
        if (!abortController.signal.aborted) {
          setError(true);
        }
      }
    };
    if (postsData.next === "") {
      loadPosts(category, topic);
    }
    return () => abortController.abort();
  }, [category, topic, postsData]);

  return (
    <>
      {posts.length > 0 ? (
        <>
          {topic.name && <h3 className="blog-topic">{topic.name}</h3>}
          <div className="blog-tiles">
            {posts.map((post) => (
              <LinkContainer to={"/blogs/post/" + post.id} key={post.id}>
                <div className="blog-tile">
                  {post.topic === topic.id ? <PostTile post={post} /> : null}
                </div>
              </LinkContainer>
            ))}
          </div>
        </>
      ) : null}
      {posts.length < postsData.count ? (
        <Row className="d-flex justify-content-center">
          <Button className="blogs-edit-button" onClick={loadMorePosts}>
            <span className="align-self-center">STARSZE POSTY</span>
          </Button>
        </Row>
      ) : null}
      {error ? <div className="blogs-loading">WYSTĄPIŁ BŁĄD</div> : null}
    </>
  );
};

export default BlogTopic;
