import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const sendConfirmation = async ({ data, id, type }) => {
  const url = `${proxy.facilityVerification}code/${id}/${type}/`;
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "PATCH",
    headers,
    body: JSON.stringify(data),
  });

  if (response.status === 200) {
    return response.json();
  }
  throw new Error(await errorMessageText(response));
};
