import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "react-bootstrap";
import { DeletionModal } from "components";
import { AlertContext } from "context";
import { deleteChoice } from "Views/Steps/functions";
import PropTypes from "prop-types";

const DeleteChoiceButton = ({ choice, token, refresh }) => {
  const [showModal, setShowModal] = useState(false);
  const [delConfirmed, setDelConfirmed] = useState(false);

  const alertContext = useRef(useContext(AlertContext));

  useEffect(() => {
    const handleDelete = async () => {
      try {
        await deleteChoice(token, choice.id);
        alertContext.current.showAlert(
          "Poprawnie usunięto pytanie.",
          "success"
        );
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
      setDelConfirmed(false);
      refresh(choice.parents, choice);
    };

    if (delConfirmed) handleDelete();
  }, [choice, delConfirmed, refresh, token]);

  return (
    <>
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDelConfirmed}
        question={
          "Czy na pewno chcesz usunąć te pytanie? Spowoduje to usunięcie tego pytania we wszystkich miejscach."
        }
      />
      <Button
        onClick={() => setShowModal(true)}
        className="steps-choice-edit-button"
      >
        USUŃ PYTANIE
      </Button>
    </>
  );
};

DeleteChoiceButton.propTypes = {
  choice: PropTypes.shape({
    id: PropTypes.number,
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,
      })
    ),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        description: PropTypes.string,
        attachment_url: PropTypes.string,
      })
    ),
    previous: PropTypes.number,
    title: PropTypes.string.isRequired,
  }),
  token: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default DeleteChoiceButton;
