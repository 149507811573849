import React, { useRef, useContext, useState, useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import { AlertContext, UserContext } from "context";
import PropTypes from "prop-types";
import { staffTypes } from "constants/staffTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { DeletionModal } from "components";
import {
  deleteRelation,
  moveChoice,
  getRelations,
} from "Views/Steps/functions";

const NextChoices = ({
  showButtons,
  choice,
  handleNextChoice,
  token,
  refresh,
  disabled,
}) => {
  const alertContext = useRef(useContext(AlertContext));
  const user = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);
  const [delConfirmed, setDelConfirmed] = useState(false);

  const [deleteId, setDeleteId] = useState(-1);

  const [disabledButton, setDisabledButton] = useState(false);

  const handleShowDelete = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  useEffect(() => {
    const handleDelete = async () => {
      setDisabledButton(true);
      let relationId = -1;

      try {
        relationId = await getRelations(token, {
          parent: choice.id,
          child: deleteId,
        });
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }

      if (relationId[0]?.id !== -1) {
        try {
          await deleteRelation(token, relationId[0].id);
          alertContext.current.showAlert(
            "Poprawnie usunięto relację.",
            "success"
          );
          refresh(choice.id);
        } catch (err) {
          alertContext.current.showAlert(err.message);
        }
      }

      setDisabledButton(false);
      setDelConfirmed(false);
      refresh(choice.previous, choice.id);
    };

    if (delConfirmed) handleDelete();
  }, [choice, delConfirmed, deleteId, refresh, token]);

  const handleMove = async (id, dir) => {
    setDisabledButton(true);
    let relationId = -1;
    try {
      const res = await getRelations(token, { child: id, parent: choice.id });
      relationId = res[0].id;
    } catch (err) {
      alertContext.current.showAlert(err.message);
    }

    if (relationId !== -1) {
      try {
        await moveChoice(token, relationId, dir);
        refresh(choice.id);
        alertContext.current.showAlert(
          "Pomyślnie przesunięto pytanie",
          "success"
        );
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
    }
    setDisabledButton(false);
  };

  return (
    showButtons && (
      <>
        {!user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR) &&
        choice.paths.length > 0 ? (
          <div className="text-align-center step-next-choices">
            <h3>Wybierz drogę aby iść dalej:</h3>
          </div>
        ) : null}
        <div className="d-flex flex-row flex-wrap justify-content-around mt-3">
          <DeletionModal
            show={showModal}
            setShow={setShowModal}
            delConfirmed={setDelConfirmed}
            question={
              "Czy na pewno chcesz usunąć te pytanie? Usunięcie tego pytania spowoduje tylko usunięcie powiązania między tymi pytaniami"
            }
          />
          {choice?.paths?.map(({ title, id }, i) => (
            <div className="d-flex flex-column justify-content-center" key={id}>
              <div
                className="steps-choice-div"
                onClick={disabled ? () => {} : () => handleNextChoice(id)}
              >
                <span className="align-items-center">{title}</span>
              </div>
              {user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR) ? (
                <div className="align-self-center">
                  {i !== 0 && (
                    <Button
                      data-testid="left"
                      variant="info"
                      disabled={disabledButton}
                      onClick={() => handleMove(id, "left")}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                  )}
                  {i !== choice.paths.length - 1 && (
                    <Button
                      data-testid="right"
                      variant="info"
                      className="ml-2"
                      disabled={disabledButton}
                      onClick={() => handleMove(id, "right")}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  )}
                  <Button
                    variant="danger"
                    className="ml-2"
                    disabled={disabledButton}
                    onClick={() => handleShowDelete(id)}
                  >
                    Usuń relację
                  </Button>
                </div>
              ) : null}
            </div>
          ))}
        </div>
        {disabled && <Alert variant="info">Trwa ładowanie...</Alert>}
      </>
    )
  );
};

NextChoices.propTypes = {
  choice: PropTypes.shape({
    id: PropTypes.number,
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,
      })
    ),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        description: PropTypes.string,
        attachment_url: PropTypes.string,
      })
    ),
    previous: PropTypes.number,
    title: PropTypes.string.isRequired,
  }),
  token: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
  showButtons: PropTypes.bool.isRequired,
  handleNextChoice: PropTypes.func.isRequired,
};

export default NextChoices;
