import React, { useState, useEffect, useContext, useRef } from "react";
import { Alert, Button } from "react-bootstrap";
import { Tile } from "../";
import proxy from "config/api";
import { UserContext } from "context/UserContext";
import { staffTypes } from "constants/staffTypes";
import NewTileForm from "../NewTileForm/NewTileForm";
import { AlertContext } from "context/AlertContext";
import getBase64Image from "utils/converters/imageToBase64";
import { changePosition } from "Views/Menu/Menu.api";

const getTiles = async (signal) => {
  let url = `${proxy.menu}`;
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers, signal });

  // return tmpTiles;

  // eslint-disable-next-line no-unreachable
  if (response.status !== 200) {
    throw response.status;
  }

  return response.json().then((tiles) => mapTiles(tiles));
};

const mapTiles = (tiles) =>
  tiles.map((tile) => ({
    id: tile.id,
    title: tile.title,
    color: tile.color,
    show: tile.photo_layer,
    imageUrl: proxy.plainFiles + tile.photo,
    destination: tile.destination,
    index: tile.index,
  }));

const TilesContainer = () => {
  const [tiles, setTiles] = useState([]);
  const [showModal, setShow] = useState(false);
  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));

  useEffect(() => {
    if (!navigator.onLine) {
      const tiles = JSON.parse(window.localStorage.getItem("tiles"));
      setTiles(tiles);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const loadTiles = async () => {
      let res;
      try {
        res = await getTiles(abortController.signal);
        setTiles(res);
      } catch (e) {
        if (!abortController.signal.aborted) {
          alertC.current.showAlert("Wystąpił błąd podczas pobierania menu.");
        }
      }
    };
    if (navigator.onLine) {
      loadTiles();
    }
    return () => abortController.abort();
  }, [alertC]);

  useEffect(() => {
    const getItem = async (item) => {
      let newRes = { ...item };
      if (item.imageUrl) {
        const newSrc = await getBase64Image(item.imageUrl);
        newRes.imageUrl = newSrc;
      }
      return newRes;
    };
    const saveToLocalStorage = async () => {
      try {
        const localTiles = await Promise.all(tiles.map(getItem));
        window.localStorage.setItem("tiles", JSON.stringify(localTiles));
      } catch (err) {}
    };
    if (navigator.onLine) {
      saveToLocalStorage();
    }
  }, [tiles]);

  const msg = !tiles && <Alert variant="info">Ładowanie menu...</Alert>;

  const appendTile = (newTile) => {
    const idx = tiles.findIndex((tile) => tile.id === newTile.id);
    if (idx > -1) {
      let newTileList = [...tiles];
      newTileList[idx] = newTile;
      setTiles(newTileList);
    } else {
      setTiles((tiles) => [...tiles, newTile]);
    }
  };

  const cutTile = (oldTileId) => {
    let newTiles = [...tiles];
    newTiles = newTiles.filter((item) => item.id !== oldTileId);
    setTiles(newTiles);
  };

  const handleDrag = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const handleOnDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, onDropIndex) => {
    e.preventDefault();
    const movedIndex = e.dataTransfer.getData("index") * 1;

    if (movedIndex !== onDropIndex) {
      setTiles((prevArray) => {
        const newArr = [...prevArray];
        const deletedIndex = newArr.findIndex(
          (item) => item.index === movedIndex
        );
        const prevIndex = newArr.findIndex(
          (item) => item.index === onDropIndex
        );
        const element = newArr[deletedIndex];
        newArr.splice(deletedIndex, 1);
        let newIndex = newArr.findIndex((item) => item.index === onDropIndex);

        if (prevIndex > deletedIndex) {
          newIndex++;
        }
        newArr.splice(newIndex, 0, element);

        return newArr.map((item, i) => ({ ...item, index: i }));
      });
      try {
        await changePosition(user.token, onDropIndex, movedIndex);
      } catch (err) {
        alertC.current.showAlert("Nie udało sie przenieść kafelka");
      }
    }
  };

  return (
    msg || (
      <div className="tilesGrid__container">
        <div className="tilesGrid">
          {tiles.map((tile) => (
            <Tile
              key={tile.id}
              id={tile.id}
              color={tile.color}
              imageUrl={tile.imageUrl || ""}
              showImage={tile.show}
              title={tile.title}
              destination={tile.destination}
              user={user}
              cutTile={cutTile}
              appendTile={appendTile}
              handleDrag={handleDrag}
              handleOnDragOver={handleOnDragOver}
              handleDrop={handleDrop}
              index={tile.index}
            />
          ))}
          {user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR) && (
            <>
              <Button
                className="tilesGrid__newTile"
                variant="primary"
                size="lg"
                block
                onClick={() => setShow(true)}
              >
                Dodaj kafelek
              </Button>
              <NewTileForm
                show={showModal}
                setShow={setShow}
                user={user}
                appendTile={appendTile}
                tilesLength={tiles.length}
              />
            </>
          )}
        </div>
      </div>
    )
  );
};

export default TilesContainer;
