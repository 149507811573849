import React, { useState, useRef, useContext, useEffect } from "react";
import { Card, Form, Accordion, Button, Alert } from "react-bootstrap";
import { FormGroup } from "components";
import { sendKey } from "Views/GenerateApiKeys/functions";
import { approveFileSize } from "utils/approveFile/approveFile";
import { AlertContext, UserContext } from "context";
import PropTypes from "prop-types";

const KeysForm = ({ keyObject, handleRefresh }) => {
  const [name, setName] = useState("");
  const [logo, setLogo] = useState(undefined);
  const alertContext = useRef(useContext(AlertContext));
  const user = useContext(UserContext);

  const [copied, setCopied] = useState(false);

  const [key, setKey] = useState("");

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (keyObject) {
      setName(keyObject.name);
    }
  }, [keyObject]);

  const onPhotoChange = async (files) => {
    if (approveFileSize(files[0])) {
      setLogo(files[0]);
    } else {
      alertContext.current.showAlert(
        "Przekazane zdjęcie jest za duże(max 15mb)"
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        const res = await sendKey(user.token, { name, logo }, keyObject?.id);
        alertContext.current.showAlert(
          keyObject
            ? "Poprawnie edytowano klucz"
            : "Poprawnie wygenerowano klucz",
          "success"
        );
        setKey(res.key);
        handleRefresh();
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
    }
    setValidated(true);
  };

  const copyToClipboard = () => {
    setCopied(true);
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = key;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h3>{keyObject ? "Edytuj klucz" : "Generator kluczy do API"}</h3>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body
            as={Form}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            {!keyObject && (
              <Alert variant="danger">
                <span className="font-weight-bold">Uwaga!</span> Klucz po
                wygenerowaniu należy zapisać. Nie ma możliwości ponownego
                pobrania klucza.
              </Alert>
            )}
            <FormGroup
              header="Nazwa strony zewnętrznej"
              val={name}
              setVal={setName}
              required
              id="external_name"
            />
            <Form.Label>Logo strony zewnętrznej</Form.Label>
            <Form.File
              name="photo"
              id="external_logo"
              label={logo ? logo?.name : "Wybierz plik"}
              custom
              onChange={(e) => onPhotoChange(e.target.files)}
              accept="image/*"
              data-browse="Dodaj"
              className="mb-3 overflow-hidden"
            />
            {(logo || keyObject?.logo_url) && logo !== "" && (
              <Button
                variant="danger"
                className="mr-1"
                onClick={() => setLogo("")}
              >
                Usuń zdjęcie
              </Button>
            )}
            <Button type="submit">Prześlij</Button>
            {key && (
              <>
                <Alert
                  variant="success"
                  className="mt-3"
                >{`Twój wygenerowany klucz to: ${key}`}</Alert>
                <Button onClick={copyToClipboard}>
                  <u>{copied ? "Skopiowano" : "Skopiuj klucz"}</u>
                </Button>
              </>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

KeysForm.propTypes = {
  handleRefresh: PropTypes.func.isRequired,
  keyObject: PropTypes.shape({
    id: PropTypes.number.isRequired,
    logo_url: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};

export default KeysForm;
