import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Button, Container, Row } from "react-bootstrap";
import { getWorkshopPost, removeWorkshop } from "./api";
import YouTube from "react-youtube";
import { staffTypes } from "constants/staffTypes";
import { LinkContainer } from "react-router-bootstrap";
import { AlertContext, UserContext } from "context";
import ApplyForWorkshop from "./components/ApplyForWorkshop";
import DeleteWorkshopPostButton from "./components/DeleteWorkshopPostButton";
import DeletionModal from "../../components/DeletionModal/DeletionModal";
import BlogComments from "./components/BlogComments";
import ReactDOM from "react-dom";
import { YouTubeGetID } from "constants/other";

const EditPostButton = ({ user, post }) => {
  if (user.data !== undefined) {
    if (user?.data?.group_type?.includes(staffTypes.BLOG_CREATOR)) {
      return (
        <LinkContainer to={"/blog/edit/workshop/" + post.id}>
          <Button className="blogs-edit-button">
            <span className="align-self-center">EDYTUJ WARSZTAT</span>
          </Button>
        </LinkContainer>
      );
    } else return null;
  } else return null;
};

const WorkshopPost = () => {
  const user = useContext(UserContext);
  const alert = useRef(useContext(AlertContext));
  const [post, setPost] = useState({ header: null, comments: [] });
  const [deletePostAlert, setDeletePostAlert] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();

  const deletePost = async () => {
    try {
      await removeWorkshop(post.id, user.token);
      alert.current.showAlert("Pomyślnie usunięto post", "success");
      setRedirect(true);
    } catch (err) {
      alert.current.showAlert(err.message);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const loadWorkshopPost = async (id) => {
      try {
        let res = await getWorkshopPost(id, abortController.signal);
        setPost(res);
      } catch (e) {}
    };

    const loadMedia = () => {
      document.querySelectorAll(".media").forEach((element) => {
        ReactDOM.render(
          <YouTube
            videoId={YouTubeGetID(
              element?.childNodes[0]?.attributes?.url?.value
            )}
            className="blog-post-video"
          />,
          ReactDOM.findDOMNode(element)
        );
        element.childNodes[0].classList.add("w-100");
        element.childNodes[0].classList.add("text-align-center");
        element.childNodes[0].classList.add("blogpost__media--padding");
      });

      document.querySelectorAll(".image").forEach((element) => {
        element.classList.add("text-align-center");
      });
    };

    loadWorkshopPost(id).then(loadMedia);
    return () => abortController.abort();
  }, [refresh, id]);

  return (
    <Container className="font-only blogs-background">
      {post.header !== null ? (
        <div className="blogpost-border">
          {post.header.includes("youtube.com/watch?") ||
          post.header.includes("https://youtu.be/") ? (
            <YouTube
              videoId={YouTubeGetID(post.header)}
              className="blog-post-media"
            />
          ) : (
            <img
              src={post.header}
              alt="tutaj jest obrazek jbc"
              className="blog-post-media"
            />
          )}
        </div>
      ) : null}
      <div className="">
        <Row className="d-flex justify-content-center blogpost__title">
          {post.title}
        </Row>
        <Row className="d-flex justify-content-center blogpost__description">
          <div
            dangerouslySetInnerHTML={{ __html: post.content }}
            className="blogpost__description"
          />
        </Row>
        <div className="d-flex justify-content-center blogpost__description">
          <ApplyForWorkshop
            workshop={post}
            buttonText={"Aplikuj na warsztat"}
            canApply={post.can_apply}
          />
        </div>
        {user?.data?.group_type?.includes(staffTypes.BLOG_CREATOR) ? (
          <Row className="justify-content-center mt-3">
            <EditPostButton user={user} post={post} />
            <DeleteWorkshopPostButton
              user={user}
              setDeletePostAlert={setDeletePostAlert}
            />
          </Row>
        ) : null}
        <BlogComments
          comments={post.comments}
          user={user}
          postId={post.id}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <DeletionModal
          show={deletePostAlert}
          setShow={setDeletePostAlert}
          question={"Czy na pewno chcesz usunąć ten warsztat?"}
          cancelLabel={"Nie, zostaw"}
          confirmLabel={"Tak, usuń"}
          delConfirmed={deletePost}
        />
        {redirect ? <Redirect to={"/workshops"} /> : null}
      </div>
    </Container>
  );
};

export default WorkshopPost;
