import React from "react";
import { Alert as BootstrapAlert } from "react-bootstrap";

const Alert = ({ loading, error }) => {
  return loading ? (
    <BootstrapAlert data-testid="loading" className="my-3" variant="info">
      Ładowanie...
    </BootstrapAlert>
  ) : error ? (
    <BootstrapAlert data-testid="error" className="my-3" variant="danger">
      {error}
    </BootstrapAlert>
  ) : null;
};

export default Alert;
