import React, { useState, useEffect, useCallback } from "react";
import Cookies from "universal-cookie";
import { getUserData } from "./functions/getUserData";

export const UserContext = React.createContext({
  type: undefined,
  token: undefined,
  data: undefined,
  cookiesAccepted: undefined,
  changeToken: () => {},
  changeType: () => {},
  changeData: () => {},
  login: () => {},
  logout: () => {},
  changeCookiesAccepted: () => {},
});
const cookies = new Cookies();

export const UserProvider = (props) => {
  const [type, setType] = useState(cookies.get("type"));
  const [token, setToken] = useState(cookies.get("token"));
  const [data, setData] = useState(cookies.get("data"));
  const [cookiesAccepted, setCookiesAccepted] = useState(
    cookies.get("cookiesAccepted")
  );
  const [offersForUser, setOffersForUser] = useState(0);

  const fetchUserData = useCallback(async () => {
    try {
      const res = await getUserData(token);
      setType(res.type);
      setData(res.data);
      cookies.set("data", res.data, {
        path: "/",
        expires: setCookieDate(),
        secure: process.env.REACT_APP_SECURE_COOKIES === "on",
      });
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchUserData();
    }
  }, [fetchUserData, token]);

  const user = {
    token,
    type,
    data,
    cookiesAccepted,
    offersForUser,
    changeToken: (newToken) => {
      cookies.set("token", newToken, {
        path: "/",
        expires: setCookieDate(),
        secure: process.env.REACT_APP_SECURE_COOKIES === "on",
      });
      setToken(newToken);
    },
    changeType: (newType) => {
      cookies.set("type", newType, {
        path: "/",
        expires: setCookieDate(),
        secure: process.env.REACT_APP_SECURE_COOKIES === "on",
      });
      setType(newType);
    },
    changeData: (newData) => {
      cookies.set("data", newData, {
        path: "/",
        expires: setCookieDate(),
        secure: process.env.REACT_APP_SECURE_COOKIES === "on",
      });
      setData(newData);
    },
    changeCookiesAccepted: (newCookiesAccepted) => {
      const date = new Date();
      date.setDate(date.getDate() + 365);
      cookies.set("cookiesAccepted", newCookiesAccepted, {
        path: "/",
        expires: date,
        secure: process.env.REACT_APP_SECURE_COOKIES === "on",
      });
      setCookiesAccepted(newCookiesAccepted);
    },
    login: (newToken, newType, newData) => {
      user.changeToken(newToken);
      user.changeType(newType);
      user.changeData(newData);
    },
    logout: () => {
      cookies.remove("token", { path: "/" });
      cookies.remove("type", { path: "/" });
      cookies.remove("data", { path: "/" });
      setToken(undefined);
      setType(undefined);
      setData(undefined);
    },
    fetchUserData,
    changeOffersForUser: (offerCount) => setOffersForUser(offerCount),
  };

  return <UserContext.Provider value={user} {...props} />;
};

export const useUserContext = () => React.useContext(UserContext);

const setCookieDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 2);
  return date;
};
