import React, { useContext, useRef, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { AlertContext } from "context";
import { sendWorkshopApplication } from "../../Workshops/api";

const ApplyForWorkshop = ({ workshop, buttonText, canApply }) => {
  const alertC = useRef(useContext(AlertContext));
  const [show, setShow] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [update, setUpdate] = useState(false);
  const [participantsCount, setParticipantsCount] = useState("0");
  const [participantsAge, setParticipantsAge] = useState("0");
  const [Principal, setPrincipal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let temp;

  const addParticipants = (e) => {
    e.preventDefault();

    temp = participants;
    let error = false;

    temp.forEach((t) => {
      if (parseInt(t.participantsAge) === parseInt(participantsAge)) {
        alertC.current.showAlert("już dodałeś liczbę uczestników o tym wieku!");
        error = true;
      }
    });

    if (!error) {
      temp.push({
        participantsCount: participantsCount,
        participantsAge: participantsAge,
      });
      setParticipants(temp);
    }

    setUpdate(!update);
  };

  const removeParticipants = (e) => {
    e.preventDefault();
    setParticipants([]);
  };

  const applyForWorkshop = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget.elements;
    let facility_inhabitants = participants;

    facility_inhabitants.forEach((p) => {
      let count = parseInt(p.participantsCount);
      let age = parseInt(p.participantsAge);
      p.count = count;
      p.age = age;
    });
    const formData = {
      facility_data: {
        name: form.formFacilityName.value,
        address: form.formFacilityAddress.value,
        city: form.formFacilityCity.value,
        postal_code: form.formFacilityPostalCode.value,
      },
      participant_count: parseInt(form.formParticipantCount.value),
      facility_inhabitants: facility_inhabitants,
    };

    if (Principal) {
      formData.principal_data = {
        name: form.formApplicantName.value,
        phone: form.formApplicantPhone.value,
        mail: form.formApplicantMail.value,
      };
    } else {
      formData.applicantData = {
        name: form.formApplicantName.value,
        phone: form.formApplicantPhone.value,
        mail: form.formApplicantMail.value,
        role: form.formApplicantRole.value,
      };
      formData.principal_data = {
        name: form.formPrincipalName.value,
        phone: form.formPrincipalPhone.value,
        mail: form.formPrincipalMail.value,
      };
    }

    if (form.formAdditionalInfo.value.length > 0) {
      formData.additional_info = form.formAdditionalInfo.value;
    }

    if (workshop !== null) {
      formData.workshop_data = {
        city: workshop.city,
        label: workshop.label,
        url: window.location.href,
      };
    }

    try {
      await sendWorkshopApplication(formData);
      alertC.current.showAlert(
        "Aplikacja została pomyślnie złożona.",
        "success"
      );
      setShow(false);
    } catch (err) {
      alertC.current.showAlert(err.message);
    }
  };

  if (canApply) {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{buttonText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={applyForWorkshop}>
              <h3>Dane placówki</h3>

              <Form.Row>
                <Form.Group as={Col} controlId="formFacilityName">
                  <Form.Label>Nazwa</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Wpisz nazwę placówki"
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formFacilityAddress">
                  <Form.Label>Adres</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Wpisz ulicę z numerem"
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formFacilityPostalCode">
                  <Form.Label>Kod pocztowy</Form.Label>
                  <Form.Control type="text" placeholder="00-000" />
                </Form.Group>

                <Form.Group as={Col} controlId="formFacilityCity">
                  <Form.Label>Miasto</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Wpisz miasto placówki"
                  />
                </Form.Group>
              </Form.Row>

              <h3>Dane zgłaszającego</h3>

              <Form.Row>
                <Form.Group as={Col} controlId="formApplicantName">
                  <Form.Label>Imię i nazwisko</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Wpisz imię i nazwisko"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formApplicantRole">
                  <Form.Label>Rola</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Podopieczny, pracownik..."
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formApplicantPhone">
                  <Form.Label>Telefon</Form.Label>
                  <Form.Control type="text" placeholder="np. 506034671" />
                </Form.Group>

                <Form.Group as={Col} controlId="formApplicantMail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="text" placeholder="Wpisz email" />
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="formApplicantPrincipal">
                <Form.Check
                  type={"checkbox"}
                  id={"formApplicantPrincipalCheckbox"}
                  label={"Jestem dyrektorem placówki"}
                  checked={Principal}
                  onChange={() => setPrincipal(!Principal)}
                />
              </Form.Group>

              {!Principal ? (
                <>
                  <h3>Dane dyrektora placówki</h3>

                  <Form.Group controlId="formPrincipalName">
                    <Form.Label>Imię i nazwisko</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Wpisz imię i nazwisko dyrektora placówki"
                    />
                  </Form.Group>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formPrincipalPhone">
                      <Form.Label>Telefon</Form.Label>
                      <Form.Control type="text" placeholder="np. 506034671" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formPrincipalMail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Wpisz email dyrektora placówki"
                      />
                    </Form.Group>
                  </Form.Row>
                </>
              ) : null}

              <h3>Uczestnicy</h3>

              <Form.Group controlId="formParticipantCount">
                <Form.Label>Liczba uczestników</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Wpisz liczbę uczestników"
                />
              </Form.Group>

              <Form.Row>
                <Col>
                  <h4>Informacje o uczestnikach</h4>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col>Liczba osób:</Col>
                <Col>Wiek osób:</Col>
                <Col>Akcje:</Col>
              </Form.Row>

              {participants.map((p) => (
                <Form.Row key={p.participantsAge} className="my-1">
                  <Col>{p.participantsCount}</Col>
                  <Col>{p.participantsAge}</Col>
                  <Col />
                </Form.Row>
              ))}

              <Form.Row>
                <Form.Group as={Col} controlId="formParticipantsCount">
                  <Form.Control
                    type="text"
                    onBlur={(event) => setParticipantsCount(event.target.value)}
                    placeholder="Liczba osób"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formParticipantsAge">
                  <Form.Control
                    type="text"
                    onBlur={(event) => setParticipantsAge(event.target.value)}
                    placeholder="Wiek osób"
                  />
                </Form.Group>

                <Col md={5}>
                  <Button onClick={addParticipants} type="reset">
                    Dodaj
                  </Button>
                  <Button
                    onClick={removeParticipants}
                    type="reset"
                    className="ml-1"
                  >
                    Wyczyść
                  </Button>
                </Col>
              </Form.Row>

              <Form.Group controlId="formAdditionalInfo">
                <Form.Label>Dodatkowe informacje</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Wpisz dodatkowe informacje"
                />
              </Form.Group>

              <Button type="submit">{buttonText}!</Button>
            </Form>
          </Modal.Body>
        </Modal>
        <div onClick={handleShow} className="apply-for-workshop-button">
          <span className="text-align-center text-uppercase">{buttonText}</span>
        </div>
      </>
    );
  } else return null;
};

export default ApplyForWorkshop;
