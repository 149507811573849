import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { months } from "constants/calendarDays";
import { getMonthId } from "constants/calendarDays";
import { CalendarGrid, CalendarNavigation } from "./components";

const calculateDayInYear = (date) => {
  let now = new Date(date);
  let start = new Date(now.getFullYear(), 0, 0);
  let diff =
    now -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  let oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
};

const Calendar = ({
  value,
  onChange,
  handleMonth,
  handleYear,
  events,
  loading,
  setDaysEvents,
}) => {
  const [month, setMonth] = useState(months[value.getMonth()]);
  const [year, setYear] = useState(value.getFullYear());
  const [daysArray, setDaysArray] = useState([]);

  const setMonths = (month) => {
    setMonth(month);
    handleMonth(month);
  };

  const setYears = (year) => {
    setYear(year);
    handleYear(year);
  };

  useEffect(() => {
    const numericMonth = getMonthId(month);
    let firstDay = new Date(year, numericMonth).getDay();
    let daysInMonth = 32 - new Date(year, numericMonth, 32).getDate();
    const daysArray = [];
    if (firstDay === 0) {
      firstDay = 7;
    }
    firstDay--;

    let eventsCopy = [...events];
    eventsCopy = eventsCopy.map((event) => ({
      ...event,
      numericStart: calculateDayInYear(event.date_start),
      numericEnd: calculateDayInYear(event.date_end),
    }));
    let eventsArray = [];

    let dayInYear = calculateDayInYear(`${year}-${numericMonth + 1}-1`);

    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDay) {
          daysArray.push("");
        } else {
          const day = i * 7 + j - firstDay + 1;

          if (day > daysInMonth) {
            break;
          }

          if (!loading) {
            eventsCopy = eventsCopy.filter((event) => {
              if (
                event.numericStart <= dayInYear &&
                dayInYear <= event.numericEnd &&
                !eventsArray.includes(event)
              ) {
                eventsArray.push(event);
              }
              return dayInYear <= event.numericEnd;
            });
          }

          daysArray.push({ day, events: eventsArray });
          if (!loading) {
            eventsArray = eventsArray.filter(
              (event) => event.numericEnd > dayInYear
            );
          }
          dayInYear++;
        }
      }
    }

    setDaysArray(daysArray);
    if (value.getMonth() === numericMonth) {
      setDaysEvents(daysArray);
    }
  }, [events, month, year, loading, setDaysEvents, value]);

  return (
    <Card className="p-0">
      <CalendarNavigation
        setMonth={setMonths}
        setYear={setYears}
        month={month}
        year={year}
      />
      <CalendarGrid
        onChange={onChange}
        year={year}
        month={month}
        value={value}
        daysArray={daysArray}
      />
    </Card>
  );
};

export default Calendar;
