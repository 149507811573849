import React, { useState, useRef, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { deleteVideo } from "components/VideoField/functions";
import { DeletionModal } from "components";
import { AlertContext } from "context";

const DeleteVideo = ({ id, token, refresh }) => {
  const [disabled, setDisabled] = useState(false);
  const alertC = useRef(useContext(AlertContext));

  // deletionModal

  const [showModal, setShowModal] = useState(false);
  const [delConfirmed, setDelConfirmed] = useState(false);

  useEffect(() => {
    const handleDelete = async () => {
      setDisabled(true);
      try {
        await deleteVideo(token, id);
        setDelConfirmed(false);
        alertC.current.showAlert("Pomyślnie usunięto film.", "success");
        refresh();
      } catch (err) {
        setDelConfirmed(false);
        alertC.current.showAlert(err.message);
      }
      setDisabled(false);
    };
    if (delConfirmed) handleDelete();
  }, [delConfirmed, id, refresh, token]);

  return (
    <>
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDelConfirmed}
        question={"Czy na pewno chcesz usunąć ten film?"}
      />
      <Button
        variant="danger"
        className="ml-3 mt-3"
        onClick={() => setShowModal(true)}
        disabled={disabled}
      >
        Usuń film
      </Button>
    </>
  );
};

export default DeleteVideo;
