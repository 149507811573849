import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { KeysForm, KeysList } from "./components";

const GenerateApiKeys = () => {
  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => setRefresh((prevState) => !prevState);
  return (
    <Container>
      <KeysList refresh={refresh} handleRefresh={handleRefresh} />
      <KeysForm handleRefresh={handleRefresh} />
    </Container>
  );
};

export default GenerateApiKeys;
