import React, { useState, useRef, useContext, useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { DeletionModal } from "components";
import { Contact, SectionForm } from "../";
import {
  moveSection,
  deleteSection,
} from "Views/ContactFoundation/ContactFoundation.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertContext, UserContext } from "context";
import {
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { isOdd } from "constants/other";
import { useWindowSize } from "hooks";
import { staffTypes } from "constants/staffTypes";

const Section = ({
  section,
  sections,
  handleRefresh,
  index,
  disabled,
  setDisabled,
}) => {
  const [show, setShow] = useState(false);

  const [width] = useWindowSize();

  const [showDel, setShowDel] = useState(false);
  const [delConfirmed, setDelConfirmed] = useState(false);

  const alertC = useRef(useContext(AlertContext));
  const user = useContext(UserContext);

  const handleMove = async (direction) => {
    setDisabled(true);
    try {
      await moveSection(user.token, direction, section.id);
      alertC.current.showAlert("Pomyślnie przesunięto sekcję.", "success");
      handleRefresh();
    } catch (err) {
      alertC.current.showAlert(err.message);
    }
    setDisabled(false);
  };

  useEffect(() => {
    const handleDelete = async () => {
      setDelConfirmed(false);
      setDisabled(true);
      try {
        await deleteSection(user.token, section.id);
        alertC.current.showAlert("Poprawnie usunięto sekcję.", "success");
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      handleRefresh();
      setDisabled(false);
    };

    if (delConfirmed) handleDelete();
  }, [delConfirmed, handleRefresh, section.id, setDisabled, user.token]);

  const chooseLeftIcon = useMemo(() => {
    if (width < 768) {
      return <FontAwesomeIcon icon={faArrowUp} />;
    } else if (!isOdd(index)) {
      return <FontAwesomeIcon icon={faArrowUp} />;
    } else {
      return <FontAwesomeIcon icon={faArrowLeft} />;
    }
  }, [index, width]);

  const chooseRightIcon = useMemo(() => {
    if (width < 768) {
      return <FontAwesomeIcon icon={faArrowDown} />;
    } else if (!isOdd(index)) {
      return <FontAwesomeIcon icon={faArrowRight} />;
    } else {
      return <FontAwesomeIcon icon={faArrowDown} />;
    }
  }, [index, width]);

  const isAdmin = user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR);

  return (
    <div className="mb-3 border-top py-3">
      <div className="mb-2">
        {isAdmin && (
          <>
            <Button
              className=""
              disabled={disabled}
              onClick={() => setShow(true)}
            >
              Edytuj
            </Button>{" "}
            <SectionForm
              token={user.token}
              handleClose={() => setShow(false)}
              show={show}
              section={section}
              disabled={disabled}
              setDisabled={setDisabled}
              handleRefresh={handleRefresh}
            />
            <DeletionModal
              show={showDel}
              setShow={setShowDel}
              delConfirmed={setDelConfirmed}
              question={"Czy na pewno chcesz usunąć tę sekcję?"}
            />
            <Button
              variant="danger"
              className="ml-2"
              disabled={disabled}
              onClick={() => setShowDel(true)}
            >
              X
            </Button>{" "}
            {index !== 0 && (
              <Button
                variant="info"
                disabled={disabled}
                className="ml-2"
                onClick={() => handleMove("up")}
                data-testid="moveup"
              >
                {chooseLeftIcon}
              </Button>
            )}
            {index !== sections.length - 1 && (
              <Button
                variant="info"
                className="ml-2"
                disabled={disabled}
                data-testid="movedown"
                onClick={() => handleMove("down")}
              >
                {chooseRightIcon}
              </Button>
            )}
          </>
        )}
      </div>
      <h3 className="mb-3">{section.title}</h3>
      {section.contacts.map((contact) => (
        <Contact
          key={contact.id}
          contact={contact}
          sections={sections}
          handleRefresh={handleRefresh}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      ))}
    </div>
  );
};

export default Section;
