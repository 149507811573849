import React, { useState, useContext, useEffect, useRef } from "react";
import { registerLocale } from "react-datepicker";
import { Form, Container, Card, Button, Row, Alert } from "react-bootstrap";
import { voivodeships } from "constants/voivodeships";
import FormGroup from "components/FormGroup";
import {
  sendData,
  getCategories,
  getTypes,
  getOffer,
  sendPhoto,
} from "Views/OfferForm/functions/fetchData";
import { UserContext, AlertContext } from "context";
import polish from "date-fns/locale/pl";
import { useHistory, useParams } from "react-router-dom";
import { staffTypes } from "constants/staffTypes";
import { approveFileSize } from "utils";

registerLocale("pl", polish);

const OfferForm = () => {
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [arrays, setArrays] = useState({ types: [], categories: [] });
  const [disabled, setDisabled] = useState(false);
  const [err, setErr] = useState(false);
  let { id } = useParams();
  let photo = useRef(null);
  const [label, setLabel] = useState("");
  const [photoFile, setPhotoFile] = useState("");
  const [fullAddress, setFullAddress] = useState(false);
  const [offer, setOffer] = useState({
    offer_name: "",
    company_name: "",
    company_address: {
      city: "",
      street: "",
      street_number: "",
      postal_code: "",
    },
    voivodeship: voivodeships[0],
    description: "",
    expiration_date: "",
    category: "",
    type: "",
    salary_min: "",
    salary_max: "",
  });

  const context = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));
  useEffect(() => {
    const abortController = new AbortController();
    if (!abortController.signal.aborted) setDisabled(true);
    const loadData = async (token) => {
      let values;
      try {
        values = await Promise.all([
          getCategories(abortController.signal),
          getTypes(abortController.signal),
          id && getOffer(token, id, abortController.signal),
        ]);
      } catch (err) {
        if (!abortController.signal.aborted) {
          if (err.message === "getOffer") {
            alertC.current.showAlert("Wystąpił błąd przy pobieraniu oferty.");
            history.push("/offerForm");
          } else {
            setDisabled(false);
            setErr(true);
          }
          return;
        }
      }
      if (!abortController.signal.aborted) {
        const [categories, types, loadedOffer] = values;

        setArrays({ categories, types });
        setOffer((prev) => ({
          ...prev,
          company_address: context.data.company_address,
          company_name: context.data.company_name,
          category: categories[0],
          type: types[0],
          ...loadedOffer,
        }));
        setDisabled(false);
      }
    };
    loadData(context.token);
    return () => abortController.abort();
  }, [
    context.data.company_address,
    context.data.company_name,
    context.token,
    history,
    id,
  ]);

  const submit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setDisabled(true);
      try {
        const res = await sendData(
          {
            ...offer,
            company_address: {
              city: offer.company_address.city,
              street: fullAddress ? offer.company_address.street : null,
              street_number: fullAddress
                ? offer.company_address.street_number
                : null,
              postal_code: fullAddress
                ? offer.company_address.postal_code
                : null,
            },
            company_logo: context?.data.picture_url,
            expiration_date: expiration_date?.toISOString().substr(0, 10),
            salary_min: salary_min?.replace(",", "."),
            salary_max: salary_max?.replace(",", "."),
          },
          context.token,
          id
        );
        if (photoFile) {
          try {
            let newId = res?.offer_id ? res.offer_id : id;
            await sendPhoto(context.token, newId, photoFile);
            alertC.current.showAlert("Przesłano ofertę", "success");
            return history.push(`/jobOffers/${id || res.offer_id}`);
          } catch (e) {
            alertC.current.showAlert("Nie udało się wysłać zdjęcia.");
          }
        } else {
          alertC.current.showAlert("Przesłano ofertę", "success");
          return history.push(`/jobOffers/${id || res.offer_id}`);
        }
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
    }
    setDisabled(false);
    setValidated(true);
  };

  const msg = err ? (
    <Alert variant="danger">
      Wystąpił błąd w trakcie ładowania formularza.
    </Alert>
  ) : null;

  const onChange = () => {
    const photoNew = photo?.current?.files?.[0];
    if (approveFileSize(photoNew)) {
      const filename = photo.current?.files?.[0]?.name;
      setPhotoFile(photoNew);
      setLabel(filename);
    } else {
      alertC.current.showAlert(
        "Wybrany plik jest za duży. Maksymalny rozmiar załącznika to 15 MB."
      );
      photo.current.fileInput = null;
    }
  };

  const {
    offer_name,
    company_name,
    description,
    expiration_date,
    voivodeship,
    category,
    type,
    salary_min,
    salary_max,
  } = offer;

  return (
    <Container className="offerForm">
      <Card className="offerForm__card">
        <Card.Header as="h2" className="offerForm__header">
          Dodaj ofertę
        </Card.Header>
        <Card.Body>
          <Form
            onSubmit={submit}
            noValidate
            validated={validated}
            className="offerForm__form"
          >
            <div className="offerForm__wrapper">
              <FormGroup
                header="Nazwa oferty"
                setVal={(val) => setOffer({ ...offer, offer_name: val })}
                val={offer_name}
                incorrect="Podaj nazwę stanowiska"
                length={{ min: 1, max: 100 }}
                id="offer_name"
                required
              />
              <FormGroup
                header="Nazwa firmy"
                id="company_name"
                setVal={(val) => setOffer({ ...offer, company_name: val })}
                val={company_name}
                incorrect="Podaj nazwę firmy"
                length={{ min: 1, max: 50 }}
                required
              />
              <FormGroup
                header="Miasto"
                id="company_city"
                setVal={(city) =>
                  setOffer({
                    ...offer,
                    company_address: { ...offer.company_address, city },
                  })
                }
                val={offer?.company_address?.city}
                incorrect="Podaj miasto"
                length={{ min: 1, max: 40 }}
                required
              />
              <Form.Check
                className="mb-2"
                type="checkbox"
                label="Podaję pełny adres"
                onChange={(e) => setFullAddress(!fullAddress)}
              />
              {fullAddress ? (
                <>
                  <FormGroup
                    header="Ulica"
                    id="company_street"
                    setVal={(street) =>
                      setOffer({
                        ...offer,
                        company_address: { ...offer.company_address, street },
                      })
                    }
                    val={offer.company_address?.street}
                    incorrect="Podaj ulicę"
                    length={{ min: 1, max: 120 }}
                    required
                  />
                  <FormGroup
                    header="Numer budynku"
                    id="company_street_number"
                    setVal={(street_number) =>
                      setOffer({
                        ...offer,
                        company_address: {
                          ...offer.company_address,
                          street_number,
                        },
                      })
                    }
                    val={offer.company_address?.street_number}
                    incorrect="Podaj numer bundynku"
                    length={{ min: 1, max: 20 }}
                    required
                  />
                  <FormGroup
                    header="Kod pocztowy"
                    id="company_city"
                    setVal={(postal_code) =>
                      setOffer({
                        ...offer,
                        company_address: {
                          ...offer.company_address,
                          postal_code,
                        },
                      })
                    }
                    val={offer.company_address?.postal_code}
                    incorrect="Podaj kod pocztowy"
                    length={{ min: 1, max: 6 }}
                    required
                  />
                </>
              ) : null}
              <FormGroup
                header="Województwo"
                id="voivodeship"
                array={voivodeships}
                type="select"
                setVal={(val) => setOffer({ ...offer, voivodeship: val })}
                val={voivodeship}
                required
              />
              <FormGroup
                header="Wynagrodzenie od (zł / miesiąc)"
                id="salary_min"
                type="number"
                setVal={(val) => setOffer({ ...offer, salary_min: val })}
                val={salary_min}
                required
                step={0.01}
                length={{ min: 0, max: offer.salary_max * 1 }}
                incorrect="Pole musi być mniejsze od maksymalnej stawki"
              />
              <FormGroup
                header="Wynagrodzenie do (zł / miesiąc)"
                id="salary_max"
                type="number"
                setVal={(val) => setOffer({ ...offer, salary_max: val })}
                val={salary_max}
                required
                step={0.01}
                length={{
                  min: offer.salary_min * 1,
                  max: 999999,
                }}
                incorrect="Pole musi być większe od minimalnej stawki"
              />
              <Form.Group>
                <Form.Label htmlFor="custom-file">Zdjęcie</Form.Label>
                <Form.File
                  className="text-nowrap text-truncate"
                  id="custom-file"
                  ref={photo}
                  custom
                  onChange={onChange}
                  label={label || "Dodaj plik..."}
                  accept="image/*"
                  data-browse="Wybierz plik"
                />
              </Form.Group>
            </div>
            <div className="offerForm__wrapper">
              <FormGroup
                header="Opis oferty"
                id="description"
                type="textarea"
                setVal={(val) => setOffer({ ...offer, description: val })}
                val={description}
                incorrect="Podaj opis"
                length={{ min: 1, max: 1000 }}
                required
              />
              <FormGroup
                header="Kategoria"
                id="category"
                setVal={(val) => setOffer({ ...offer, category: val })}
                val={category}
                type="select"
                array={arrays.categories}
                required
                incorrect="Podaj kategorię"
              />
              <FormGroup
                header="Rodzaj umowy"
                id="type"
                setVal={(val) => setOffer({ ...offer, type: val })}
                val={type}
                type="select"
                array={arrays.types}
                required
                incorrect="Podaj rodzaj umowy"
              />
              <FormGroup
                header="Ważna do"
                id="expiration_date"
                type="date"
                setVal={(val) => setOffer({ ...offer, expiration_date: val })}
                val={expiration_date}
                required
              />
            </div>
            <Row className="w-100 justify-content-center align-items-center m-0">
              <Button
                variant="primary"
                type="submit"
                className=""
                data-testid="submitBtn"
                disabled={
                  context.data.group_type?.includes(staffTypes.GUEST) ||
                  disabled
                }
              >
                {disabled ? "Ładowanie..." : "Dodaj"}
              </Button>
            </Row>
          </Form>
          {msg && (
            <Row className="w-100 justify-content-center align-items-center ml-0 mb-0 mt-3">
              {msg}
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default OfferForm;
