import React, { useState, useEffect, useContext } from "react";
import { Container, Alert, Button } from "react-bootstrap";
import { getDocuments } from "./DocumentsEditor.api";
import { UserContext } from "context";
import { DocumentsForm, Document } from "./components";

const DocumentsEditor = () => {
  const user = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [show, setShow] = useState(false);

  const [documents, setDocuments] = useState([]);

  const handleRefresh = () => setRefresh((prevState) => !prevState);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDocuments = async () => {
      if (!abortController.signal.aborted) {
        setLoading(true);
        setDisabled(true);
      }
      try {
        const response = await getDocuments(abortController.signal);
        setDocuments(response);
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(err.message);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
        setDisabled(false);
      }
    };
    fetchDocuments();

    return () => abortController.abort();
  }, [user.token, refresh]);

  const message = loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : error ? (
    <Alert variant="danger">{error}</Alert>
  ) : documents.length === 0 ? (
    <Alert variant="info">Brak dokumentów</Alert>
  ) : null;

  return (
    <Container>
      <h3 className="text-center">Dokumenty</h3>
      <Button
        disabled={disabled}
        className="mb-3"
        onClick={() => setShow(true)}
      >
        Dodaj dokument
      </Button>
      <DocumentsForm
        handleClose={() => setShow(false)}
        show={show}
        handleRefresh={handleRefresh}
        token={user.token}
        setDisabled={setDisabled}
        disabled={disabled}
      />
      {message ||
        documents.map((document) => (
          <Document
            key={document.id}
            document={document}
            handleRefresh={handleRefresh}
            disabled={disabled}
            setDisabled={setDisabled}
          />
        ))}
    </Container>
  );
};

export default DocumentsEditor;
