import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

const buttonValues = [
  { text: "Mam 16 lat lub więcej", value: true, variant: "outline-dark" },
  { text: "Nie mam jeszcze 16 lat", value: false, variant: "outline-dark" },
];

const Over16Buttons = ({ over16years, setOver16Years }) => {
  return (
    <div className="my-3 d-flex justify-content-center">
      <ButtonGroup toggle>
        {buttonValues.map(({ text, value, variant }) => (
          <ToggleButton
            key={text}
            type="radio"
            variant={variant}
            name="radio"
            value={over16years}
            checked={over16years === value}
            onChange={(e) => setOver16Years(value)}
          >
            {text}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default Over16Buttons;
