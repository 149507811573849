import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "react-bootstrap";
import { DeletionModal } from "components";
import { DocumentsForm } from "../";
import { deleteDocument } from "Views/DocumentsEditor/DocumentsEditor.api";
import { AlertContext, UserContext } from "context";
import { staffTypes } from "constants/staffTypes";
import proxy from "config/api";

const Document = ({ document, handleRefresh, disabled, setDisabled }) => {
  const [show, setShow] = useState(false);

  const [showDel, setShowDel] = useState(false);
  const [delConfirmed, setDelConfirmed] = useState(false);

  const alertC = useRef(useContext(AlertContext));
  const user = useContext(UserContext);

  useEffect(() => {
    const handleDelete = async () => {
      setDelConfirmed(false);
      setDisabled(true);
      try {
        await deleteDocument(user.token, document.id);
        alertC.current.showAlert("Poprawnie usunięto dokument.", "success");
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDisabled(false);
      handleRefresh();
    };

    if (delConfirmed) handleDelete();
  }, [delConfirmed, document.id, handleRefresh, setDisabled, user.token]);

  const isAdmin = user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR);

  return (
    <div className="mb-3">
      <div className="mb-2">
        <DocumentsForm
          handleClose={() => setShow(false)}
          show={show}
          handleRefresh={handleRefresh}
          token={user.token}
          setDisabled={setDisabled}
          disabled={disabled}
          prevDocument={document}
        />
        {isAdmin && (
          <>
            <Button
              className=""
              disabled={disabled}
              onClick={() => setShow(true)}
            >
              Edytuj
            </Button>
            <DeletionModal
              show={showDel}
              setShow={setShowDel}
              delConfirmed={setDelConfirmed}
              question={"Czy na pewno chcesz usunąć ten dokument?"}
            />
            <Button
              variant="danger"
              className="ml-2"
              disabled={disabled}
              onClick={() => setShowDel(true)}
            >
              X
            </Button>
          </>
        )}
      </div>
      <div className="d-flex align-items-center mb-3">
        <p className="font-weight-bold mb-0">{document.id}</p>
        <Button
          className="p-0 ml-2"
          variant="link"
          target="_blank"
          rel="noopener noreferrer"
          href={`${proxy.plainFiles}${document.document_url}`}
        >
          Zobacz dokument
        </Button>
      </div>
    </div>
  );
};
export default Document;
