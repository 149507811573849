import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import proxy from "config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCheckCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { paths } from "constants/paths";
import PropTypes from "prop-types";

const OfferApplication = ({ application, handleDelete }) => {
  const WasReadIcon = useMemo(() => {
    if (!application.was_read) {
      return (
        <p className="text-warning d-inline-block mr-1 mb-0">
          <FontAwesomeIcon icon={faClock} spin />
          Nieprzeczytana
        </p>
      );
    } else {
      return (
        <p className="text-success d-inline-block mr-1  mb-0">
          <FontAwesomeIcon icon={faCheckCircle} />
          Przeczytana
        </p>
      );
    }
  }, [application.was_read]);

  const url = window.location.href.replace(window.location.pathname, "");

  return (
    <div className="offerApplication__container mb-3">
      <span className="offerApplication__button">Status: {WasReadIcon}</span>
      <h4 className="d-inline-block mb-0 text-center">
        {application.offer_name}
      </h4>
      <div className="offerApplication__btnContainer">
        <Button
          variant="outline-primary"
          target="_blank"
          rel="noopener noreferrer"
          className="offerApplication__button--CV offerApplication__button p-2"
          href={`${proxy.plainFiles}${application.cv_url}`}
        >
          Zobacz wysłane CV
        </Button>
        <Button
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 offerApplication__button"
          href={`${url}${paths.JOB_OFFERS}/${application.job_offer}`}
        >
          Przejdź do oferty
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          className="offerApplication__button--delete"
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
    </div>
  );
};

OfferApplication.propTypes = {
  application: PropTypes.shape({
    was_read: PropTypes.bool.isRequired,
    job_offer: PropTypes.string.isRequired,
    cv_url: PropTypes.string.isRequired,
    offer_name: PropTypes.string.isRequired,
  }).isRequired,
};

export default OfferApplication;
