import React, { useEffect, useState, useContext, useRef } from "react";
import { Container, Card, Alert } from "react-bootstrap";
import { getApplicationList, deleteApplication } from "./functions";
import { UserContext, AlertContext } from "context";
import { OfferApplication } from "./components";
import { Pagination, DeletionModal } from "components";
import { useLocation } from "react-router-dom";
import qs from "query-string";

const ListOfApplications = () => {
  const [applications, setApplications] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);

  const [idDel, setIdDel] = useState(-1);

  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));

  const handleDelete = (id) => {
    setIdDel(id);
    setShowModal(true);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const fetchApplications = async () => {
      if (!abortController.signal.aborted) setLoading(true);
      try {
        const response = await getApplicationList(
          user.token,
          page,
          abortController.signal
        );
        setApplications(response);
      } catch (err) {
        if (!abortController.signal.aborted) setError(true);
      }
      if (!abortController.signal.aborted) setLoading(false);
    };
    fetchApplications();
    return () => abortController.abort();
  }, [page, user.token, refresh]);

  useEffect(() => {
    const handleDelete = async () => {
      setLoading(true);
      try {
        const res = await deleteApplication(user.token, idDel);
        alertC.current.showAlert(res.message, "success");
        setDeletionConfirmed(false);
        setRefresh((prevState) => !prevState);
      } catch (err) {
        setDeletionConfirmed(false);
        alertC.current.showAlert(err.message);
      }
    };
    if (deletionConfirmed) handleDelete();
  }, [deletionConfirmed, idDel, user.token]);

  const queryParams = qs.parse(location.search, { parseNumbers: true });
  if (typeof queryParams.page === "number" && queryParams.page !== page) {
    setPage(queryParams.page);
  }

  const message = loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : error ? (
    <Alert variant="danger">Nie udało się pobrać listy aplikacji</Alert>
  ) : applications.count === 0 ? (
    <Alert variant="info">Nie zaaplikowałeś do żadnej oferty</Alert>
  ) : null;

  return (
    <Container>
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDeletionConfirmed}
        question={"Czy na pewno chcesz usunąć tę aplikację na ofertę pracy?"}
      />
      <Card>
        <Card.Header as="h3">Oferty, na które aplikowałeś:</Card.Header>
        <Card.Body>
          {message ||
            applications.results?.map((application) => (
              <OfferApplication
                key={application.id}
                application={application}
                handleDelete={() => handleDelete(application.job_offer)}
              />
            ))}
        </Card.Body>
        {!!applications.count && (
          <Card.Footer>
            <Pagination
              max={Math.ceil(applications.count / 10)}
              current={page}
            />
          </Card.Footer>
        )}
      </Card>
    </Container>
  );
};

export default ListOfApplications;
