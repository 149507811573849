import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { getBlogs } from "./api";
import proxy from "config/api";
import BlogPosts from "./components/BlogPosts";
import { UserContext } from "context";
import { NewBlogButton } from "./components/NewBlogButton/NewBlogButton";

const Blog = () => {
  const user = useContext(UserContext);

  const [blog, setBlog] = useState([]);
  const [error, setError] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const abortController = new AbortController();
    const loadBlog = async (category) => {
      try {
        let res = await getBlogs(abortController.signal);
        for (const cat of res) {
          if (cat.id === parseInt(category, 10)) {
            setBlog(cat);
          }
        }
      } catch (e) {
        console.log(e);

        if (!abortController.signal.aborted) {
          setError(true);
        }
      }
    };
    loadBlog(id);
    return () => abortController.abort();
  }, [id]);

  return (
    <Container className="blog-font px-0">
      <picture>
        {blog.header_mobile_url && (
          <source
            className="blog-header"
            srcSet={`${proxy.plainFiles + blog.header_mobile_url} 1x`}
            media={"(max-width: 500px)"}
          />
        )}
        {blog.header_pc_url && (
          <img
            className="blog-header"
            srcSet={`${proxy.plainFiles + blog.header_pc_url} 2x`}
            alt="Full Logo"
          />
        )}
      </picture>
      <div className="padding__text">
        {blog.show_title && <div className="blog-title">{blog.name}</div>}
        {blog.description && (
          <div className="blog-description">{blog.description}</div>
        )}
        <BlogPosts category={id} />
        {error ? <div className="blogs-loading">WYSTĄPIŁ BŁĄD</div> : null}
        <Row className="d-flex justify-content-center m-0 mt-3">
          <NewBlogButton user={user} />
        </Row>
      </div>
    </Container>
  );
};

export default Blog;
