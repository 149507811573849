import React, { Suspense, useState, useEffect } from "react";
import { ShowShapes } from "../";
import { getShapes } from "../../functions";
import { Card, Accordion, Button, Alert } from "react-bootstrap";
import { PostTile } from "Views/Blog/components";
const ColorPicker = React.lazy(() =>
  import("components/ColorPicker/ColorPicker")
);

const PostTileCreator = ({
  token,
  setColor,
  color,
  setBackgroundUrl,
  backgroundUrl,
  post,
  cover,
}) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shapes, setShapes] = useState({});
  useEffect(() => {
    const fetchShapes = async () => {
      setLoading(true);
      try {
        const res = await getShapes(token);
        setShapes(res);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    };
    fetchShapes();
  }, [token]);

  const message = error ? (
    <Alert variant="danger">Błąd wczytywania kształtów</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : null;

  return (
    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Utwórz kafelek posta
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {message || (
              <ShowShapes
                shapes={shapes}
                setBackgroundUrl={setBackgroundUrl}
                backgroundUrl={backgroundUrl}
                color={color}
              />
            )}
            <div className="createTile__wrapper">
              <Suspense fallback={<Alert variant="info">Ładowanie...</Alert>}>
                <ColorPicker background={color} setBackground={setColor} />
              </Suspense>
              <div className="createTile__postWrapper">
                <PostTile
                  post={post}
                  cover={cover}
                  shape={shapes[post.background]}
                />
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default PostTileCreator;
