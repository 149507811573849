import React from "react";
import { staffTypes } from "constants/staffTypes";
import { Button } from "react-bootstrap";

const DeletePostButton = ({ user, setDeletePostAlert }) => {
  if (user?.data?.group_type?.includes(staffTypes.BLOG_CREATOR)) {
    return (
      <Button
        className="blogs-edit-button"
        variant="danger"
        onClick={() => setDeletePostAlert(true)}
      >
        <span className="align-self-center">USUŃ POST</span>
      </Button>
    );
  } else return null;
};

export default DeletePostButton;
