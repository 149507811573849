import React, { useEffect, useState } from "react";
import { getWorkshopPosts } from "../../api";
import { LinkContainer } from "react-router-bootstrap";
import PostTile from "Views/Blog/components/PostTile";

const WorkshopYear = ({ workshop }) => {
  const [workshopPosts, setWorkshopPosts] = useState([]);

  useEffect(() => {
    const loadWorkshopPosts = async () => {
      try {
        let res = await getWorkshopPosts(workshop.id);
        setWorkshopPosts(res.results);
      } catch (e) {}
    };
    loadWorkshopPosts();
  }, [workshop.id]);

  return (
    <div className="w-100 mb-5">
      <div className="blog-title d-flex flex-column align-items-center">
        {workshop.year}
      </div>
      {workshop.description && (
        <p className="blog-description"> {workshop.description}</p>
      )}
      <div className="blog-tiles">
        {workshopPosts.map((post) => (
          <LinkContainer to={"/workshops/post/" + post.id} key={post.id}>
            <div className="blog-tile">
              <PostTile post={post} />
            </div>
          </LinkContainer>
        ))}
      </div>
    </div>
  );
};

export default WorkshopYear;
