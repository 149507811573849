import * as React from "react";
import { useQuery } from "hooks";
import { Alert as RBAlert } from "react-bootstrap";
import { getDocuments } from "Views/DocumentsEditor/DocumentsEditor.api";
import documents from "constants/documents";
import proxy from "config/api";
import { Alert } from "components";
const { GENERAL_POLICY } = documents;

const RedirectToRegulations = () => {
  const [data, error, loading] = useQuery([], getDocuments);
  const policyUrl = React.useMemo(
    () => data.find((document) => document.id === GENERAL_POLICY)?.document_url,
    [data]
  );

  if (policyUrl) {
    window.location = `${proxy.plainFiles}${policyUrl}`;
  }

  return (
    <>
      <Alert loading={loading} error={error} />
      {!loading ? (
        policyUrl ? (
          <RBAlert variant="info" data-testid="redirecting">
            Przekierowuje...
          </RBAlert>
        ) : (
          <RBAlert variant="info" data-testid="notAdded">
            Regulamin aktualnie nie jest dodany.
          </RBAlert>
        )
      ) : null}
    </>
  );
};

export default RedirectToRegulations;
