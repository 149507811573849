import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const acceptTerms = async (id) => {
  const url = `${proxy.termsAcceptance}code/${id}/`;
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "PATCH",
    headers,
  });

  if (response.status === 200) {
    return response.json();
  }
  throw new Error(await errorMessageText(response));
};
