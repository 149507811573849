import React from "react";
import proxy from "config/api";

const CategoryTile = ({ category, active, setActive }) => {
  const { name, id, header_pc_url } = category;

  const handleClick = () => {
    setActive(category.id);
  };

  return (
    <div
      className={`categoryTile ${header_pc_url ? "" : "categoryTile--center"}`}
      data-testid="container"
      onClick={handleClick}
    >
      <div
        className={`categoryTile__choosen${active === id ? "" : "--disable"}`}
      ></div>
      {header_pc_url && (
        <div className="categoryTile__imgContainer">
          <img
            alt="Obrazek kategorii"
            className="categoryTile__img"
            src={`${proxy.plainFiles}${header_pc_url}`}
          />
        </div>
      )}
      <div className="categoryTile__content">
        <h5 className="mb-0">{name}</h5>
      </div>
    </div>
  );
};

export default CategoryTile;
