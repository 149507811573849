import proxy from "config/api";
import { errorMessageText } from "constants/other.js";

export const getWorkshops = async (signal, pageSize) => {
  const url = `${proxy.workshops}${pageSize ? `?page_size=${pageSize}` : ""}`;
  const headers = {
    "Content-Type": "application/json",
  };
  const response = await fetch(url, {
    method: "GET",
    headers,
    signal,
  });

  if (response.status === 200) {
    return response.json();
  }
  throw new Error(await errorMessageText(response));
};

export const getEvents = async (from, to, filters) => {
  const onlyFalseLabels = Object.keys(filters).filter((key) => filters[key]);
  let filterEx = "&exclude_label=";
  let filter = filterEx;
  filter += onlyFalseLabels.join(filterEx);
  const url = `${proxy.events}${
    from && to
      ? `?from_=${from}&to_=${to}${onlyFalseLabels.length ? filter : ""}`
      : ""
  }`;

  const headers = {
    "Content-Type": "application/json",
  };
  const response = await fetch(url, { method: "GET", headers });

  if (response.status === 200) {
    return response.json();
  }
  throw new Error(await errorMessageText(response));
};

export const getLabels = async (from, to) => {
  const url = `${proxy.events}labels/`;

  const headers = {
    "Content-Type": "application/json",
  };
  const response = await fetch(url, { method: "GET", headers });

  if (response.status === 200) {
    return response.json();
  }
  throw new Error(await errorMessageText(response));
};

export const sendEvent = async (token, event, id) => {
  const url = `${proxy.events}${id ? `${id}/` : "new/"}`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    method: id ? "PUT" : "POST",
    headers,
    body: JSON.stringify(event),
  });

  if ((response.status !== 201 && !id) || (response.status !== 200 && id)) {
    throw new Error(await errorMessageText(response));
  }
};

export const sendLabel = async (token, label, id) => {
  const url = `${proxy.events}labels/${id ? `${id}/` : "new/"}`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  const response = await fetch(url, {
    method: id ? "PUT" : "POST",
    headers,
    body: JSON.stringify(label),
  });

  if ((response.status !== 201 && !id) || (response.status !== 200 && id)) {
    throw new Error(await errorMessageText(response));
  }
};

export const deleteEvent = async (token, id) => {
  const url = `${proxy.events}${id}/`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  const response = await fetch(url, {
    method: "DELETE",
    headers,
  });

  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};

export const deleteLabel = async (token, id) => {
  const url = `${proxy.events}labels/${id}/`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  const response = await fetch(url, {
    method: "DELETE",
    headers,
  });

  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};
