import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import {
  CategoryCreator,
  TopicCreator,
  TreeOfSelectors,
  ExpertCreator,
  ExpertList,
  LabelCreator,
  LabelList,
} from "./components";
import { getCategories, getTopics } from "Views/BlogPostCreator/functions";
import { getExperts, getLabels } from "./functions";
import { UserContext } from "context";

const BlogSelectorCreator = () => {
  const user = useContext(UserContext);

  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [experts, setExperts] = useState([]);
  const [labels, setLabels] = useState([]);

  const [call, setCall] = useState(false);
  const [callExpert, setCallExpert] = useState(false);
  const [callLabel, setCallLabel] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [loadingExpert, setLoadingExpert] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState(false);

  const [error, setError] = useState(false);
  const [errorC, setErrorC] = useState(false);
  const [errorExpert, setErrorExpert] = useState(false);
  const [errorLabel, setErrorLabel] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchExperts = async () => {
      if (!abortController.signal.aborted) {
        setLoadingExpert(true);
      }
      try {
        const res = await getExperts(user.token, abortController.signal);

        setExperts(res);
      } catch (err) {
        if (!abortController.signal.aborted) {
          setErrorExpert(true);
        }
      }
      if (!abortController.signal.aborted) {
        setLoadingExpert(false);
      }
    };
    fetchExperts();
    return () => abortController.abort();
  }, [callExpert, user.token]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchCategories = async () => {
      if (!abortController.signal.aborted) {
        setLoadingC(true);
      }
      try {
        const res = await getCategories(user.token, abortController.signal);
        setCategories(res);
      } catch (err) {
        if (!abortController.signal.aborted) {
          setErrorC(true);
        }
      }
      if (!abortController.signal.aborted) {
        setLoadingC(false);
      }
    };
    fetchCategories();
    return () => abortController.abort();
  }, [user.token, call]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!abortController.signal.aborted) {
      setLoading(true);
    }
    const parseObject = (object) => {
      return object.map(({ category, ...rest }) => ({
        category: categories.find((item) => item.id === category),
        ...rest,
      }));
    };
    const fetchTopics = async () => {
      try {
        let res = await getTopics(
          user.token,
          categories.map(({ id }) => id),
          abortController.signal
        );
        setTopics(parseObject(res));
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(true);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    };
    fetchTopics();
    return () => abortController.abort();
  }, [categories, user.token]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchLabels = async () => {
      if (!abortController.signal.aborted) {
        setLoadingLabel(true);
      }
      try {
        const res = await getLabels(user.token, abortController.signal);
        setLabels(res);
      } catch (err) {
        if (!abortController.signal.aborted) {
          setErrorLabel(true);
        }
      }
      if (!abortController.signal.aborted) {
        setLoadingLabel(false);
      }
    };
    fetchLabels();
    return () => abortController.abort();
  }, [callLabel, user.token]);

  return (
    <Container>
      <TreeOfSelectors
        token={user.token}
        topics={topics}
        refresh={setCall}
        loadingObj={{ loadingC, loading }}
        errorObj={{ errorC, error }}
      />
      <CategoryCreator refresh={setCall} token={user.token} />
      <TopicCreator
        loading={loadingC}
        error={error}
        refresh={setCall}
        categories={categories.map(({ name, id }) => ({
          name,
          id,
        }))}
        token={user.token}
      />
      <ExpertList
        experts={experts}
        refresh={setCallExpert}
        token={user.token}
      />
      <ExpertCreator
        loading={loadingExpert}
        error={errorExpert}
        token={user.token}
        refresh={setCallExpert}
      />
      <LabelList
        token={user.token}
        refresh={setCallLabel}
        error={errorLabel}
        loading={loadingLabel}
        labels={labels}
      />
      <LabelCreator token={user.token} refresh={setCallLabel} />
    </Container>
  );
};

export default BlogSelectorCreator;
