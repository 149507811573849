import proxy from "config/api";
import { errorMessageText } from "constants/other.js";

export const sendContact = async (token, contact, id) => {
  const url = `${proxy.contact}contact/${id ? `${id}/` : ""}`;

  const formData = new FormData();

  formData.append("title", contact.title);
  formData.append("phone_number", contact.phone_number);
  formData.append("description", contact.description);
  !contact.logo?.fetched && formData.append("logo", contact.logo || "");

  const method = id ? "PATCH" : "POST";
  const headers = {
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, { method, headers, body: formData });

  if (response.status !== 200 && id) {
    throw new Error(await errorMessageText(response));
  }
  if (response.status !== 201 && !id) {
    throw new Error(await errorMessageText(response));
  }
};

export const deleteContact = async (token, id) => {
  const url = `${proxy.contact}contact/${id}/`;

  const method = "DELETE";
  const headers = {
    Authorization: `token ${token}`,
  };

  const response = await fetch(url, { method, headers });

  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};

export const getContacts = async () => {
  const url = `${proxy.contact}contacts/`;

  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method, headers });

  if (response.status === 200) {
    return response.json();
  }
  throw new Error(await errorMessageText(response));
};
