import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";

import { userTypes } from "constants/userTypes";

const ResendEmailButton = ({ user }) =>
  user.type === userTypes.STANDARD && user.data.facility_verification === 2 ? (
    <LinkContainer to="/resendEmail">
      <Button variant="primary">Wyślij ponownie mail do placówki</Button>
    </LinkContainer>
  ) : null;

export default ResendEmailButton;
