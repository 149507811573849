import React, { useState, useContext, useRef, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { CategoryCreator, TopicsEdit } from "../";
import { UserContext, AlertContext } from "context";
import { deleteType } from "../../functions";
import { DeletionModal } from "components";

const EditorModal = ({ data, refresh, categories, id }) => {
  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));
  //topic
  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [idDelete, setIdDelete] = useState(-1);

  const handleDeletionModal = (id) => {
    setShowModal(true);
    setIdDelete(id);
  };

  useEffect(() => {
    const handleDelete = async (type, id) => {
      try {
        await deleteType(user.token, id, type);
        alertC.current.showAlert("Pomyślnie usunięto.", "success");
      } catch (err) {
        alertC.current.showAlert("Nie udało się usunąć elementu.");
      }

      setDeletionConfirmed(false);
      setIdDelete(-1);

      refresh((prevState) => !prevState);
    };

    if (deletionConfirmed) handleDelete("topic", idDelete);
  }, [deletionConfirmed, idDelete, refresh, user.token]);

  return (
    <Accordion.Collapse eventKey={id}>
      <>
        <DeletionModal
          show={showModal}
          setShow={setShowModal}
          delConfirmed={setDeletionConfirmed}
          question={"Czy na pewno chcesz usunąć ten temat?"}
        />

        <CategoryCreator
          token={user.token}
          data={data.category}
          refresh={refresh}
        />
        <TopicsEdit
          data={data}
          categories={categories}
          refresh={refresh}
          token={user.token}
          onClickDelete={handleDeletionModal}
        />
      </>
    </Accordion.Collapse>
  );
};

export default EditorModal;
