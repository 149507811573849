import React, { useState, useEffect, useContext, useRef } from "react";
import { registerLocale } from "react-datepicker";
import { Form, Button, Col, Row } from "react-bootstrap";
import FormGroup from "components/FormGroup";
import { voivodeships } from "constants/voivodeships";
import polish from "date-fns/locale/pl";
import { getSelects } from "Views/OfferForm/functions/fetchData";
import { UserContext, AlertContext } from "context";
import { IndexLinkContainer } from "react-router-bootstrap";
import { userTypes } from "constants/userTypes";
import { userStatuses } from "constants/userStatuses";
import { useHistory } from "react-router-dom";
registerLocale("pl", polish);

const Filter = ({ setFilters, disabled, filtersMain }) => {
  const [keyword, setKeyword] = useState("");
  const [voivodeship, setVoivodeship] = useState("Województwo");
  const [pageSize, setPageSize] = useState(10);
  const [minExpirationDate, setMinExpirationDate] = useState();
  const [category, setCategory] = useState("Kategoria");
  const [type, setType] = useState("Rodzaj umowy");
  const [city, setCity] = useState(filtersMain?.city || "");
  const [arrays, setArrays] = useState([]);
  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));
  const history = useHistory();

  useEffect(() => {
    const abortController = new AbortController();
    const loadSelects = async () => {
      let res;
      try {
        res = await getSelects(abortController.signal);
      } catch (e) {
        if (!abortController.signal.aborted) {
          res = { categories: [], types: [] };
          alertC.current.showAlert("Nie udało się pobrać filtrów.");
        }
      }
      if (!abortController.signal.aborted) setArrays(res);
    };
    loadSelects();
    return () => abortController.abort();
  }, []);

  const filter = (event) => {
    event.preventDefault();
    history.push("/jobOffers");
    let newDate;
    if (minExpirationDate) {
      const year = minExpirationDate.getFullYear();
      const month =
        minExpirationDate.getMonth() + 1 < 10
          ? `0${minExpirationDate.getMonth() + 1}`
          : minExpirationDate.getMonth() + 1;
      const day =
        minExpirationDate.getDate() < 10
          ? `0${minExpirationDate.getDate()}`
          : minExpirationDate.getDate();
      newDate = `${year}-${month}-${day}`;
    }

    const voivodeshipV =
      voivodeship !== "Województwo" ? voivodeship : undefined;
    const categoryV = category !== "Kategoria" ? category : undefined;
    const typeV = type !== "Rodzaj umowy" ? type : undefined;
    const keywordV = keyword !== "" ? keyword : undefined;
    setFilters({
      page: 1,
      voivodeship: voivodeshipV,
      pageSize,
      minExpirationDate: newDate,
      category: categoryV,
      type: typeV,
      offer_name: keywordV,
      city,
    });
  };

  const deleteFilter = (e) => {
    history.push("/jobOffers");
    setVoivodeship("Województwo");
    setPageSize(10);
    setCategory("Kategoria");
    setType("Rodzaj umowy");
    setCity("");
    setMinExpirationDate("");
    setFilters({ page: 1, pageSize: 10 });
    setKeyword("");
  };

  return (
    <Form className="job-offers-filter" onSubmit={filter}>
      <Row className="m-0">
        <Col xs={12} md={8} xl={9} className="align-self-center">
          <Form.Row>
            <FormGroup
              as={Col}
              xs={12}
              md={4}
              header="Stanowisko"
              val={keyword}
              setVal={setKeyword}
              type="text"
              id="keyword"
              showHeader={false}
              customClassname="job-offers-filter-text"
            />
            <FormGroup
              as={Col}
              xs={12}
              md={4}
              header="Województwo"
              array={voivodeships}
              val={voivodeship}
              setVal={setVoivodeship}
              type="select"
              id="voivodeship"
              defaultSelect="Województwo"
              showHeader={false}
              customClassname="job-offers-filter-text"
            />
            <FormGroup
              as={Col}
              xs={12}
              md={4}
              header="Miasto"
              val={city}
              setVal={setCity}
              id="city"
              showHeader={false}
              customClassname="job-offers-filter-text"
            />
            <FormGroup
              as={Col}
              xs={12}
              md={4}
              header="Ważna do"
              val={minExpirationDate}
              setVal={setMinExpirationDate}
              type="date"
              id="minExpirationDate"
              showHeader={false}
              customClassname="job-offers-filter-text"
            />
            <FormGroup
              as={Col}
              xs={12}
              md={4}
              header="Kategoria"
              type="select"
              array={arrays.categories}
              val={category}
              setVal={setCategory}
              id="category"
              defaultSelect="Kategoria"
              showHeader={false}
              customClassname="job-offers-filter-text"
            />
            <FormGroup
              as={Col}
              xs={12}
              md={4}
              header="Rodzaj umowy"
              type="select"
              array={arrays.types}
              val={type}
              setVal={setType}
              id="type"
              defaultSelect="Rodzaj umowy"
              showHeader={false}
              customClassname="job-offers-filter-text"
            />
          </Form.Row>
        </Col>
        <Col
          xs={12}
          md={4}
          xl={3}
          className="d-flex flex-column align-items-end"
        >
          <Button
            type="submit"
            className="job-offers-filter-button"
            variant="blue"
            disabled={disabled}
          >
            {disabled ? "Ładowanie..." : "Filtruj oferty"}
          </Button>
          <Button
            variant="outline-blue"
            className="job-offers-filter-button"
            onClick={deleteFilter}
            disabled={disabled}
          >
            {disabled ? "Ładowanie..." : "Wyczyść filtry"}
          </Button>

          {user.type === userTypes.EMPLOYER &&
          user.data &&
          user.data.status === userStatuses.VERIFIED ? (
            <IndexLinkContainer as={Button} to="/offerForm">
              <Button
                variant="success"
                className="job-offers-filter-button mb-3"
              >
                Dodaj ofertę
              </Button>
            </IndexLinkContainer>
          ) : null}
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
