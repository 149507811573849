import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const getApplicationList = async (token, page, signal) => {
  const url = `${proxy.job}user/application_list/?page=${page}&page_size=10`;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  const res = await fetch(url, { headers, method: "GET", signal });
  if (res.status === 200) {
    return res.json();
  } else {
    throw res.status;
  }
};

export const deleteApplication = async (token, id) => {
  const url = `${proxy.job}job-offers/application/${id}/`;

  const headers = {
    Authorization: `Token ${token}`,
  };

  const res = await fetch(url, { headers, method: "DELETE" });
  if (res.status === 200) {
    return res.json();
  } else {
    throw new Error(await errorMessageText(res));
  }
};
