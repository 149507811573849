import React, { useContext, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { getBlogPost, removePost } from "./api";
import YouTube from "react-youtube";
import { AlertContext, UserContext } from "context";
import DeletionModal from "components/DeletionModal/DeletionModal";
import BlogComments from "./components/BlogComments";
import EditPostButton from "./components/EditPostButton";
import DeletePostButton from "./components/DeletePostButton";
import { YouTubeGetID } from "constants/other";

const BlogPost = () => {
  const user = useContext(UserContext);
  const alert = useRef(useContext(AlertContext));
  const [post, setPost] = useState({ header: null, comments: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [deletePostAlert, setDeletePostAlert] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { id, category } = useParams();
  const history = useHistory();

  useEffect(() => {
    const abortController = new AbortController();
    const loadBlogPost = async (id) => {
      if (!abortController.signal.aborted) {
        setLoading(true);
      }

      try {
        let res = await getBlogPost(user.token, id, abortController.signal);
        if (!abortController.signal.aborted) {
          setPost(res);
        }
      } catch (e) {
        if (e === 401) {
          history.push("/login");
        }
        if (!abortController.signal.aborted) {
          setError(true);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    };

    const loadMedia = () => {
      document.querySelectorAll(".media").forEach((element) => {
        ReactDOM.render(
          <YouTube
            videoId={YouTubeGetID(
              element?.childNodes[0]?.attributes?.url?.value
            )}
            className="blog-post-video"
          />,
          ReactDOM.findDOMNode(element)
        );
        element.childNodes[0].classList.add("w-100");
        element.childNodes[0].classList.add("text-align-center");
        element.childNodes[0].classList.add("blogpost__media--padding");
      });

      document.querySelectorAll(".image").forEach((element) => {
        element.classList.add("text-align-center");
      });
    };

    loadBlogPost(id).then(loadMedia);
    return () => abortController.abort();
  }, [refresh, id]);

  const deletePost = async () => {
    try {
      await removePost(post.id, user.token);
      alert.current.showAlert("Pomyślnie usunięto post", "success");
      setRedirect(true);
    } catch (e) {
      alert.current.showAlert("Wystąpił błąd podczas usuwania posta", "danger");
    }
  };

  return (
    <Container className="font-only blogs-background">
      {loading ? (
        <div className="blogs-loading">ŁADOWANIE</div>
      ) : error ? (
        <div className="blogs-loading">WYSTĄPIŁ BŁĄD</div>
      ) : (
        <>
          {post.header !== null ? (
            <div className="blogpost-border">
              {post.header.includes("youtube.com/watch?") ||
              post.header.includes("https://youtu.be/") ? (
                <YouTube
                  videoId={YouTubeGetID(post.header)}
                  className="blog-post-media"
                />
              ) : (
                <img
                  src={post.header}
                  alt="blogpost-header"
                  className="blog-post-media"
                />
              )}
            </div>
          ) : null}
          <div className="">
            <div className="d-flex justify-content-center blogpost__title">
              {post.title}
            </div>
            <div className="d-flex justify-content-center w-100">
              <div
                dangerouslySetInnerHTML={{ __html: post.content }}
                className="blogpost__description"
              />
            </div>
            <BlogComments
              comments={post.comments}
              user={user}
              postId={post.id}
              refresh={refresh}
              setRefresh={setRefresh}
            />
            <Row className="d-flex justify-content-center mt-3">
              <EditPostButton
                user={user}
                category={category}
                postId={post.id}
              />
              <DeletePostButton
                user={user}
                setDeletePostAlert={setDeletePostAlert}
              />
            </Row>
            <DeletionModal
              show={deletePostAlert}
              setShow={setDeletePostAlert}
              question={"Czy na pewno chcesz usunąć ten post?"}
              cancelLabel={"Nie, zostaw"}
              confirmLabel={"Tak, usuń"}
              delConfirmed={deletePost}
            />
          </div>
        </>
      )}
      {redirect ? <Redirect to={"/blogs"} /> : null}
    </Container>
  );
};

export default BlogPost;
