import * as React from "react";
import { Container, Card, Button, Form } from "react-bootstrap";
import { FormGroup, Alert } from "components";
import { resendEmail } from "./ResendEmailVerification.api";
import snakecaseKeys from "snakecase-keys";
import { useUserContext } from "context";
import { useMutation } from "hooks";

const ResendEmailVerification = () => {
  const [facilityEmail, setFacilityEmail] = React.useState("");

  const {
    data: { email: userEmail },
    token,
  } = useUserContext();

  const { loading, onSubmit, validated, isInvalid } = useMutation(
    () => resendEmail(snakecaseKeys({ facilityEmail, userEmail }), token),
    "Pomyślnie przesłano nowy mail placówki/opiekuna.",
    { checkIfInValid: () => facilityEmail === userEmail }
  );

  return (
    <Container>
      <Card>
        <Card.Header>
          <h4>Prześlij ponownie email do placówki/opiekuna</h4>
        </Card.Header>
        <Card.Body
          as={Form}
          noValidate
          validated={validated}
          onSubmit={onSubmit}
        >
          <FormGroup
            val={facilityEmail}
            setVal={setFacilityEmail}
            header="Email placówki/opiekuna"
            id="facilityEmail"
            required
            isInvalid={isInvalid}
            type="email"
            incorrect="Adres email jest wymagany i musi się różnić od adresu email
            użytkownika."
          />
          <Alert loading={loading} />
          <Button type="submit" data-testid="submit">
            Prześlij
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ResendEmailVerification;
