import { useState, useContext, useRef } from "react";
import { AlertContext } from "context";

const useMutation = (mutateFn, successMessage, options) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const alertC = useRef(useContext(AlertContext));
  const [validated, setValidated] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const handleSubmit = async (...params) => mutateFn(...params);

  const onSubmit = (e, params) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (options?.checkIfInValid) {
      if (options.checkIfInValid()) {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
        if (form.checkValidity() === true) {
          mutate(params?.message, params);
        }
      }
    } else {
      if (form.checkValidity() === true) {
        mutate(params?.message, params);
      }
      setIsInvalid(false);
    }
    setValidated(true);
  };

  const mutate = async (message, params) => {
    setLoading(true);
    try {
      if (params) {
        delete params.message;
        await handleSubmit(params);
      } else {
        await handleSubmit();
      }

      if (message || successMessage) {
        alertC.current.showAlert(message || successMessage, "success");
      }
      setLoading(false);
      if (options?.redirectFn) {
        options.redirectFn();
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
      if (!options?.notShowErrorAlert) {
        alertC.current.showAlert(err.message);
      }
    }
  };

  return { loading, error, mutate, onSubmit, validated, isInvalid };
};

export default useMutation;
