import React, { useState, useEffect, useRef, useContext } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { LabelForm } from "../";
import { DeletionModal } from "components";
import { deleteLabel } from "Views/Calendar/Calendar.api";
import { AlertContext, UserContext } from "context";

const Label = ({ handleRefresh, label }) => {
  const [showForm, setShowForm] = useState(false);
  const { id, name, color } = label;

  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));

  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleDelete = async () => {
      try {
        await deleteLabel(user.token, id);
        alertC.current.showAlert("Poprawnie usunięto etykietę.", "success");
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDeletionConfirmed(false);
      handleRefresh();
    };

    if (deletionConfirmed) handleDelete();
  }, [deletionConfirmed, handleRefresh, id, user.token]);

  return (
    <>
      <div key={id} className="calendarView__label">
        <ButtonGroup>
          <Button className="mr-2" onClick={() => setShowForm(true)}>
            Edytuj
          </Button>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            X
          </Button>
        </ButtonGroup>
        <div
          className="calendarView__labelList__item"
          style={{ backgroundColor: color }}
        >
          {name}
        </div>
      </div>
      <LabelForm
        show={showForm}
        handleClose={() => setShowForm(false)}
        handleRefresh={handleRefresh}
        label={label}
      />
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        question={"Czy na pewno chcesz usunąć tę etykietę?"}
        cancelLabel={"Nie, zostaw"}
        confirmLabel={"Tak, usuń"}
        delConfirmed={setDeletionConfirmed}
      />
    </>
  );
};

export default Label;
