import proxy from "config/api";

export const sendCategory = async (token, data, id) => {
  const url = id ? `${proxy.blog}category/${id}/` : `${proxy.blog}category/`;
  const headers = {
    Authorization: `Token ${token}`,
  };

  const { header_mobile, header_pc, name, description, layout, show_title } =
    data;

  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("layout", layout);
  formData.append("show_title", show_title);
  formData.append("public", data?.public);

  if (header_pc?.fetched) {
  } else if (header_pc) {
    formData.append("header_pc", header_pc, header_pc?.name);
  } else {
    formData.append("header_pc", "");
  }

  if (header_mobile?.fetched) {
  } else if (header_mobile) {
    formData.append("header_mobile", header_mobile, header_mobile?.name);
  } else {
    formData.append("header_mobile", "");
  }

  const response = await fetch(url, {
    method: id ? "PUT" : "POST",
    headers,
    body: formData,
  });

  if (response.status === 200 && data) {
    return response.status;
  } else if (response.status === 201) {
    return response.status;
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const sendTopic = async (token, data, id) => {
  const url = id ? `${proxy.blog}topic/${id}/` : `${proxy.blog}topic/`;
  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: id ? "PUT" : "POST",
    headers,
    body: JSON.stringify(data),
  });

  if (response.status === 200 && id) {
    return response.json();
  } else if (response.status === 201) {
    return response.json();
  }

  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const moveTopic = async (token, id, dir) => {
  const response = await fetch(`${proxy.blog}topic/${id}/${dir}/`, {
    method: "PATCH",
    headers: { Authorization: "Token " + token },
  });

  if (response.status === 204) {
    return response.status;
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const deleteType = async (token, id, type) => {
  const response = await fetch(`${proxy.blog}${type}/${id}/`, {
    method: "DELETE",
    headers: { Authorization: "Token " + token },
  });

  if (response.status === 204) {
    return response.status;
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const getChatUsers = async (token, signal) => {
  const response = await fetch(
    `${proxy.account}admin/user_list/staff/?group_type=staff_chat_access`,
    {
      method: "GET",
      signal,
      headers: { Authorization: "Token " + token },
    }
  );

  if (response.status === 200) {
    return response.json();
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const getExperts = async (token, signal) => {
  const response = await fetch(`${proxy.experts}`, {
    signal,
    method: "GET",
    headers: { Authorization: "Token " + token },
  });

  if (response.status === 200) {
    return response.json();
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const deleteExpert = async (token, id) => {
  const response = await fetch(`${proxy.experts}${id}/`, {
    method: "DELETE",
    headers: { Authorization: "Token " + token },
  });

  if (response.status === 204) {
    return response.status;
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const sendExpert = async (token, data, id) => {
  const response = await fetch(`${proxy.experts}${id ? `${id}/` : "new/"}`, {
    method: id ? "PATCH" : "POST",
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!id && response.status === 201) {
    return response.status;
  } else if (response.status === 200) {
    return response.status;
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const getLabels = async (token, signal) => {
  const response = await fetch(`${proxy.workshops}labels/`, {
    method: "GET",
    signal,
    headers: { Authorization: "Token " + token },
  });

  if (response.status === 200) {
    return response.json();
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const deleteLabel = async (token, id) => {
  const response = await fetch(`${proxy.workshops}labels/${id}/`, {
    method: "DELETE",
    headers: { Authorization: "Token " + token },
  });

  if (response.status === 204) {
    return response.status;
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};

export const sendLabel = async (token, data, id) => {
  const response = await fetch(
    `${proxy.workshops}${id ? `labels/${id}/` : "new-label/"}`,
    {
      method: id ? "PATCH" : "POST",
      headers: {
        Authorization: "Token " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  if (!id && response.status === 201) {
    return response.status;
  } else if (response.status === 200) {
    return response.status;
  }
  const messages = await response.json().then((description) => description);
  let err = "";
  Object.keys(messages).forEach((key) => (err += `${key}: ${messages[key]} `));
  throw new Error(err);
};
