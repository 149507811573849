import React, { useState, useRef, useContext, useEffect } from "react";
import { Card, Form, Button, Accordion, Alert } from "react-bootstrap";
import { FormGroup } from "components";
import { AlertContext } from "context";
import { sendExpert, getChatUsers } from "../../functions";

const ExpertCreator = ({ token, data, refresh }) => {
  const [users, setUsers] = useState([]);

  const [id, setId] = useState("");

  const [personalData, setPersonalData] = useState({});
  const [presentation, setPresentation] = useState("");
  const [video, setVideo] = useState("");

  const alertC = useRef(useContext(AlertContext));

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchUsers = async () => {
      if (!abortController.signal.aborted) {
        setLoading(true);
      }
      try {
        const res = await getChatUsers(token, abortController.signal);
        setUsers(res.results);
        if (!data) {
          setPersonalData(res.results[0]);
        }
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(true);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    };
    fetchUsers();
    if (data && !abortController.signal.aborted) {
      const { first_name, last_name, role, id, presentation, video } = data;
      setPresentation(presentation || "");
      setVideo(video || "");
      setId(id);
      setPersonalData({ first_name, last_name, role });
    }
    return () => abortController.abort();
  }, [data, token]);

  const handleSubmit = async (e) => {
    setDisable(true);
    e.preventDefault();
    let objectToSend = {};
    if (personalData.id) {
      objectToSend.staff_account = personalData.id;
    }

    if (video) {
      if (
        video.includes("https://www.youtube.com/") ||
        video.includes("https://youtu.be/")
      ) {
        objectToSend.video = video;
      } else {
        alertC.current.showAlert(
          "Niepoprawny link do filmu( powinien być z youtube )"
        );
        setDisable(false);
        return;
      }
    }
    if (presentation) objectToSend.presentation = presentation;
    try {
      await sendExpert(token, objectToSend, id);
      refresh((prevState) => !prevState);
      alertC.current.showAlert(
        data ? "Pomyślnie edytowano eksperta" : "Pomyślnie dodano eksperta",
        "success"
      );
    } catch (err) {
      alertC.current.showAlert(err.message);
    }
    setDisable(false);
  };

  const message = error ? (
    <Alert variant="danger">Błąd ładowania użytkowników</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie</Alert>
  ) : null;

  return (
    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h4>{data ? "Edytuj eksperta" : "Dodaj eksperta"}</h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body as={Form} onSubmit={handleSubmit}>
            {!data && (
              <Form.Group controlId="user_select">
                <Form.Label>Wybierz eksperta</Form.Label>
                <Form.Control
                  as="select"
                  value={personalData.id}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newUser = users.find((user) => user.id === value);
                    setPersonalData(newUser);
                  }}
                  required
                >
                  {users?.map(({ first_name, last_name, role, id }) => (
                    <option key={id} value={id}>{`${first_name} ${last_name}${
                      role ? ` - ${role}` : ""
                    }`}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
            {message}
            <FormGroup
              type="textarea"
              header="Opis eksperta"
              val={presentation}
              setVal={setPresentation}
              length={{ min: 1 }}
              id="category_desc"
            />

            <FormGroup
              header="Film eksperta"
              val={video}
              setVal={setVideo}
              length={{ min: 1 }}
              id="category_desc"
            />

            <Button disabled={disable} type="submit">
              {disable ? "Trwa dodawanie..." : "Prześlij"}
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ExpertCreator;
