import React from "react";
import { Button } from "react-bootstrap";

const DeleteWorkshopPostButton = ({ setDeletePostAlert }) => (
  <Button
    className="ml-1"
    variant="danger"
    onClick={() => setDeletePostAlert(true)}
  >
    <span className="align-self-center">USUŃ WARSZTAT</span>
  </Button>
);

export default DeleteWorkshopPostButton;
