import React, { useContext } from "react";
import { UserContext } from "context";
import { EditChoiceButton, DeleteChoiceButton } from "./components";
import PropTypes from "prop-types";

import { staffTypes } from "constants/staffTypes";
import { userTypes } from "constants/userTypes";

const Choice = ({ choice, refresh, updatePath, handleBack }) => {
  const user = useContext(UserContext);

  return (
    <>
      <div
        className="steps-choice-row d-flex flex-column align-items-center"
        onClick={() => handleBack(choice.id)}
      >
        <div className="steps-choice-div">{choice?.title}</div>
        {user.type === userTypes.STAFF &&
          user.data.group_type.includes(staffTypes.BLOG_MODERATOR) && (
            <div className="mb-3">
              <EditChoiceButton
                token={user.token}
                refresh={refresh}
                choice={choice}
              />
              <DeleteChoiceButton
                token={user.token}
                refresh={updatePath}
                choice={choice}
              />
            </div>
          )}
      </div>
    </>
  );
};

Choice.propTypes = {
  choice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,
      })
    ),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        description: PropTypes.string,
        attachment_url: PropTypes.string,
      })
    ),
    parents: PropTypes.arrayOf(PropTypes.number),
    title: PropTypes.string.isRequired,
  }),
  updatePath: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default Choice;
