import React, { useState, useRef, useContext } from "react";
import { Card, Form, Button, Accordion } from "react-bootstrap";
import { FormGroup } from "components";
import { AlertContext } from "context";
import { sendCategory } from "../../functions";
import CategoryPhoto from "../CategoryPhoto";

const CategoryCreator = ({ refresh, token, data }) => {
  const alertContext = useRef(useContext(AlertContext));
  const [name, setName] = useState(data?.name || "");
  const [description, setDescription] = useState(data?.description || "");
  const [show_title, setShowTitle] = useState(data ? data?.show_title : true);
  const [publicCat, setPublicCat] = useState(data ? data?.public : true);
  const [header_mobile, setHeaderMobile] = useState(
    data?.header_mobile_url
      ? { name: data?.header_mobile_url, fetched: true }
      : null
  );
  const [header_pc, setHeaderPc] = useState(
    data?.header_pc_url ? { name: data?.header_pc_url, fetched: true } : null
  );
  const [disable, setDisable] = useState(null);

  const handleDelete = (type) => {
    if (type === "mobile") {
      setHeaderMobile(null);
    } else {
      setHeaderPc(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setDisable(true);
      try {
        await sendCategory(
          token,
          {
            name,
            description,
            header_pc,
            header_mobile,
            show_title,
            public: publicCat,
          },
          data?.id
        );

        alertContext.current.showAlert(
          data ? "Pomyślnie edytowano kategorię" : "Pomyślnie dodano kategorię",
          "success"
        );
        refresh((prevState) => !prevState);
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
      setDisable(false);
    }
  };

  return (
    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h4>{data ? "Edytuj dane kategorii" : "Stwórz nową kategorię"}</h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body as={Form} onSubmit={handleSubmit}>
            <FormGroup
              required
              val={name}
              setVal={setName}
              length={{ min: 1, max: 60 }}
              header="Nazwa kategorii*"
              id="category_name"
            />
            <FormGroup
              type="textarea"
              val={description}
              setVal={setDescription}
              header="Opis"
              length={{ min: 1 }}
              id="category_desc"
            />
            <Form.Group controlId="formTitle">
              <Form.Check
                type="checkbox"
                onChange={() => setShowTitle((prevState) => !prevState)}
                checked={show_title}
                label="Pokaż tytuł"
              />
            </Form.Group>
            <Form.Group controlId="formPublic">
              <Form.Check
                type="checkbox"
                onChange={() => setPublicCat((prevState) => !prevState)}
                checked={publicCat}
                label="Publiczna"
              />
            </Form.Group>
            <CategoryPhoto
              photo={header_mobile}
              setPhoto={setHeaderMobile}
              isPhoto={data?.header_pc_mobile !== null}
              placeholder={"Nagłówek kategorii na urządzeniach mobilnych(16:9)"}
            />
            {data?.header_mobile_url ? (
              <Button
                onClick={() => handleDelete("mobile")}
                variant="danger"
                className=" mb-3"
              >
                Usuń zdjęcie (mobilne)
              </Button>
            ) : null}
            <CategoryPhoto
              token={token}
              photo={header_pc}
              setPhoto={setHeaderPc}
              placeholder={
                "Nagłówek kategorii na urządzeniach stacjonarnych(21:9)"
              }
            />
            {data?.header_pc_url ? (
              <Button
                onClick={() => handleDelete("pc")}
                className="d-block mb-3"
                variant="danger"
              >
                Usuń zdjęcie (stacjonarne)
              </Button>
            ) : null}
            <Button disabled={disable} type="submit">
              {disable
                ? "Trwa dodawanie..."
                : data
                ? "Edytuj kategorię"
                : "Dodaj kategorię"}
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default CategoryCreator;
