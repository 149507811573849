import React from "react";
import { Button } from "react-bootstrap";

const BlogCategory = ({ blog }) => {
  return (
    <div className="blogs-category">
      <div>
        <div className="blogs-title">{blog.name}</div>
        <div className="blogs-description">{blog.description}</div>
      </div>
      <div className="justify-content-center d-flex">
        <Button
          href={"/blogs/" + blog.id}
          className="blogs-category-button float-right"
        >
          <span className="blog-category-button-span">PRZECZYTAJ</span>
        </Button>
      </div>
    </div>
  );
};

export default BlogCategory;
