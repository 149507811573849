import React, { useEffect, useState, useContext, useRef } from "react";
import { getCategories, getTypes } from "Views/OfferForm/functions/fetchData";
import { deleteEnum } from "Views/JobOffersEnumsCreator/functions";
import { Accordion, Modal } from "react-bootstrap";
import { MappedEnums, EnumForm } from "../";
import { DeletionModal } from "components";
import { UserContext, AlertContext } from "context";
import PropTypes from "prop-types";

const ListOfEnums = ({ refresh, refreshFunc }) => {
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [edited, setEdited] = useState(null);
  const [type, setType] = useState("");

  const [loadingCat, setLoadingCat] = useState(false);
  const [errorCat, setErrorCat] = useState(false);
  const [loadingType, setLoadingType] = useState(false);
  const [errorType, setErrorType] = useState(false);

  const user = useContext(UserContext);
  const alertContext = useRef(useContext(AlertContext));

  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [delObject, setDelObject] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = (enumObject, type) => {
    setEdited(enumObject);
    setType(type);
    handleShow();
  };

  const handleDelete = (name, type) => {
    const question =
      type === "category"
        ? "Usunięcie kategorii spowoduje usunięcie wszystkich postów które ją posiadają czy napewno chcesz to zrobić?"
        : "Usunięcie rodzaju umowy spowoduje usunięcie wszystkich postów które go posiadają czy napewno chcesz to zrobić?";
    setShowModal(true);
    setDelObject({ name, question, type });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const fetchTypes = async () => {
      if (!abortController.signal.aborted) setLoadingCat(true);
      try {
        const res = await getCategories(abortController.signal);
        setCategories(res);
      } catch (err) {
        if (!abortController.signal.aborted) setErrorCat(true);
      }
      if (!abortController.signal.aborted) setLoadingCat(false);
    };
    fetchTypes();
    return () => abortController.abort();
  }, [refresh]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchCategories = async () => {
      if (!abortController.signal.aborted) setLoadingType(true);
      try {
        const res = await getTypes(abortController.signal);
        setTypes(res);
      } catch (err) {
        if (!abortController.signal.aborted) setErrorType(true);
      }
      if (!abortController.signal.aborted) setLoadingType(false);
    };
    fetchCategories();
    return () => abortController.abort();
  }, [refresh]);

  useEffect(() => {
    const handleDelete = async (id) => {
      try {
        const res = await deleteEnum(user.token, delObject.type, id);
        alertContext.current.showAlert(res.message, "success");
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
      setDeletionConfirmed(false);
      refreshFunc();
    };

    if (deletionConfirmed) handleDelete(delObject.name);
  }, [
    delObject.name,
    delObject.type,
    deletionConfirmed,
    refreshFunc,
    user.token,
  ]);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>
          <EnumForm type={type} enumName={edited} refresh={refreshFunc} />
        </Modal.Body>
      </Modal>
      <Accordion>
        <DeletionModal
          show={showModal}
          setShow={setShowModal}
          delConfirmed={setDeletionConfirmed}
          question={delObject.question}
        />
        <MappedEnums
          array={categories}
          eventKey="0"
          type="category"
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          error={errorCat}
          loading={loadingCat}
        />
        <MappedEnums
          array={types}
          eventKey="1"
          type="type"
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          error={errorType}
          loading={loadingType}
        />
      </Accordion>
    </div>
  );
};

ListOfEnums.propTypes = {
  refreshFunc: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
};

export default ListOfEnums;
