import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import back from "assets/errorBack.svg";

const ErrorPage = () => {
  return (
    <div className="errorPage__container ">
      <div className="errorPage__content">
        <h4 className="errorPage__header">
          Ups, wygląda na to, że nic tu nie ma :(
        </h4>
        <Link to="/" className="errorPage__back">
          <ReactSVG src={back} />
          Wróć do strony głównej
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
