import React from "react";
import proxy from "config/api";
import { ReactSVG } from "react-svg";

const ShowShapes = ({ shapes, backgroundUrl, setBackgroundUrl }) => {
  return (
    <div className="shapesContainer">
      {Object.keys(shapes).map((key) => (
        <div
          key={key}
          className={`${
            backgroundUrl === parseInt(key) ? "shapesContainer__picked" : ""
          }`}
          onClick={() => setBackgroundUrl(parseInt(key))}
        >
          <ReactSVG
            beforeInjection={(svg) => {
              svg.querySelector("path").setAttribute("fill", "#6699CC");
            }}
            src={`${proxy.plainFiles}${shapes[key]}`}
          />
        </div>
      ))}
    </div>
  );
};

export default ShowShapes;
