import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useMutation } from "hooks";
import { Alert } from "components";
import { acceptTerms } from "./TermsAccepted.api";

const TermsAccepted = () => {
  const { id } = useParams();

  const { mutate, loading, error } = useMutation(
    () => acceptTerms(id),
    "Pomyślnie przesłano nowy mail placówki/opiekuna.",
    { notShowErrorAlert: true }
  );

  useEffect(() => {
    mutate();
  }, []);

  return (
    <Container className="d-flex align-items-center flex-column justify-content-center height-100">
      {!error || loading ? (
        <Alert loading={loading} error={error} />
      ) : (
        <>
          <h1 data-testid="success">
            Dziękuje za zaakceptowanie regulaminu strony. Konto zostało
            odblokowane.
          </h1>
          <Link to="/user">Powrót do strony użytkownika.</Link>
        </>
      )}
    </Container>
  );
};

export default TermsAccepted;
