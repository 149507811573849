import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { staffTypes } from "constants/staffTypes";
import { userTypes } from "constants/userTypes";

const NewPostButton = ({ user }) =>
  user.type === userTypes.STAFF &&
  (user.data.group_type.includes(staffTypes.BLOG_CREATOR) ||
    user.data.group_type.includes(staffTypes.GUEST)) ? (
    <LinkContainer to="/blog/newPost">
      <Button variant="primary">Stwórz nowy post</Button>
    </LinkContainer>
  ) : null;

export default NewPostButton;
