import { DetailsItem } from "components";
import proxy from "config/api";
import { errorMessage } from "constants/other";
import { staffTypes } from "constants/staffTypes";
import { userStatuses } from "constants/userStatuses";
import { userTypes } from "constants/userTypes";
import { AlertContext, UserContext } from "context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, Card, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { addressToString } from "utils/converters";
import { AddCvForm, ButtonsStaff } from "./_components";

const getOfferDetails = async (id, signal) => {
  let url = `${proxy.job}job-offer/${id}`;
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers, signal });

  if (response.status === 200) {
    return response.json().then((offer) => mapOffer(offer));
  } else {
    throw new Error(await errorMessage(response));
  }
};

const mapOffer = (offer) => {
  let companyAddress;
  if (
    offer.company_address.postal_code &&
    offer.company_address.street &&
    offer.company_address.street_number
  ) {
    companyAddress = `ul. ${offer.company_address.street} ${offer.company_address.street_number}, ${offer.company_address.postal_code} ${offer.company_address.city}`;
  } else {
    companyAddress = `${offer.company_address.city}`;
  }
  return {
    id: offer.id,
    title: offer.offer_name,
    category: offer.category,
    type: offer.type,
    companyName: offer.company_name,
    companyAddress,
    voivodeship: offer.voivodeship,
    expirationDate: offer.expiration_date,
    description: offer.description,
    pay_from: offer.salary_min,
    pay_to: offer.salary_max,
    companyLogo: offer.company_logo,
    offerImage: offer.offer_image,
    applicationsUrl: offer.applications_url,
    applicable: offer.applicable,
  };
};

const JobOfferDetails = (props) => {
  const [offer, setOffer] = useState({});
  const [isOfferLoading, setIsOfferLoading] = useState(false);
  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));
  useEffect(() => {
    const abortController = new AbortController();
    const loadOffer = async (id) => {
      if (!abortController.signal.aborted) setIsOfferLoading(true);
      let loadedOffer;
      try {
        loadedOffer = await getOfferDetails(id, abortController.signal);
      } catch (e) {
        if (!abortController.signal.aborted) {
          loadedOffer = {};
          alertC.current.showAlert("Wystąpił błąd podczas ładowania oferty.");
        }
      }
      if (!abortController.signal.aborted) {
        setOffer(loadedOffer);
        setIsOfferLoading(false);
      }
    };
    loadOffer(props.match.params.id);
    return () => abortController.abort();
  }, [props.match.params.id]);

  const msg = isOfferLoading && (
    <Alert variant="info">Ładowanie oferty...</Alert>
  );

  return (
    <Container className="jobOfferDetails">
      <Card>
        <Card.Header as="h2">Szczegóły oferty pracy</Card.Header>
        <Card.Body>
          {msg || (
            <div>
              {offer.offerImage ? (
                <Row className="m-0 justify-content-center">
                  <img
                    className="JobOffer__mainImage mb-3"
                    src={`${proxy.plainFiles}${offer.offerImage}`}
                    alt="obrazek główny oferty"
                  />
                </Row>
              ) : null}
              {offer.companyLogo ? (
                <Row className="m-0 mb-2 align-items-center justify-content-center justify-content-md-start">
                  <img
                    className="JobOffer__logo mb-3 mb-md-0 mr-3 mr-md-3"
                    src={`${proxy.plainFiles}${offer.companyLogo}`}
                    alt="logo firmy"
                  />
                  <h3>{offer.title}</h3>
                </Row>
              ) : (
                <h3>{offer.title}</h3>
              )}
              <Row>
                <DetailsItem md="6" xl="4" label="Nazwa firmy">
                  {offer.companyName}
                </DetailsItem>
                <DetailsItem md="6" xl="4" label="Adres firmy">
                  {addressToString(offer.companyAddress)}
                </DetailsItem>
                <DetailsItem md="6" xl="4" label="Lokalizacja">
                  {offer.voivodeship}
                </DetailsItem>
                <DetailsItem md="6" xl="4" label="Ważna do">
                  {new Date(offer.expirationDate).toLocaleDateString(
                    undefined,
                    {}
                  )}
                </DetailsItem>
                <DetailsItem md="6" xl="4" label="Kategoria">
                  {offer.category}
                </DetailsItem>
                <DetailsItem md="6" xl="4" label="Rodzaj umowy">
                  {offer.type}
                </DetailsItem>
                <DetailsItem md="6" xl="4" label="Wynagrodzenie">
                  {offer.pay_from} zł - {offer.pay_to} zł
                </DetailsItem>
              </Row>
              <div className="jobOfferDetails__description">
                {offer.description}
              </div>
            </div>
          )}
          {user.type === userTypes.STANDARD &&
            !offer.applicationsUrl &&
            user.data?.status === userStatuses.VERIFIED && (
              <AddCvForm id={props.match.params.id} user={user} />
            )}
          {offer.applicationsUrl && (
            <div className="w-100 d-flex justify-content-center">
              <a
                class="btn btn-primary"
                role="button"
                target="_blank"
                rel="noopener noreferrer"
                href={offer.applicationsUrl}
              >
                Aplikuj teraz
              </a>
            </div>
          )}
          {user.type === userTypes.STAFF &&
            user.data?.group_type.includes(staffTypes.JOBS) && (
              <ButtonsStaff id={props.match.params.id} user={user} />
            )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default withRouter(JobOfferDetails);
