import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ChoiceForm } from "Views/Steps/components";
import PropTypes from "prop-types";

const EditChoiceButton = ({ choice, token, refresh }) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <>
      <ChoiceForm
        show={showEdit}
        previous={choice?.id}
        choice={choice}
        token={token}
        refresh={refresh}
        handleHide={() => setShowEdit(false)}
      />
      <Button
        className="steps-choice-edit-button"
        onClick={() => setShowEdit(true)}
      >
        Edytuj pytanie
      </Button>
    </>
  );
};

EditChoiceButton.propTypes = {
  choice: PropTypes.shape({
    id: PropTypes.number,
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,
      })
    ),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        description: PropTypes.string,
        attachment_url: PropTypes.string,
      })
    ),
    previous: PropTypes.number,
    title: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
  }),
  token: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default EditChoiceButton;
