import React from "react";
import { Container } from "react-bootstrap";
import header21 from "assets/aboutProject/o-projekcie-napis_2.jpg";
import header16 from "assets/aboutProject/o-projekcie-napis_1.jpg";
import groupPhoto from "assets/aboutProject/groupPhoto.jpg";
import { useWindowSize } from "hooks";

const AboutProject = () => {
  const [width] = useWindowSize();

  return (
    <Container className="aboutProject__container">
      <img
        src={width >= 768 ? header21 : header16}
        className="aboutProject__headerPhoto"
        alt="Zdjęcie grupowe fundacji One Day i studentów i pracowników Politechniki Warszawskiej."
      />
      <h4 className="pb-4 px-3 mb-0 text-justify d-flex flex-column align-items-center aboutProject__header">
        <span>Za górami za lasami...</span>{" "}
        <span>trochę jak w bajce, bo zaczęło się od marzeń...</span>
      </h4>
      <div className="aboutProject__section px-0">
        <section className="aboutProject__section">
          <p>
            Jako Fundacja <span className="font-weight-bold">ONE DAY</span>{" "}
            poznaliśmy i poznajemy setki młodych osób z Domów Dziecka, które
            proszą nas o wsparcie. Usamodzielnianie się jest dla nich niezwykle
            trudne. Wychowankowie nie wiedzą jakie mają prawa i jak proces ten
            przejść bez stresu i lęku. Jesteśmy po to by dać im narzędzia,
            pokazać jak można poradzić sobie w różnych okolicznościach, podjąć
            pracę i stać się pewną siebie, kochającą osobą, dumną ze swojej
            dorosłości i z samego siebie.
          </p>
        </section>
        <section className="aboutProject__section">
          <p>
            Według danych Głównego Urzędu Statystycznego w 2018 roku w Polsce
            działało 1152 placówek instytucjonalnej pieczy zastępczej, do
            których zaliczyć można placówki opiekuńczo-wychowawcze, regionalne
            placówki opiekuńczo-terapeutyczne oraz interwencyjne ośrodki
            preadopcyjne. Przebywało w nich wówczas 16655 osób, wśród których
            najliczniejszą grupę stanowili wychowankowie w wieku 14–17 lat (7467
            osób).
          </p>
        </section>
        <section className="aboutProject__section">
          <p>
            Podopieczni Domów Dziecka, którzy osiągają pełnoletność, stają przed
            koniecznością usamodzielnienia. Wielu napotyka wówczas na bariery w
            wejściu w dorosłe życie, przekładające się na utrudniony dostęp do
            edukacji czy rynku pracy.
          </p>
        </section>
        <section className="aboutProject__section w-100">
          <p>
            <span className="font-weight-bold">
              CO ROKU USAMODZIELNIONYCH JEST OKOŁO 6 TYSIĘCY!
            </span>
          </p>
        </section>
        <section className="aboutProject__section w-100">
          <p>
            To właśnie dla tej grupy chcieliśmy stworzyć dedykowane narzędzie,
            które stanie się realnym wsparciem dla wchodzących w dorosłość.
          </p>
        </section>
        <section className="aboutProject__section w-100">
          <p>
            Nasz cykl szkoleniowy dociera rocznie do ponad 200 osób. Ale to
            wciąż za mało! Na szczęście istnieją osoby, które tak jak my, nie
            widzą przeszkód w tworzeniu tego czego jeszcze nie ma.
          </p>
        </section>
        <section className="aboutProject__section">
          <p>
            Pierwszą taką osobą, która poznała nasze marzenia, była Paulina Król
            z Politechniki Warszawskiej oraz prorektor ds. studenckich Janusz
            Walo. Podczas rozmów na temat corocznego finału akcji One Day w Auli
            Głównej Politechniki Warszawskiej, pojawił się pomysł wspólnej pracy
            nad projektem dla młodzieży. Młodzież dla młodzieży! I tak się
            stało! Za zgodą prorektora ds. studenckich i dzięki skutecznym
            działaniom Pauliny, znaleźliśmy pierwszego „śmiałka”! Łukasz
            Żmudzin, nasz lider projektu, stworzył całą strukturę aplikacji. W
            finalnej wersji stała się przedmiotem do zaliczenia na uczelni.
          </p>
        </section>
        <section className="aboutProject__section w-100">
          <p>Studenci tworzyli aplikację / stronę USAMODZIELNIENI.PL</p>
        </section>
        <section className="aboutProject__section">
          <p>
            W trakcie prac zwiększały się nasze potrzeby, pojawiało się coraz
            więcej pomysłów na zakładki – projekt stawał się coraz
            atrakcyjniejszy, ale i bardziej skomplikowany technologicznie. W tym
            samym czasie Dominika i Sebastian Kulczyk zaangażowali się w
            finansowanie projektu
            <span className="font-weight-bold"> USAMODZIELNIENI</span>. Pomysł
            bardzo im się spodobał a my dzięki ich wsparciu mogliśmy kontynuować
            pracę, zatrudniać prawników, księgowych, psychologów, stworzyć
            grafikę i planować dalszy rozwój.
          </p>
        </section>
        <section className="aboutProject__section">
          <p>
            Gdy przedmiot się skończył a studentom na Politechnice wystawiono
            oceny, zorganizowaliśmy dziękczynne spotkanie w pracowni Efektu
            Domina <span className="font-weight-bold">KULCZYK FOUNDATION</span>.
            W ramach podziękowania Mateusz Gessler nauczył studentów gotować...
            eskalopki :)
          </p>
        </section>
        <div className="aboutProject__wrapper">
          <img
            src={groupPhoto}
            className="aboutProject__contentImg"
            alt="zdjęcie grupowe z Mateuszem Gesslerem"
          />
          <div className="aboutProject__colWrapper">
            <section className="aboutProject__section">
              Przedstawiamy pełen skład zespołu, który pracował nad aplikacją w
              ramach projektu zespołowego na uczelni.
              <ul className="aboutProject__list list-unstyled">
                <li>Michał Balas</li>
                <li>Maciej Bany</li>
                <li>Mikołaj Czarkowski</li>
                <li>Jan Dobrowolski</li>
                <li>Michał Gajda</li>
                <li>Damian Kacperski</li>
                <li>Dmitry Kaliada</li>
                <li>Michał Kaniewski</li>
                <li>Adrian Mostowski</li>
                <li>Monika Osiak</li>
                <li>Jan Polaczek</li>
                <li>Jakub Świder</li>
              </ul>
              <p>Kierownik projektu: mgr inż. Łukasz Żmudzin </p>
              <p>Konsultant ds. bezpieczeństwa: dr inż. Bartosz Chaber</p>
            </section>
          </div>
        </div>
        <section className="aboutProject__section w-100">
          <p>
            Grupa śmiałków pod kierownictwem Moniki Osiak została z nami, by
            kontynuować prace nad projektem{" "}
            <span className="font-weight-bold">USAMODZIELNIENI.PL</span>
          </p>
        </section>
        <section className="aboutProject__section w-100">
          <p>
            Udało się! Po pokonaniu długiej i krętej drogi, zaczynając od marzeń
            i planów, a kończąc na funkcjonalnej aplikacji, zainaugurowaliśmy
            działalność platformy{" "}
            <span className="font-weight-bold">USAMODZIELNIENI!</span>{" "}
          </p>
        </section>
        <section className="aboutProject__section">
          <p>
            <span className="font-weight-bold">USAMODZIELNIENI.PL</span> to
            kompendium niezbędnej wiedzy dla osób, które stawiają pierwsze kroki
            w dorosłym życiu. W prosty sposób nauczy, a potem sprawdzi, czy
            udało się napisać poprawne i efektywne CV, za pomocą którego
            będziecie mogli aplikować na zweryfikowane przez nas oferty pracy.
            Pomoże też rozwinąć kluczowe kompetencje tak, żeby rozmowa
            kwalifikacyjna nie była zbyt dużym stresem. Platforma pokaże również
            kroki i ich konsekwencje oraz prawa i obowiązki w usamodzielnianiu
            tak, abyście mogli podejmować bardziej świadome decyzje. Za
            pośrednictwem aplikacji będzie można skontaktować się z
            psychologiem, ekspertem od usamodzielniania, księgowym, prawnikiem,
            czy doradcami zawodowymi. Odpowiedzą na wszystkie pytania i udzielą
            wsparcia.{" "}
          </p>
        </section>
        <section className="aboutProject__section w-100">
          <p>
            Dalsze funkcjonowanie aplikacji możliwe jest dzięki wsparciu{" "}
            <span className="font-weight-bold">KULCZYK FOUNDATION</span>, za co
            bardzo dziękujemy Dominice i Sebastianowi Kulczykom.
          </p>
        </section>
        <section className="aboutProject__section">
          <p>
            Aplikacja była tworzona wspólnie z młodzieżą, jest więc młodzieżowa
            – taka, jaką sobie wspólnie wymarzyliśmy! Bo, jak już wiecie,
            marzenia to cele, które przy pomocy innych mogą zrealizować się w
            pełni. Widać to na naszym przykładzie. Życzymy przyjemnego
            użytkowania.
          </p>
        </section>
        <section className="aboutProject__section w-100">
          <p className="font-weight-bold">
            Kontaktujcie się z nami! Jesteśmy dla Was!
          </p>
        </section>
        <section className="aboutProject__section w-100">
          <p className="font-weight-bold mb-0">Monika Krzyżanowska,</p>
          <p className="font-weight-bold">Założycielka fundacji One Day</p>
        </section>
      </div>
    </Container>
  );
};

export default AboutProject;
