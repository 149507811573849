import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FormGroup } from "components";
import { AlertContext } from "context";
import { approveFileSize } from "utils/approveFile/approveFile";
import { sendContact } from "Views/ContactPage/functions";
import PropTypes from "prop-types";

const NewContact = ({ show, handleClose, token, contact, refresh }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (contact) {
      setTitle(contact.title);
      setDescription(contact?.description || "");
      setPhoneNumber(contact.phone_number);
      setLogo(
        contact?.logo_url ? { name: contact.logo_url, fetched: true } : ""
      );
    }
  }, [contact, show]);

  const [validated, setValidated] = useState(false);

  const alertContext = useContext(AlertContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const data = { title, description, phone_number, logo };
      try {
        await sendContact(token, data, contact?.id);
        alertContext.showAlert(
          contact?.id
            ? "Pomyślnie edytowano kontakt"
            : "Pomyślnie przesłano kontakt",
          "success"
        );
        handleClose();
        refresh();
      } catch (err) {
        alertContext.showAlert(err.message);
      }
    }
    setValidated(true);
  };

  const handleChange = (file) => {
    if (approveFileSize(file)) {
      setLogo(file);
    } else {
      alertContext.showAlert(
        "Wybrany plik jest za duży. Maksymalny rozmiar pliku to 15 MB."
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Dodaj kontakt</Modal.Title>
      </Modal.Header>
      <Modal.Body
        as={Form}
        noValidate
        validated={validated}
        onSubmit={(e) => handleSubmit(e)}
      >
        <FormGroup
          header="Podaj tytuł"
          val={title}
          setVal={setTitle}
          id="contact_title"
          length={{ min: 1, max: 120 }}
          required
          incorrect="To pole jest wymagane"
        />
        <FormGroup
          header="Podaj numer telefonu"
          type="tel"
          val={phone_number}
          setVal={setPhoneNumber}
          id="contact_number"
          length={{ min: 1, max: 12 }}
          required
          incorrect="To pole jest wymagane. Przykładowy numer: 123123123"
        />
        <Form.File
          id="custom-file"
          onChange={(e) => handleChange(e.target.files[0])}
          custom
          label={logo?.name || "Zdjęcie kontaktu"}
          accept="image/*"
          data-browse="Wybierz plik"
          className="mb-3"
        />
        {logo && (
          <Button
            variant="danger"
            className="mb-3"
            onClick={() => {
              setLogo(null);
            }}
          >
            Usuń zdjęcie
          </Button>
        )}
        <FormGroup
          header="Opis"
          val={description}
          type="textarea"
          setVal={setDescription}
          id="contact_description"
          length={{ min: 1 }}
        />
        <Button variant="primary" type="submit">
          Prześlij
        </Button>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

NewContact.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  token: PropTypes.string,
  contact: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    logo_url: PropTypes.string,
    phone_number: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  refresh: PropTypes.func.isRequired,
};

export default NewContact;
