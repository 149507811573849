import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import React from "react";

export const ManageBlogsButton = ({ user }) => {
  return (
    <LinkContainer to={"/blog/create/newSelect"}>
      <Button className="blogs-category-button">
        <span className="align-self-center">ZARZĄDZAJ BLOGAMI</span>
      </Button>
    </LinkContainer>
  );
};
