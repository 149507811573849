import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { staffTypes } from "constants/staffTypes";
import { userTypes } from "constants/userTypes";
import { paths } from "constants/paths";

const NewWorkshopButton = ({ user }) =>
  user.type === userTypes.STAFF &&
  (user.data.group_type.includes(staffTypes.BLOG_MODERATOR) ||
    user.data.group_type.includes(staffTypes.GUEST)) ? (
    <LinkContainer to={`${paths.BLOG_WORKSHOP}`}>
      <Button variant="primary">Stwórz nowy warsztat</Button>
    </LinkContainer>
  ) : null;

export default NewWorkshopButton;
