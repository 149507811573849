import React, { useMemo } from "react";
import { Card, Alert } from "react-bootstrap";
import { Event } from "../";

const EventList = ({ date, todayEvents, labels, handleRefresh }) => {
  const parsedDate = useMemo(() => {
    return date.toLocaleDateString();
  }, [date]);

  const message =
    todayEvents?.length === 0 ? (
      <Alert variant="info">Brak wydarzeń danego dnia.</Alert>
    ) : null;

  return (
    <Card>
      <Card.Header>
        <h4>{`Lista wydarzeń dnia ${parsedDate}`}</h4>
      </Card.Header>
      <Card.Body>
        {message ||
          todayEvents?.map((event) => (
            <Event
              key={event.id}
              labels={labels}
              handleRefresh={handleRefresh}
              event={event}
            />
          ))}
      </Card.Body>
    </Card>
  );
};

export default EventList;
