import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const sendEnum = async (token, type, enumObject, typeName) => {
  const url = `${proxy.job}enums/${type}/${typeName ? `${typeName}/` : ""}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    headers,
    method: typeName ? "PUT" : "POST",
    body: JSON.stringify(enumObject),
  });
  if (response.status === 201 && !typeName) {
    return response.json();
  } else if (typeName && response.status === 200) {
    return response.json();
  } else {
    throw Error(await errorMessageText(response));
  }
};

export const deleteEnum = async (token, type, typeName) => {
  const url = `${proxy.job}enums/${type}/${typeName}/`;
  const headers = {
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    headers,
    method: "DELETE",
  });
  if (response.status === 200) {
    return response.json();
  } else {
    throw Error(await errorMessageText(response));
  }
};
