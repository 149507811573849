import React from "react";
import { Pagination } from "react-bootstrap";
import { useWindowSize } from "hooks";

const createArray = (max, current) => {
  const array = [];
  for (let i = Math.max(1, current - 2); i <= Math.min(max, current + 2); i++) {
    array[i] = i;
  }
  return array;
};

const PaginationForPeople = ({ setFilters, maxPage, filters }) => {
  const [width] = useWindowSize();
  return (
    <Pagination
      size={width >= 768 ? "lg" : "sm"}
      className="d-flex justify-content-center mt-3"
    >
      <Pagination.First
        disabled={filters.page === 1}
        onClick={() => setFilters((prevState) => ({ ...prevState, page: 1 }))}
      />
      <Pagination.Prev
        disabled={filters.page === 1}
        onClick={
          filters.page !== 1
            ? () =>
                setFilters((prevState) => ({
                  ...prevState,
                  page: prevState.page - 1,
                }))
            : () => {}
        }
      />
      {createArray(maxPage, filters.page).map((number) => (
        <Pagination.Item
          key={number}
          active={filters.page === number}
          onClick={() =>
            setFilters((prevState) => ({
              ...prevState,
              page: number,
            }))
          }
        >
          {number}
        </Pagination.Item>
      ))}
      <Pagination.Next
        disabled={filters.page === maxPage}
        onClick={
          filters.page !== maxPage
            ? () =>
                setFilters((prevState) => ({
                  ...prevState,
                  page: prevState.page + 1,
                }))
            : () => {}
        }
      />
      <Pagination.Last
        disabled={filters.page === maxPage}
        onClick={() =>
          setFilters((prevState) => ({
            ...prevState,
            page: maxPage,
          }))
        }
      />
    </Pagination>
  );
};

export default PaginationForPeople;
