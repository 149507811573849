import React, { useState, useContext } from "react";
import { Container, Button } from "react-bootstrap";
import headerImg from "assets/telefon_zaufania/header.jpg";
import { staffTypes } from "constants/staffTypes";
import { UserContext } from "context";
import { NewContact, ContactList } from "./components";

const ContactPage = () => {
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const user = useContext(UserContext);

  const refreshData = () => setRefresh((prevState) => !prevState);

  return (
    <Container className="font-only">
      <img
        alt="Nagłówek zaufanych telefonów"
        className="w-100"
        src={headerImg}
      />
      <div className="">
        {user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR) && (
          <div className="d-flex w-100">
            <Button
              variant="primary"
              className="contact__button"
              onClick={handleShow}
            >
              Dodaj nowy kontakt
            </Button>
            <NewContact
              show={show}
              handleClose={handleClose}
              token={user.token}
              refresh={refreshData}
            />
          </div>
        )}
        <ContactList refresh={refresh} refreshData={refreshData} />
      </div>
    </Container>
  );
};

export default ContactPage;
