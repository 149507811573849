import React from "react";
import { Link } from "react-router-dom";

const OfflinePage = () => {
  return (
    <div className="errorPage__container ">
      <div className="errorPage__content">
        <h4 className="errorPage__header">
          Aby korzystać z tych treści musisz mieć dostęp do internetu
        </h4>
        <h5 className="errorPage__subheader">
          Dostęp bez internetu posiadają strony:
        </h5>
        <ul>
          <li className="errorPage__li">
            <Link to="/safetyPhones">Strona kontaktu</Link>
          </li>
          <li className="errorPage__li">
            <Link to="/">Strona główna</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OfflinePage;
