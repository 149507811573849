import React, { useEffect, useState, useContext } from "react";
import { Container, Button, Row, Alert } from "react-bootstrap";
import { getContacts } from "./ContactFoundation.api";
import { Section, SectionForm, ContactForm } from "./components";
import { UserContext } from "context";
import { staffTypes } from "constants/staffTypes";
import contactHeader21 from "assets/contact/21_9kontakt.jpg";
import contactHeader16 from "assets/contact/kontakt16_9.jpg";
import { useWindowSize } from "hooks";

const ContactFoundation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sections, setSections] = useState([]);
  const [width] = useWindowSize();

  const [disabled, setDisabled] = useState(false);

  const [showContact, setShowContact] = useState(false);
  const [showSection, setShowSection] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const user = useContext(UserContext);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchContacts = async () => {
      if (!abortController.signal.aborted) {
        setLoading(true);
        setDisabled(true);
      }
      try {
        const response = await getContacts(abortController.signal);
        setSections(response);
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(err.message);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
        setDisabled(false);
      }
    };
    fetchContacts();
    return () => abortController.abort();
  }, [refresh]);

  const handleRefresh = () => setRefresh((prevState) => !prevState);

  const isAdmin = user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR);

  const message = error ? (
    <Alert variant="danger">{error}</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : sections.length === 0 ? (
    <Alert variant="info">Brak kontaktów.</Alert>
  ) : null;

  return (
    <Container className="contactFun__container">
      <img
        src={width >= 768 ? contactHeader21 : contactHeader16}
        alt="Nagłówek kontakt"
      />
      <div className="w-100">
        {isAdmin && (
          <Row className="m-0 mt-3 w-100">
            <Button
              variant="primary"
              className="mr-3"
              disabled={disabled}
              onClick={() => setShowContact(true)}
            >
              Dodaj kontakt
            </Button>
            <ContactForm
              sections={sections}
              token={user.token}
              handleClose={() => setShowContact(false)}
              show={showContact}
              disabled={disabled}
              setDisabled={setDisabled}
              handleRefresh={handleRefresh}
            />
            <Button
              variant="primary"
              disabled={disabled}
              onClick={() => setShowSection(true)}
            >
              Dodaj sekcję
            </Button>
            <SectionForm
              token={user.token}
              handleClose={() => setShowSection(false)}
              show={showSection}
              disabled={disabled}
              setDisabled={setDisabled}
              handleRefresh={handleRefresh}
            />
          </Row>
        )}
        <div className="contactFun__wrapper mt-4 w-100">
          {message ||
            sections.map((section, i) => (
              <Section
                section={section}
                key={section.id}
                sections={sections}
                handleRefresh={handleRefresh}
                index={i}
                disabled={disabled}
                setDisabled={setDisabled}
              />
            ))}
        </div>
      </div>
    </Container>
  );
};

export default ContactFoundation;
