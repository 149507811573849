import React, { useRef, useContext } from "react";
import { Form } from "react-bootstrap";
import { approveFileSize } from "utils/approveFile/approveFile";
import { AlertContext } from "context";

const CategoryPhoto = ({ placeholder, photo, setPhoto }) => {
  const alertContext = useRef(useContext(AlertContext));
  const fileInput = useRef(null);

  const onPhotoChange = async (files) => {
    if (approveFileSize(files[0])) {
      setPhoto(files[0]);
    } else {
      alertContext.current.showAlert(
        "Przekazane zdjęcie jest za duże(max 15mb)"
      );
    }
  };

  return (
    <div>
      <Form.Label>{placeholder}</Form.Label>
      <Form.File
        name="photo"
        id="blogspot_header_photo"
        label={photo !== null ? photo?.name : placeholder}
        custom
        ref={fileInput}
        onChange={(e) => onPhotoChange(e.target.files)}
        accept="image/*"
        data-browse="Dodaj"
        className="mb-3 overflow-hidden"
      />
    </div>
  );
};

export default CategoryPhoto;
