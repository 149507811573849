import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import {
  Accordion,
  Card,
  Alert,
  ListGroup,
  Row,
  Button,
} from "react-bootstrap";
import "Views/MyOffersPage/style.css";
import { UserContext, AlertContext } from "context";
import {
  getOfferPeople,
  getZipUrl,
  deleteOffer,
  changeStateOfOffer,
} from "../functions/apiCalls";
import MyOfferPerson from "./MyOfferPerson";
import PaginationForPeople from "./PaginationForPeople";
import { Link } from "react-router-dom";
import { DeletionModal } from "components";
import proxy from "config/api";
import Filter from "./Filter";

const MyOffer = ({ offer, activeOffer, setActiveOffer, handleRefresh }) => {
  const context = useContext(UserContext);
  const [people, setPeople] = useState([]);
  const [loading, setLoading] = useState(false);
  const alertC = useRef(useContext(AlertContext));
  const [filters, setFilters] = useState({
    pageSize: 10,
    page: 1,
  });

  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [idDel, setIdDel] = useState(-1);

  const handleDelete = (offerId) => {
    setIdDel(offerId);
    setShowModal(true);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const loadOfferPeople = async (token, offerId) => {
      if (!abortController.signal.aborted) setLoading(true);
      try {
        let res = await getOfferPeople(
          token,
          offerId,
          filters,
          abortController.signal
        );
        if (res.count > 0) {
          setPeople(res);
        } else {
          setPeople([]);
        }
      } catch (err) {
        if (!abortController.signal.aborted)
          alertC.current.showAlert(err.message);
      }
      if (!abortController.signal.aborted) setLoading(false);
    };
    if (offer.id === activeOffer) {
      loadOfferPeople(context.token, offer.id, activeOffer);
    }
    return () => abortController.abort();
  }, [context.token, activeOffer, offer.id, filters]);

  useEffect(() => {
    const handleDelete = async (id) => {
      try {
        const res = await deleteOffer(context.token, id);
        alertC.current.showAlert(res.message, "success");
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDeletionConfirmed(false);
      handleRefresh();
    };
    if (deletionConfirmed) handleDelete(idDel);
  }, [context.token, deletionConfirmed, handleRefresh, idDel]);

  const downloadApplications = async () => {
    try {
      let res = await getZipUrl(context.token, offer.id);
      let url = proxy.plainFiles + res.url;
      window.open(url, "_blank");
    } catch (err) {
      alertC.current.showAlert(err.message);
    }
  };

  const message = loading ? (
    <Alert variant="info">Ładuję...</Alert>
  ) : people.length === 0 ? (
    <Alert className="mb-0" variant="info">
      Brak zgłoszeń.
    </Alert>
  ) : null;

  const handleState = async (id, state) => {
    try {
      const res = await changeStateOfOffer(context.token, state, id);
      alertC.current.showAlert(res.message, "success");
      handleRefresh();
    } catch (err) {
      alertC.current.showAlert(err.message);
    }
  };

  const maxPage = useMemo(
    () => Math.ceil(people.count / filters.pageSize),
    [filters.pageSize, people.count]
  );

  return (
    <Card className="border-left-0 border-right-0 border-bottom-0">
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDeletionConfirmed}
        question={"Czy napewno chcesz usunąć tą ofertę?"}
      />
      <Accordion.Toggle
        as={Card.Header}
        eventKey={offer.id}
        onClick={(e) => setActiveOffer(offer.id)}
      >
        {offer.offer_name}
        {offer.archived ? null : offer.confirmed ? (
          <span className="text-success"> - zaakceptowana</span>
        ) : (
          <span className="text-warning"> - oczekuje na akceptacje</span>
        )}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={offer.id}>
        <Card.Body className="p-0">
          <ListGroup>
            <Filter filters={filters} setFilters={setFilters} />
            {message ? (
              message
            ) : (
              <>
                {people.results?.map((value) => (
                  <MyOfferPerson person={value} key={value.id} />
                ))}
                <PaginationForPeople
                  maxPage={maxPage}
                  filters={filters}
                  setFilters={setFilters}
                />
                {/* <Pagination
                  current={filters.page}
                  max={Math.ceil(people.count / filters.pageSize)}
                /> */}
              </>
            )}
            <ListGroup.Item>
              <Row className="justify-content-center">
                <Link to={"/jobOffers/" + offer.id}>
                  <Button className="mr-3 mb-1">Pokaż ofertę</Button>
                </Link>
                <Link to={"/offerForm/" + offer.id}>
                  <Button className="mr-3 mb-1">Edytuj ofertę</Button>
                </Link>
                {offer.archived ? (
                  <Button
                    className="mr-3 mb-1"
                    onClick={() => handleState(offer.id, "activate")}
                  >
                    Aktywuj ofertę
                  </Button>
                ) : (
                  <Button
                    className="mr-3 mb-1"
                    onClick={() => handleState(offer.id, "archive")}
                  >
                    Archiwizuj ofertę
                  </Button>
                )}
                <Button
                  className="mr-3 mb-1"
                  variant="danger"
                  onClick={() => handleDelete(offer.id)}
                >
                  Usuń ofertę
                </Button>
                {people.count > 0 && (
                  <Button
                    className="mb-1"
                    onClick={() => downloadApplications()}
                  >
                    Pobierz zgłoszenia
                  </Button>
                )}
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default MyOffer;
