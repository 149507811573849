import proxy from "config/api";

function getBase64Image(url) {
  return new Promise((resolve, reject) => {
    var img = new Image(),
      canvas = document.createElement("canvas"),
      ctx = canvas.getContext("2d"),
      src = url.includes(proxy.plainFiles) ? url : `${proxy.plainFiles}${url}`; // insert image url here

    img.crossOrigin = "Anonymous";

    img.onload = function async() {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL("image/png"));
    };
    img.src = src;
    // make sure the load event fires for cached images too
    if (img.complete || img.complete === undefined) {
      img.src =
        "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  });
}

export default getBase64Image;
