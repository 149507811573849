import React, { useState, useRef, useContext, useEffect } from "react";
import { Card, Button, Accordion, Alert } from "react-bootstrap";
import { DeletionModal } from "components";
import { AlertContext } from "context";
import { deleteLabel } from "../../functions";
import { LabelCreator } from "../";

const LabelList = ({ token, refresh, labels, error, loading }) => {
  const alertC = useRef(useContext(AlertContext));

  const [idDel, setIdDel] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);

  const handleDelModal = (id) => {
    setShowModal(true);
    setIdDel(id);
  };

  useEffect(() => {
    const handleDelete = async (id) => {
      try {
        await deleteLabel(token, id);
        alertC.current.showAlert(
          "Poprawnie usunięto rok warsztatów.",
          "success"
        );
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDeletionConfirmed(false);
      refresh((prevState) => !prevState);
    };

    if (deletionConfirmed) handleDelete(idDel);
  }, [deletionConfirmed, idDel, refresh, token]);

  const message = error ? (
    <Alert variant="danger">Błąd ładowania lat.</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : labels.length === 0 ? (
    <Alert variant="info">Brak dodanych lat.</Alert>
  ) : null;

  return (
    <Accordion>
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDeletionConfirmed}
        question={"Czy na pewno chcesz usunąć ten rok?"}
      />
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h4>Zarządzaj warsztatami</h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <h4>Aktualnie dodane lata:</h4>
            {message ||
              labels?.map((label) => (
                <div key={label.id} className="mt-3">
                  <h5>
                    {label.year}
                    <Button
                      variant="danger"
                      className="ml-2"
                      onClick={() => handleDelModal(label.id)}
                    >
                      X
                    </Button>
                  </h5>
                  <LabelCreator data={label} token={token} refresh={refresh} />
                </div>
              ))}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default LabelList;
