import React from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import routes from "constants/routes";
import PrivateRoute from "root/_components/PrivateRoute";
import { UserContext } from "context";

const Router = () => {
  const location = useLocation();

  const isRedirected = (offline) => {
    if (navigator.onLine) {
      return false;
    } else if (!navigator.onLine && offline) {
      return false;
    }
    return true;
  };

  return (
    <UserContext.Consumer>
      {(value) => {
        return (
          <Switch location={location} key={location.pathname}>
            {routes.map(
              ({
                component: Component,
                path,
                isPrivate,
                userVerified,
                offline,
                ...rest
              }) => {
                if (isPrivate) {
                  return (
                    <PrivateRoute
                      path={path}
                      key={path}
                      userVerified={userVerified}
                      {...rest}
                      authenticated={value}
                    >
                      {isRedirected(offline) ? (
                        <Redirect to="/offline" />
                      ) : (
                        <Component {...rest} />
                      )}
                    </PrivateRoute>
                  );
                }
                return (
                  <Route path={path} key={path} {...rest}>
                    {isRedirected(offline) ? (
                      <Redirect to="/offline" />
                    ) : (
                      <Component {...rest} />
                    )}
                  </Route>
                );
              }
            )}
            <Redirect to="/error" />
          </Switch>
        );
      }}
    </UserContext.Consumer>
  );
};

export default Router;
