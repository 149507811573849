import proxy from "config/api";
import { errorMessageText } from "constants/other.js";

export const changePosition = async (token, newPos, oldPos) => {
  const url = `${proxy.menu}index-change/`;

  const headers = {
    "Content-Type": "Application/json",
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    headers,
    method: "PATCH",
    body: JSON.stringify({ curr_index: oldPos, new_index: newPos }),
  });

  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};
