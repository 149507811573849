import {
  BlogPost,
  BlogPostCreator,
  ContactPage,
  CVApprovalPage,
  CVEditorPage,
  Footer,
  JobOfferDetails,
  JobOffersPage,
  LoginPage,
  Menu,
  MyCVsPage,
  MyOffersPage,
  OfferForm,
  RegisterPage,
  UserProfilePage,
  UserApprovalPage,
  Steps,
  CVCorrection,
  MessagesList,
  Chats,
  UserList,
  OfferApprovalPage,
  ChangeData,
  PasswordResetPrompt,
  NewPasswordPage,
  Blogs,
  Blog,
  BlogSelectorsCreator,
  AskExpert,
  ErrorPage,
  JobOffersEnumsCreator,
  GenerateApiKeys,
  Workshops,
  WorkshopPost,
  OfflinePage,
  ListOfApplications,
  AboutProject,
  ContactFoundation,
  DocumentsEditor,
  PermissionChange,
  Calendar,
  ConfirmUser,
  ResendEmailVerification,
  TermsAccepted,
  RedirectToRegulations,
} from "Views";
import routesInfo from "./routesInfo";

export default [
  {
    component: Menu,
  },

  {
    component: CVEditorPage,
  },
  {
    component: CVEditorPage,
  },
  {
    component: RegisterPage,
  },
  {
    component: RegisterPage,
  },
  {
    component: Footer,
  },
  {
    component: LoginPage,
  },
  {
    component: UserProfilePage,
  },
  {
    component: JobOffersPage,
  },
  {
    component: JobOfferDetails,
  },
  {
    component: OfferForm,
  },
  {
    component: CVApprovalPage,
  },
  {
    component: MyOffersPage,
  },
  {
    component: ContactPage,
  },
  /*{
    component: BlogPost,
  },*/
  {
    component: BlogPostCreator,
  },
  {
    component: BlogPostCreator,
  },
  {
    component: MyCVsPage,
  },
  {
    component: UserApprovalPage,
  },
  {
    component: Steps,
  },
  {
    component: CVCorrection,
  },
  {
    component: Chats,
  },
  {
    component: MessagesList,
  },
  {
    component: UserList,
  },
  {
    component: OfferApprovalPage,
  },
  {
    component: ChangeData,
  },
  {
    component: PasswordResetPrompt,
  },
  {
    component: NewPasswordPage,
  },
  {
    component: Blogs,
  },
  {
    component: Blog,
  },
  {
    component: BlogPost,
  },
  {
    component: BlogSelectorsCreator,
  },
  {
    component: BlogPostCreator,
  },
  {
    component: BlogPostCreator,
  },
  {
    component: AskExpert,
  },
  {
    component: ErrorPage,
  },
  {
    component: JobOffersEnumsCreator,
  },
  {
    component: GenerateApiKeys,
  },
  {
    component: Workshops,
  },
  {
    component: WorkshopPost,
  },
  { component: OfflinePage },
  { component: ListOfApplications },
  { component: AboutProject },
  { component: ContactFoundation },
  { component: DocumentsEditor },
  { component: PermissionChange },
  { component: Calendar },
  { component: ConfirmUser },
  { component: ResendEmailVerification },
  { component: TermsAccepted },
  { component: RedirectToRegulations },
].map((value, i) => ({ ...value, ...routesInfo[i] }));
