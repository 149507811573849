import React, { useEffect, useState } from "react";
import { getTopics } from "Views/BlogPostCreator/functions";
import { FormGroup } from "components";
import { Alert } from "react-bootstrap";

const TopicSelector = ({ token, activeTopic, setActiveTopic, category }) => {
  const [topics, setTopics] = useState([]);
  const [choosen, setChoosen] = useState("-- Brak tematu --");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChoosen(activeTopic || "-- Brak tematu --");
  }, [activeTopic, category]);

  useEffect(() => {
    setLoading(true);
    const fetchTopic = async () => {
      try {
        const res = await getTopics(token, [category]);
        setTopics(res[0].topics);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    };
    fetchTopic();
  }, [category, token]);

  const message = error ? (
    <Alert variant="danger">Nie udało się pobrać tagów.</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : null;

  return (
    <>
      <div className="tagSelector__wrapper">
        <div className="tagSelector__container">
          <FormGroup
            header="Wybierz temat do którego należy post:"
            type="select"
            id="topicSelect"
            val={choosen}
            setVal={(value) => {
              setChoosen(value);
              setActiveTopic(topics.find((topic) => topic.name === value).id);
            }}
            defaultSelect="-- Brak tematu --"
            array={topics.map(({ name }) => name)}
          />
        </div>
      </div>
      {message}
    </>
  );
};

export default TopicSelector;
