import React from "react";
import proxy from "config/api";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const ButtonToPDF = ({ text, error, disabled, link, small }) => {
  return error ? (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      className="mx-1"
      to={"/error"}
      disabled={disabled}
    >
      {small ? <small>{text}</small> : text}
    </Link>
  ) : (
    <Button
      className="p-0 mx-1 align-top"
      variant="link"
      target="_blank"
      rel="noopener noreferrer"
      disabled={disabled}
      href={`${proxy.plainFiles}${link}`}
    >
      {small ? <small>{text}</small> : text}
    </Button>
  );
};

export default ButtonToPDF;
