import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Container, Button, Alert } from "react-bootstrap";
import { Choice, NextChoices, StartStepCreator } from "./components";
import { getStart, getChoice } from "./functions";
import { AlertContext, UserContext } from "context";

import { staffTypes } from "constants/staffTypes";
import { userTypes } from "constants/userTypes";

import background from "assets/backgrounds/krokiusamodzielnienia.jpg";
import AddStepButton from "./components/Choice/components/AddStepButton";
import AddChoiceButton from "./components/Choice/components/AddChoiceButton";
import RelationsForm from "./components/RelationForm";
import Step from "./components/Step";

const StepsCreator = () => {
  const user = useContext(UserContext);
  const [path, setPath] = useState([]);
  const alertContext = useRef(useContext(AlertContext));
  const [idOfChoice, setIdOfChoice] = useState(-1);

  const [disabled, setDisabled] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchStart = async () => {
      setLoading(true);
      try {
        const res = await getStart(abortController.signal);
        setPath((prevState) => [...prevState, res]);
      } catch (err) {
        if (err.message !== "404" && !abortController.signal.aborted) {
          setError(true);
        }
      }
      if (!abortController.signal.aborted) setLoading(false);
    };
    fetchStart();
    return () => abortController.abort();
  }, []);

  const updateTree = useCallback(async () => {
    try {
      const res = await getChoice(idOfChoice);
      setPath((prevState) => {
        const newArr = [...prevState];
        newArr.splice(
          newArr.findIndex(({ id }) => id === idOfChoice),
          1,
          res
        );
        return [...newArr];
      });
      setIdOfChoice(-1);
    } catch (err) {}
  }, [idOfChoice]);

  useEffect(() => {
    if (idOfChoice !== -1) {
      updateTree();
    }
  }, [idOfChoice, updateTree]);

  const updatePath = useCallback(async (previous, choice) => {
    if (previous.length === 0) {
      setPath([]);
    } else {
      setPath((prevState) => {
        const newArr = [...prevState];
        newArr.splice(newArr.findIndex(({ id }) => id === choice.id));
        newArr[newArr.length - 1].paths.splice(
          newArr.findIndex(({ id }) => id === choice.id),
          1
        );
        return [...newArr];
      });
      setIdOfChoice(previous);
    }
  }, []);

  const handleClick = async (id) => {
    setDisabled(true);
    try {
      const res = await getChoice(id);
      setPath((prevState) => [...prevState, res]);
    } catch (err) {
      alertContext.current.showAlert(err.message);
    }
    setDisabled(false);
  };

  const handleBack = async (id) => {
    setDisabled(true);
    let actualIndexOfChoice = path.findIndex(
      (pathElement) => pathElement.id === id
    );
    setPath((prevState) => {
      const newState = [...prevState];
      newState.length = actualIndexOfChoice + 1;
      return newState;
    });
    setDisabled(false);
  };

  const message = loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : error ? (
    <Alert variant="danger">Błąd ładowania kroków</Alert>
  ) : path.length === 0 ? (
    <>
      <Alert variant="info">Brak kroków</Alert>
      {user.type === userTypes.STAFF &&
        user.data.group_type.includes(staffTypes.BLOG_MODERATOR) && (
          <Button onClick={() => setShowModal(true)}>
            Utwórz pytanie startowe
          </Button>
        )}
    </>
  ) : null;

  return (
    <Container className="font-only d-flex flex-column align-items-center">
      <img src={background} alt="background" className="w-100 mb-5" />
      <StartStepCreator
        show={showModal}
        token={user.token}
        handleHide={() => setShowModal(false)}
        refresh={setIdOfChoice}
      />

      {message ||
        path.map((choice, index) => (
          <div key={choice.id} className="steps-background steps-container">
            <Choice
              choice={choice}
              refresh={setIdOfChoice}
              updatePath={updatePath}
              handleBack={handleBack}
            />
            {choice?.steps?.map((step, i) => (
              <Step
                step={step}
                key={step.id}
                user={user}
                choice={choice?.id}
                refresh={setIdOfChoice}
                first={i === 0}
                last={i === choice.steps.length - 1}
                endStep={
                  index === path.length - 1 && i === choice.steps.length - 1
                }
              />
            ))}
            {user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR) ? (
              <div className="d-flex justify-content-center mt-3">
                <AddStepButton
                  refresh={setIdOfChoice}
                  token={user.token}
                  choice={choice}
                />
                <AddChoiceButton
                  refresh={setIdOfChoice}
                  token={user.token}
                  choice={choice}
                />
                <RelationsForm
                  refresh={setIdOfChoice}
                  token={user.token}
                  choice={choice}
                />
              </div>
            ) : null}
            <NextChoices
              showButtons={path[path.length - 1].id === choice.id}
              choice={choice}
              disabled={disabled}
              handleNextChoice={handleClick}
              refresh={setIdOfChoice}
              token={user.token}
            />
          </div>
        ))}
    </Container>
  );
};

export default StepsCreator;
