import React from "react";
import { Card, Badge, Form } from "react-bootstrap";

const LabelFilter = ({ labels, setFilters, filters }) => {
  return (
    <Card>
      <Card.Body as={Form}>
        {labels.map(({ name, color, id }) => (
          <Form.Group
            controlId="event_checkUrl"
            className="d-inline-block mr-3 mb-0"
            key={id}
          >
            <Form.Check
              type="checkbox"
              label={
                <Badge
                  variant="primary"
                  className="calendar__label calendar__label--view"
                  key={id}
                  style={{ backgroundColor: color }}
                >
                  {name}
                </Badge>
              }
              checked={!filters[id]}
              onChange={() =>
                setFilters((prevState) => ({
                  ...prevState,
                  [id]: !prevState[id],
                }))
              }
            />
          </Form.Group>
        ))}
      </Card.Body>
    </Card>
  );
};

export default LabelFilter;
