import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ChoiceForm } from "../../../";
import PropTypes from "prop-types";

const AddChoiceButton = ({ choice, token, refresh }) => {
  const [showCreateChoice, setShowCreateChoice] = useState(false);
  return (
    <>
      <ChoiceForm
        show={showCreateChoice}
        previous={choice?.id}
        token={token}
        refresh={refresh}
        handleHide={() => setShowCreateChoice(false)}
      />
      <Button
        className="steps-choice-edit-button"
        onClick={() => setShowCreateChoice(true)}
      >
        Dodaj pytanie
      </Button>
    </>
  );
};

AddChoiceButton.propTypes = {
  choice: PropTypes.shape({
    id: PropTypes.number,
    level: PropTypes.number.isRequired,
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,
      })
    ),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        description: PropTypes.string,
        attachment_url: PropTypes.string,
      })
    ),
    previous: PropTypes.number,
    title: PropTypes.string.isRequired,
  }),
  token: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default AddChoiceButton;
