import React, { useRef, useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { approveFileSize } from "utils/approveFile/approveFile";

import { AlertContext } from "context";

const Header = ({ setHeader, setPhoto, condition }) => {
  const fileInput = useRef(null);
  const [photoName, setPhotoName] = useState("");
  const [video, setVideo] = useState("");
  const alertC = useRef(useContext(AlertContext));

  const onPhotoChange = async (files) => {
    if (approveFileSize(files[0])) {
      setPhotoName(files[0].name);
      setPhoto(files[0]);
      setHeader("");
    } else {
      alertC.current.showAlert(
        "Wybrany plik jest za duży. Maksymalna wielkość to 15 mb."
      );
    }
  };

  const submitVideo = async () => {
    if (
      video.includes("https://www.youtube.com/") ||
      video.includes("https://youtu.be/")
    ) {
      setHeader(video);
      setPhoto(null);
    } else {
      alertC.current.showAlert(
        "Dodałeś niepoprawny film, link powinien zaczynać się od: https://www.youtube.com/ lub https://youtu.be/"
      );
    }
  };

  return (
    <Form.Group controlId="blogpost_photo" className="blogCreator__header">
      <div className="blogCreator__photoInput">
        <Form.File
          name="photo"
          id="blogspot_header_photo"
          label={
            photoName !== "" ? photoName : "Wybierz zdjęcie na nagłówek posta"
          }
          custom
          ref={fileInput}
          onChange={(e) => onPhotoChange(e.target.files)}
          accept="image/*"
          data-browse="Dodaj"
        />
      </div>
      <p className="blogCreator__paragraph">Lub:</p>
      <div className="blogCreator__videoInput align-items-center">
        <Form.Control
          className="blogCreator__videoInput--input"
          value={video}
          onChange={(e) => setVideo(e.target.value)}
          placeholder="Np. https://www.youtube.com/watch?v=K9wbrl-0SJE"
        />
        <Button className="blogCreator__button" onClick={submitVideo}>
          Dodaj film
        </Button>
        {condition && (
          <Button
            className="blogCreator__button ml-1"
            variant="danger"
            onClick={() => {
              setPhoto(null);
              setHeader(null);
              setPhotoName("");
            }}
          >
            Usuń nagłówek posta
          </Button>
        )}
      </div>
    </Form.Group>
  );
};

export default Header;
