import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import { FormGroup } from "components";
import { choiceUpload } from "../../functions";
import { AlertContext } from "context";

const ChoiceForm = ({ show, choice, previous, token, handleHide, refresh }) => {
  const [title, setTitle] = useState(choice?.title || "");
  const alertContext = useRef(useContext(AlertContext));
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        const data = {};
        data.title = title;

        if (previous && !choice?.id) {
          data.parent = previous;
        }
        await choiceUpload(token, data, choice?.id);
        refresh(previous);
        alertContext.current.showAlert(
          choice?.id
            ? "Pomyślnie edytowano pytanie"
            : "Pomyślnie dodano pytanie",
          "success"
        );
        handleHide();
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
    }
    setValidated(true);
  };

  const createTitle = choice?.id ? "Edytuj pytanie" : "Stwórz nowe pytanie";

  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>{createTitle}</Modal.Header>
      <Modal.Body
        noValidate
        validated={validated}
        as={Form}
        onSubmit={(e) => handleSubmit(e)}
      >
        <FormGroup
          header="Podaj tytuł"
          id="choice_title"
          val={title}
          setVal={setTitle}
          required
          incorrect="To pole jest wymagane."
        />
        <Button type="submit">Prześlij</Button>
      </Modal.Body>
    </Modal>
  );
};

ChoiceForm.propTypes = {
  token: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  choice: PropTypes.shape({
    id: PropTypes.number,
    level: PropTypes.number.isRequired,
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,
      })
    ),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        description: PropTypes.string,
        attachment_url: PropTypes.string,
      })
    ),
    parents: PropTypes.arrayOf(PropTypes.number).isRequired,
    title: PropTypes.string.isRequired,
  }),
  previous: PropTypes.number.isRequired,
  handleHide: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default ChoiceForm;
