import { staffTypes } from "constants/staffTypes";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import React from "react";

export const ManageWorkshopsButton = ({ user }) => {
  if (user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR)) {
    return (
      <LinkContainer to={"/blog/create/newSelect"}>
        <Button className="blogs-category-button">
          <span className="align-self-center">ZARZĄDZAJ WARSZTATAMI</span>
        </Button>
      </LinkContainer>
    );
  } else return null;
};
