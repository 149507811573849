import proxy from "config/api";

export const getBlogs = async (signal) => {
  let url = proxy.blog + "categories/";
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers, signal });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw response.status;
  }
};
