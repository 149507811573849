import React from "react";
import { Alert } from "react-bootstrap";

const OffilneAlert = ({ handleClose }) => {
  return (
    <Alert
      variant="info"
      onClick={handleClose}
      className="offlineAlert"
      dismissible
    >
      Aktualnie przeglądasz stronę bez internetu. Dostęp do niektórych treści
      jest ograniczony.
    </Alert>
  );
};

export default OffilneAlert;
