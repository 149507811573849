import React from "react";
import { Modal, Alert } from "react-bootstrap";
import { Label } from "../";

const LabelList = ({ show, handleClose, handleRefresh, labels }) => {
  const message =
    labels.length === 0 ? (
      <Alert variant="info">Brak dodanych etykiet.</Alert>
    ) : null;
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lista etykiet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message ||
          labels.map((label) => (
            <Label key={label.id} handleRefresh={handleRefresh} label={label} />
          ))}
      </Modal.Body>
    </Modal>
  );
};

export default LabelList;
