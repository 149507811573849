import React, { useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { getWorkshops } from "./api";
import header from "assets/warsztaty.jpg";
import WorkshopYear from "./components/WorkshopYear";
import { NewWorkshopPostButton } from "./components/NewWorkshopPostButton/NewWorkshopPostButton";
import { ManageWorkshopsButton } from "./components/ManageWorkshopsButton/ManageWorkshopsButton";
import { UserContext } from "context";
import ApplyForWorkshop from "../WorkshopPost/components/ApplyForWorkshop";

const Workshops = () => {
  const user = useContext(UserContext);

  const [workshops, setWorkshops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const loadWorkshops = async () => {
      if (!abortController.signal.aborted) setLoading(true);
      try {
        let res = await getWorkshops(abortController.signal);
        setWorkshops(res);
      } catch (e) {
        if (!abortController.signal.aborted) setError(true);
      }
      if (!abortController.signal.aborted) setLoading(false);
    };
    loadWorkshops();
    return () => abortController.abort();
  }, []);

  return (
    <Container className="font-only px-0">
      <img src={header} className="w-100" alt={"workshops_background"} />
      <div className="padding__text">
        {loading ? (
          <div className="blogs-loading">ŁADOWANIE</div>
        ) : !error ? (
          <>
            <Row className="w-100 d-flex justify-content-center m-0">
              <ApplyForWorkshop
                workshop={null}
                buttonText={"Zaproponuj warsztat"}
                canApply={true}
              />
            </Row>
            {workshops.map((workshop) => (
              <WorkshopYear workshop={workshop} key={workshop.id} />
            ))}
            {workshops.length === 0 ? (
              <div className="blogs-loading">BRAK WARSZTATÓW</div>
            ) : null}
            <Row className="w-100 d-flex justify-content-center">
              <NewWorkshopPostButton user={user} />
              <ManageWorkshopsButton user={user} />
            </Row>
          </>
        ) : (
          <div className="blogs-loading">WYSTĄPIŁ BŁĄD</div>
        )}
      </div>
    </Container>
  );
};

export default Workshops;
