import React, { useState, useRef, useContext, useEffect } from "react";
import { Card, Form, Button, Alert, Accordion } from "react-bootstrap";
import { FormGroup } from "components";
import { AlertContext } from "context";
import { sendTopic } from "../../functions";

const TopicCreator = ({ refresh, categories, token, data, loading, error }) => {
  const alertContext = useRef(useContext(AlertContext));
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");

  const message = error ? (
    <Alert variant="danger">Nie udało się załadować kategorii</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : null;

  useEffect(() => {
    setCategory(data?.category.name || categories[0]?.name);
    setName(data?.name || "");
  }, [categories, data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        await sendTopic(
          token,
          {
            name,
            category: categories.find(({ name }) => name === category).id,
          },
          data?.id
        );

        alertContext.current.showAlert(
          data
            ? `Pomyślnie zmieniono temat: ${name} w kategorii: ${category}`
            : `Pomyślnie dodano temat: ${name} do kategorii: ${category}`,
          "success"
        );
        refresh((prevState) => !prevState);
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
    }
  };

  return (
    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h4>{data ? "Edytuj temat" : "Stwórz nowy temat"}</h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body as={Form} onSubmit={handleSubmit}>
            <FormGroup
              required
              type="select"
              array={categories.map(({ name }) => name)}
              val={category}
              setVal={setCategory}
              header="Kategoria w której ma być temat*"
              id="category_layout"
            />
            {message}
            <FormGroup
              required
              val={name}
              setVal={setName}
              length={{ min: 1, max: 60 }}
              header="Nazwa tematu*"
              id="category_name"
            />
            <Button type="submit">{data ? "Edytuj" : "Dodaj temat"}</Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default TopicCreator;
