import React, { useContext, useRef } from "react";
import { Button, Badge, Accordion } from "react-bootstrap";
import { TopicCreator } from "../";
import { AlertContext } from "context";
import { moveTopic } from "../../functions";

const TopicsEdit = ({ data, refresh, token, categories, onClickDelete }) => {
  const alertCon = useRef(useContext(AlertContext));

  const handleMove = async (id, dir) => {
    try {
      await moveTopic(token, id, dir);
      alertCon.current.showAlert(`Pomyślnie przesunięto`, "success");
    } catch (err) {
      alertCon.current.showAlert(err.message);
    }
    refresh((prevState) => !prevState);
  };
  return (
    <div className="d-flex flex-column mt-3">
      {data.topics.map((topic) => (
        <div key={topic.id} className="mb-3 w-100">
          <Badge variant="info" className="p-2 mr-1 my-1">
            {topic.name}
          </Badge>
          <Button
            variant="danger"
            data-testid="close"
            onClick={() => onClickDelete(topic.id)}
          >
            Usuń
          </Button>
          <Accordion>
            <div className="mb-1">
              {topic.index !== 0 && (
                <Button
                  className="mr-1"
                  onClick={() => handleMove(topic.id, "up")}
                >
                  Przesuń w górę
                </Button>
              )}
              {topic.index !== data.topics.length - 1 && (
                <Button
                  className="mr-1"
                  onClick={() => handleMove(topic.id, "down")}
                >
                  Przesuń w dół
                </Button>
              )}
            </div>

            <TopicCreator
              refresh={refresh}
              categories={categories.map(({ name, id }) => ({
                name,
                id,
              }))}
              token={token}
              data={{
                ...topic,
                category: {
                  name: data.category.name,
                  id: data.category.id,
                },
              }}
            />
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default TopicsEdit;
