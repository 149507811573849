import React from "react";
import { Col, Row } from "react-bootstrap";
import YouTube from "react-youtube";
import { YouTubeGetID } from "constants/other";
import StepButtons from "./components/StepButtons";

const Step = ({
  step,
  user,
  refresh,
  choice,
  first,
  last,
  endStep = false,
}) => {
  return (
    <>
      <Row className="steps__step">
        {step.is_visible ? (
          <Col xs={12} md={5} className="step-desc">
            <p className="step-title">{step.title}</p>
            <p className="step-text">{step.description}</p>
            <StepButtons
              user={user}
              refresh={refresh}
              choice={choice}
              first={first}
              last={last}
              step={step}
            />
          </Col>
        ) : (
          <Col xs={12} md={5}>
            <StepButtons
              user={user}
              refresh={refresh}
              choice={choice}
              first={first}
              last={last}
              step={step}
            />
          </Col>
        )}
        <Col sm={2} md={2} className="steps-flow">
          <Row className="steps-line" />
          <Row className="steps-dot" />
          <Row className={"steps-line" + (endStep ? " hidden " : "")} />
        </Col>
        <Col xs={12} md={5} className="step-media">
          {step.attachment_url ? (
            step?.attachment_url?.includes("https://www.youtube.com/") ? (
              <YouTube
                videoId={YouTubeGetID(step.attachment_url)}
                className="step-video"
              />
            ) : (
              <img
                src={step.attachment_url}
                alt="step-img"
                className="step-img"
              />
            )
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default Step;
