import React, { useContext, useRef, useState, useEffect } from "react";
import { UserContext, AlertContext } from "context";
import { Button, Card, ListGroup, Row } from "react-bootstrap";
import { DetailsItem, DeletionModal } from "components";
import {
  setOfferApproved,
  setOfferRejected,
} from "Views/OfferApprovalPage/apiCalls";
import { deleteOffer } from "Views/MyOffersPage/functions/apiCalls";
import proxy from "config/api";

const OfferPosition = ({ offer }) => {
  const [actionTaken, setActionTaken] = useState(false);
  const context = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));
  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);

  const approveOffer = async (e) => {
    e.preventDefault();
    try {
      await setOfferApproved(context.token, offer.id);
      alertC.current.showAlert("Pomyślnie potwierdzono ofertę", "success");
      setActionTaken(true);
    } catch (err) {
      alertC.current.showAlert("Nie udało się potwierdzić oferty.");
    }
  };

  const rejectOffer = async (e) => {
    e.preventDefault();
    try {
      await setOfferRejected(context.token, offer.id);
      alertC.current.showAlert("Pomyślnie odrzucono ofertę.", "success");
      setActionTaken(true);
    } catch (e) {
      alertC.current.showAlert("Nie udało się odrzucić oferty.");
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const handleDelete = async (id) => {
      try {
        const res = await deleteOffer(
          context.token,
          offer.id,
          abortController.signal
        );
        alertC.current.showAlert(res.message, "success");
        setActionTaken(true);
      } catch (err) {
        if (!abortController.signal.aborted)
          alertC.current.showAlert(err.message);
      }
      if (!abortController.signal.aborted) setDeletionConfirmed(false);
    };
    if (deletionConfirmed) handleDelete();
    return () => abortController.abort();
  }, [context.token, deletionConfirmed, offer.id]);

  return (
    <Card.Body className="p-0">
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDeletionConfirmed}
        question={"Czy napewno chcesz usunąć tą ofertę?"}
      />
      <ListGroup variant="flush">
        <ListGroup.Item>
          <div className="offerToApprove__wrapper">
            {offer.offer_image && (
              <img
                alt="Obrazek oferty pracy"
                className="offerToApprove__offerImage"
                src={`${proxy.plainFiles}${offer.offer_image}`}
              />
            )}
          </div>
          <Row>
            <DetailsItem md={4} xl={2} label="Województwo">
              {offer.voivodeship}
            </DetailsItem>
            <DetailsItem md={4} xl={2} label="typ">
              {offer.type}
            </DetailsItem>
            <DetailsItem md={4} xl={2} label="Firma">
              {offer.company_name}
            </DetailsItem>
            <DetailsItem md={4} xl={2} label="Adres">
              {offer.company_address.street && (
                <p className="mb-0">
                  {offer.company_address.street}{" "}
                  {offer.company_address.street_number}
                </p>
              )}
              <p>
                {`${
                  offer.company_address.postal_code
                    ? offer.company_address.postal_code
                    : ""
                } ${offer.company_address.city}`}
              </p>
            </DetailsItem>
            <DetailsItem md={4} xl={2} label="kategoria">
              {offer.category}
            </DetailsItem>
            <DetailsItem md={4} xl={2} label="Data wygaśnięcia">
              {new Date(offer.expiration_date).toLocaleDateString(
                undefined,
                {}
              )}
            </DetailsItem>
          </Row>
          <Row>
            <DetailsItem label="Wynagrodzenie">
              {offer.salary_min} zł - {offer.salary_max} zł
            </DetailsItem>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <DetailsItem label="Opis" className="jobOfferDetails__description">
            {offer.description}
          </DetailsItem>
        </ListGroup.Item>
        {!actionTaken ? (
          <ListGroup.Item>
            <Row className="justify-content-center">
              <Button onClick={(e) => approveOffer(e)} variant="primary">
                Akceptuj
              </Button>
              <Button
                onClick={(e) => rejectOffer(e)}
                variant="danger"
                className="ml-3"
              >
                Odrzuć
              </Button>
              <Button
                onClick={() => setShowModal(true)}
                variant="danger"
                className="ml-3"
              >
                Usuń ofertę
              </Button>
            </Row>
          </ListGroup.Item>
        ) : null}
      </ListGroup>
    </Card.Body>
  );
};

export default OfferPosition;
