import React, { useState, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { getDocuments } from "Views/DocumentsEditor/DocumentsEditor.api";
import documents from "constants/documents";
import { ButtonToPDF } from "../";

const CheckBox = ({ checked, handleChange, disabled, handleLinkChange }) => {
  const { COOKIES_POLICY, GENERAL_POLICY } = documents;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [cookiesLink, setCookiesLink] = useState("/error");
  const [generalLink, setGeneralLink] = useState("/error");

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDocument = async () => {
      if (!abortController.signal.aborted) {
        setLoading(true);
      }
      try {
        const response = await getDocuments(abortController.signal);
        const cookiesLink = response.find(
          (document) => document.id === COOKIES_POLICY
        )?.document_url;
        const generalLink = response.find(
          (document) => document.id === GENERAL_POLICY
        )?.document_url;
        handleLinkChange(cookiesLink);
        if (cookiesLink === undefined) {
          setError(true);
        } else if (generalLink === undefined) {
          setError(true);
        }
        setCookiesLink(cookiesLink);
        setGeneralLink(generalLink);
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(true);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    };
    fetchDocument();

    return () => abortController.abort();
  }, [COOKIES_POLICY, GENERAL_POLICY, handleLinkChange]);

  const Regulations = useMemo(() => {
    return (
      <p className="d-flex align-items-center flex-wrap mb-0">
        Akceptuję{" "}
        <ButtonToPDF
          text="regulamin aplikacji"
          error={error}
          loading={loading}
          link={generalLink}
        />{" "}
        oraz{" "}
        <ButtonToPDF
          text="politykę prywatności cookies"
          error={error}
          loading={loading}
          link={cookiesLink}
        />
        *
      </p>
    );
  }, [cookiesLink, error, generalLink, loading]);

  return (
    <Form.Group controlId="formRegulationsCheck">
      <Form.Check
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        label={Regulations}
        required
        disabled={disabled}
        feedback="Musisz zaakceptować regulamin"
      />
    </Form.Group>
  );
};

export default CheckBox;
