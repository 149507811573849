import { userTypes } from "./userTypes";
import { staffTypes } from "./staffTypes";
import { paths } from "./paths";

const routesInfo = [
  {
    path: paths.DASHBOARD,
    exact: true,
    offline: true,
  },

  {
    path: paths.CV_CREATOR,
    exact: true,
  },
  {
    path: paths.CV_EDITOR,
    isPrivate: true,
    type: [userTypes.STANDARD],
    exact: true,
    userVerified: true,
  },
  {
    path: paths.REGISTER,
    exact: true,
  },
  {
    path: paths.REGISTER_ADMIN,
    exact: true,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.ADMIN_MANAGER],
    userVerified: true,
  },
  {
    path: paths.FOOTER,
    exact: true,
  },
  {
    path: paths.LOGIN,
    exact: true,
  },
  {
    path: paths.USER,
    isPrivate: true,
    type: undefined, // jeżeli jest undefined to znaczy że jest dostępne dla wszystkich typów konta
  },
  {
    path: paths.JOB_OFFERS,
    exact: true,
    type: undefined,
  },
  {
    path: paths.JOB_OFFER_DETAILS,
    exact: true,
    type: undefined,
  },
  {
    path: paths.OFFER_FORM,
    isPrivate: true,
    type: [userTypes.EMPLOYER, userTypes.STAFF],
    group: [staffTypes.GUEST],
    userVerified: true,
  },
  {
    path: paths.CV_APPROVAL,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.CV],
    userVerified: true,
  },
  {
    path: paths.MY_OFFERS,
    isPrivate: true,
    type: userTypes.EMPLOYER,
    userVerified: true,
  },
  {
    path: paths.CONTACT_PAGE,
    offline: true,
  },
  /*{
    path: paths.BLOG_POST,
    exact: true,
  },*/
  {
    path: paths.BLOG_FORM,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.BLOG_CREATOR, staffTypes.GUEST],
    exact: true,
    userVerified: true,
  },
  {
    path: paths.BLOG_EDIT,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.BLOG_CREATOR],
    exact: true,
    userVerified: true,
  },
  {
    path: paths.MY_CVS,
    isPrivate: true,
    type: userTypes.STANDARD,
    userVerified: true,
  },
  {
    path: paths.USER_APPROVAL,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.VERIFICATION],
    userVerified: true,
  },
  {
    path: paths.STEPS,
    type: undefined,
    exact: true,
  },
  {
    path: paths.CV_CORRECTION,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.CV],
  },
  {
    path: paths.CHATS,
    isPrivate: true,
    type: [userTypes.STANDARD, userTypes.EMPLOYER, userTypes.STAFF],
    group: [staffTypes.CHAT],
    exact: true,
    userVerified: true,
  },
  {
    path: paths.CHAT_DETAILS,
    isPrivate: true,
    type: [userTypes.STANDARD, userTypes.EMPLOYER, userTypes.STAFF],
    group: staffTypes.CHAT,
    exact: true,
    userVerified: true,
  },
  {
    path: paths.USER_LIST,
    isPrivate: true,
    type: userTypes.STAFF,
    exact: true,
    userVerified: true,
  },
  {
    path: paths.OFFER_APPROVAL,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.JOBS],
  },
  {
    path: paths.CHANGE_DATA,
    isPrivate: true,
    type: userTypes.STAFF,
    group: undefined,
  },
  {
    path: paths.PASSWORD_RESET,
    type: undefined,
    exact: true,
  },
  {
    path: paths.NEW_PASSWORD,
    type: undefined,
    exact: true,
  },
  {
    path: paths.BLOGS,
    group: undefined,
    exact: true,
  },
  {
    path: paths.BLOG,
    type: undefined,
    exact: true,
  },
  {
    path: paths.BLOG_POST,
    type: undefined,
    exact: true,
  },
  {
    path: paths.BLOG_SELECT,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.BLOG_MODERATOR],
  },
  {
    path: paths.BLOG_WORKSHOP,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.BLOG_MODERATOR, staffTypes.GUEST],
  },
  {
    path: paths.BLOG_WORKSHOP_EDIT,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.BLOG_MODERATOR],
  },
  {
    path: paths.ASK_EXPERT,
    type: undefined,
    exact: true,
  },
  {
    path: paths.ERROR,
    type: undefined,
    exact: true,
    offline: true,
  },
  {
    path: paths.JOB_OFFERS_ENUMS_CREATOR,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.JOBS],
    exact: true,
  },
  {
    path: paths.KEYS_GENERATOR,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.VERIFICATION],
  },
  { path: paths.WORKSHOPS, type: undefined, exact: true },
  {
    path: paths.WORKSHOP_POST,
    type: undefined,
    exact: true,
  },
  { path: paths.OFFLINE, offline: true, exact: true },
  {
    path: paths.APPLICATIONS,
    isPrivate: true,
    type: userTypes.STANDARD,
    exact: true,
    userVerified: true,
  },
  {
    path: paths.PROJECT,
    exact: true,
  },
  {
    path: paths.CONTACT_FOUNDATION,
    exact: true,
  },
  {
    path: paths.DOCUMENTS_EDITOR,
    exact: true,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.VERIFICATION],
  },
  {
    path: paths.PERMISSION_CHANGE,
    exact: true,
    isPrivate: true,
    type: userTypes.STAFF,
    group: [staffTypes.ADMIN_MANAGER],
  },
  { path: paths.CALENDAR },
  { path: paths.ACCEPT_USER },
  {
    path: paths.RESEND_EMAIL_VERIFICATION,
    exact: true,
    isPrivate: true,
    type: userTypes.STANDARD,
  },
  {
    path: paths.TERMS_ACCEPTED,
    exact: true,
  },
  { path: paths.REGULATIONS, exact: true },
];

export default routesInfo;
