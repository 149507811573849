import React, { useState, useContext, useEffect, useRef } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/pl";
import { Button, Card, Container, Form, Alert } from "react-bootstrap";
import {
  reserveSpace,
  deleteImages,
  postBlogPost,
  getPost,
  getAttachments,
  uploadPhoto,
  loadingMessage,
} from "./functions";
import { UserContext, AlertContext } from "context";
import {
  ImageAdapter,
  Header,
  HeaderPreview,
  PostTileCreator,
  Cover,
  PostSelects,
  LabelChooser,
} from "./components";
import { useHistory, useParams, useLocation } from "react-router-dom";
import proxy from "config/api";
import { paths } from "constants/paths";

const BlogPostCreator = () => {
  const [imgs, setImgs] = useState([]);
  const [prevHeader, setPrevHeader] = useState([]);
  const [prevCover, setPrevCover] = useState([]);
  const [post, setPost] = useState({ background_color: "#6699CC" });
  const [header, setHeader] = useState(null);
  const [cover, setCover] = useState(null);

  const history = useHistory();
  const postId = useParams();

  const location = useLocation();

  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));

  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [loadingHeader, setLoadingHeader] = useState(false);
  const [loadingCover, setLoadingCover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [error, setError] = useState(false);

  const isPost = location.pathname.includes(paths.BLOG_FORM);

  useEffect(() => {
    if (isPost && !postId) {
      setPost((prevState) => ({ ...prevState, can_apply: true }));
    }
  }, [isPost, postId]);

  //pobieranie attachmentów przy edycji żeby można je było czyścić
  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const res = await getAttachments(user.token, postId.id, isPost);
        setImgs(
          res
            .filter(({ is_header }) => !is_header)
            .filter(({ is_cover }) => !is_cover)
        );
        setPrevCover(res.filter(({ is_cover }) => is_cover));
        setPrevHeader(res.filter(({ is_header }) => is_header));
      } catch (err) {}
    };
    if (postId.id) {
      fetchAttachments();
    }
  }, [isPost, postId, user.token]);

  //pobieranie danych posta przy edycji
  useEffect(() => {
    const getPosts = async () => {
      setIsPostLoading(true);
      try {
        const res = await getPost(user.token, postId.id, isPost);
        setPost(res);
      } catch (err) {
        setError(true);
      }
      setIsPostLoading(false);
    };

    if (postId.id) {
      getPosts();
    }
  }, [isPost, postId.id, user.token]);

  const deleteImagesHandler = async () => {
    const headersToDelete = [];
    // tablica bo bezpieczniejszaj jest

    prevHeader.forEach((prevHeader) => {
      return header ||
        `${proxy.plainFiles}${prevHeader.attachment_url}` !== post.header
        ? headersToDelete.push(prevHeader.id)
        : null;
    });

    prevCover.forEach((prevCover) => {
      return cover ||
        `${proxy.plainFiles}${prevCover.attachment_url}` !== post.cover
        ? headersToDelete.push(prevCover.id)
        : null;
    });

    try {
      await deleteImages(
        user.token,
        post.content,
        imgs,
        headersToDelete,
        isPost
      );
    } catch (err) {
      alertC.current.showAlert(err.message);
    }
  };

  const sendHeader = async (file, postToSend) => {
    setLoadingHeader(true);
    await sendTypePhoto("is_header", file, postToSend);
    setLoadingHeader(false);
  };

  const sendCover = async (file, postToSend) => {
    setLoadingCover(true);
    await sendTypePhoto("is_cover", cover, postToSend);
    setLoadingCover(false);
  };

  const sendTypePhoto = async (type, file, postToSend) => {
    if (file) {
      try {
        const res = await uploadPhoto(
          post.id,
          file,
          user.token,
          null,
          isPost,
          type
        );
        if (type === "is_header") {
          postToSend.header = `${proxy.plainFiles}${res.attachment_url}`;
        } else {
          postToSend.cover = `${proxy.plainFiles}${res.attachment_url}`;
        }
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
    }
  };

  const submitPost = async () => {
    setLoading(true);
    const postToSend = { ...post };

    await deleteImagesHandler();
    await sendHeader(header, postToSend);
    await sendCover(cover, postToSend);

    try {
      await postBlogPost(postToSend, user.token, post.id, postId.id, isPost);
      alertC.current.showAlert(
        postId.id ? "Pomyślnie edytowano post." : "Pomyślnie dodano post.",
        "success"
      );
      setLoading(false);
      if (isPost) {
        history.push(`/blogs/post/${post.id}`);
      } else {
        history.push(`/workshops/post/${post.id}`);
      }
    } catch (err) {
      setLoading(false);
      alertC.current.showAlert(err.message);
    }
  };

  function MyCustomUploadAdapterPlugin(editor) {
    const getId = async () => {
      try {
        let id = postId.id;
        if (!postId.id) {
          const res = await reserveSpace(user.token, isPost);
          id = res.id;
        }

        setPost((prevState) => ({ ...prevState, id }));
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return new ImageAdapter(
            loader,
            id,
            user.token,
            setImgs,
            setIsLoadingImg,
            isPost
          );
        };
      } catch (err) {}
    };
    getId();
  }

  const message = error ? (
    <Alert variant="danger">Nie udało się pobrać posta.</Alert>
  ) : isPostLoading ? (
    <Alert variant="info">Trwa ładowanie posta...</Alert>
  ) : null;

  return (
    <Container>
      <Card className="creator__container">
        <Card.Header className="creator__header">
          {message}
          <Cover setCover={setCover} condition={post.cover || cover} />
          <Header
            setPhoto={setHeader}
            condition={post.header || header}
            setHeader={(header) =>
              setPost((prevState) => ({ ...prevState, header }))
            }
          />
        </Card.Header>
        <Card.Body className="p-0 creator__body">
          <PostTileCreator
            post={post}
            token={user.token}
            cover={cover}
            color={post.background_color}
            setColor={(background_color) =>
              setPost((prevState) => ({ ...prevState, background_color }))
            }
            backgroundUrl={post.background}
            setBackgroundUrl={(background) =>
              setPost((prevState) => ({ ...prevState, background }))
            }
          />

          {isPost ? (
            <PostSelects
              setActiveTopic={(topic) =>
                setPost((prevState) => ({ ...prevState, topic }))
              }
              token={user.token}
              category={post.category || postId.category}
              activeTopic={post.topic}
              setCategoryActive={(category) =>
                setPost((prevState) => ({
                  ...prevState,
                  category,
                  topic: null,
                }))
              }
            />
          ) : (
            <LabelChooser token={user.token} post={post} setPost={setPost} />
          )}

          <div className="d-flex flex-column align-items-center mb-3">
            <h4>Tytuł posta</h4>
            <Form.Control
              value={post.title || ""}
              onChange={(e) => {
                const title = e.target.value;
                setPost((prevState) => ({
                  ...prevState,
                  title,
                }));
              }}
              className="blogCreator__title"
              placeholder="Podaj tytuł posta"
            />
          </div>
          <div className="blogCreator__editor">
            {(post?.header || header) && (
              <HeaderPreview url={post.header} header={header} />
            )}
            <CKEditor
              config={{
                language: "pl",
                extraPlugins: [MyCustomUploadAdapterPlugin],
              }}
              onChange={(e, editor) => {
                setPost((prevState) => ({
                  ...prevState,
                  content: editor.getData(),
                }));
              }}
              className="text-justify"
              editor={ClassicEditor}
              data={post.content}
            />
          </div>
        </Card.Body>
        <Card.Footer>
          {loadingMessage(isLoadingImg, loadingHeader, loadingCover, loading)}
          <Button disabled={loading || isLoadingImg} onClick={submitPost}>
            {loading ? "Trwa przesyłanie..." : "Prześlij post"}
          </Button>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default BlogPostCreator;
