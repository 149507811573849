import React, { useState, useEffect, useRef, useContext } from "react";
import { Badge, Button } from "react-bootstrap";
import { EventForm } from "../";
import { DeletionModal } from "components";
import { deleteEvent } from "Views/Calendar/Calendar.api";
import { AlertContext, UserContext } from "context";
import { staffTypes } from "constants/staffTypes";
import { userTypes } from "constants/userTypes";

const Event = ({ event, labels, handleRefresh }) => {
  const { name, id, time_start, time_end, label, url } = event;
  const [show, setShow] = useState(false);

  const alertC = useRef(useContext(AlertContext));
  const user = useContext(UserContext);

  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleDelete = async () => {
      try {
        await deleteEvent(user.token, id);
        alertC.current.showAlert("Poprawnie usunięto wydarzenie.", "success");
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDeletionConfirmed(false);
      handleRefresh();
    };

    if (deletionConfirmed) handleDelete();
  }, [deletionConfirmed, handleRefresh, id, user.token]);

  return (
    <>
      <div className="calendarView__event">
        <Badge
          variant="primary"
          className="calendar__label calendar__label--view"
          key={id}
          style={{ backgroundColor: label?.color }}
        >
          {label?.name}
        </Badge>
        <h5>
          {name}{" "}
          {user?.type === userTypes.STAFF &&
            user?.data?.group_type.includes(staffTypes.BLOG_MODERATOR) && (
              <>
                <Button onClick={() => setShow(true)} className="mr-2">
                  Edytuj
                </Button>
                <Button variant="danger" onClick={() => setShowModal(true)}>
                  X
                </Button>
              </>
            )}
        </h5>
        <p className="mb-0">{`${time_start} - ${time_end}`}</p>
        <a target="_blank" rel="noopener noreferrer" href={url}>
          Przejdź do wydarzenia
        </a>
      </div>
      <EventForm
        show={show}
        handleClose={() => setShow(false)}
        labels={labels}
        event={event}
        handleRefresh={handleRefresh}
      />
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        question={"Czy na pewno chcesz usunąć te wydarzenie?"}
        cancelLabel={"Nie, zostaw"}
        confirmLabel={"Tak, usuń"}
        delConfirmed={setDeletionConfirmed}
      />
    </>
  );
};

export default Event;
