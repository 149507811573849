import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import kulczyk from "assets/footer/kulczyk.svg";
import pw from "assets/footer/politechnika.svg";
import oneday from "assets/footer/oneDay.svg";

import yt from "assets/footer/youtube.svg";
import tt from "assets/footer/twitter.svg";
import fb from "assets/footer/facebook.svg";
import ig from "assets/footer/instagram.svg";

const Footer = () => {
  return (
    <Container fluid>
      <Row className="footer-logo-row">
        <Col className="foooter__col">
          <p className="footer__text">Pomysłodawca, twórca treści</p>
          <a
            href="https://www.oneday.com.pl/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__button w-100"
          >
            <img className="footer-logo-icon" src={oneday} alt={"oneday"} />
          </a>
        </Col>
        <Col className="foooter__col">
          <p className="footer__text">Twórca technologiczny strony</p>
          <a
            href="https://www.pw.edu.pl/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__button w-100"
          >
            <img
              className="footer-logo-icon"
              src={pw}
              alt={"Politechnika Warszawska"}
            />
          </a>
        </Col>
        <Col className="foooter__col">
          <p className="footer__text">Partner</p>
          <a
            href="https://kulczykfoundation.org.pl/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__button w-100"
          >
            <img
              className="footer-logo-icon"
              src={kulczyk}
              alt={"Kulczyk foundation"}
            />
          </a>
        </Col>
      </Row>
      <Row className="footer-media-row">
        <a
          href="https://www.facebook.com/Usamodzielnieni-110914023943342"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__button"
        >
          <img
            className="footer-media-icon footer-media-icon--small"
            src={fb}
            alt={"Facebook"}
          />
        </a>
        <a
          href="https://www.instagram.com/usamodzielnieni.pl"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__button "
        >
          <img
            className="footer-media-icon footer-media-icon--small"
            src={ig}
            alt={"Instagram"}
          />
        </a>
        <a
          href="https://twitter.com/usamodzielnieni"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__button "
        >
          <img
            className="footer-media-icon footer-media-icon--small"
            src={tt}
            alt={"Twitter"}
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCbKE3BfzmHGfXbB9nmmswYw"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__button"
        >
          <img
            className="footer-media-icon footer-media-icon--small"
            src={yt}
            alt={"YouTube"}
          />
        </a>
      </Row>
    </Container>
  );
};

export default Footer;
