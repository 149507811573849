import React from "react";
import proxy from "config/api";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const KeyObject = ({ item, handleDelete, handleEdit }) => {
  return (
    <div className="d-flex align-items-center mb-2">
      {item.logo_url && (
        <img
          className="keyObject__logo"
          alt="logo klucza"
          src={`${proxy.plainFiles}${item.logo_url}`}
        />
      )}
      <p className="d-inline-block m-0">{item.name}</p>
      <Button
        variant="danger"
        className="mx-1"
        onClick={() => handleDelete(item.id)}
      >
        Usuń klucz
      </Button>
      <Button onClick={() => handleEdit(item.id)}>Edytuj klucz</Button>
    </div>
  );
};

KeyObject.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    logo_url: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default KeyObject;
