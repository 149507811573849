import { useState, useEffect } from "react";

function useQuery(dependency, fn) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fn();
        setData(response);
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    };
    fetchData();
  }, dependency);

  return [data, error, loading];
}

export default useQuery;
