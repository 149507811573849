import React, { useState, useRef, useContext } from "react";
import { Button } from "react-bootstrap";
import { deleteOffer } from "../../functions/deleteOffer";
import { AlertContext } from "context";
import { DeletionModal } from "components";
import { changeStateOfOffer } from "Views/MyOffersPage/functions/apiCalls";

const RemoveOffer = ({ id, user }) => {
  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);

  const alertC = useRef(useContext(AlertContext));

  const handleDeleteOffer = async () => {
    setDeletionConfirmed(false);
    try {
      await deleteOffer(id, user.token);
      alertC.current.showAlert("Pomyślnie usunięto ofertę.", "success");
    } catch (err) {
      alertC.current.showAlert("Wystąpił błąd przy usuwaniu oferty.");
    }
  };

  const handleOnClick = () => {
    setShowModal(true);
  };

  const handleArchive = async (id, type) => {
    try {
      const res = await changeStateOfOffer(user.token, type, id);
      alertC.current.showAlert(res.message, "success");
    } catch (err) {
      alertC.current.showAlert(err.message);
    }
  };

  if (deletionConfirmed) handleDeleteOffer();

  return (
    <div className="removeOffer">
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDeletionConfirmed}
        question={"Czy na pewno chcesz usunąć tę ofertę?"}
      />
      <Button variant="danger" onClick={handleOnClick} className="mr-1">
        Usuń ofertę
      </Button>
      <Button onClick={() => handleArchive(id, "archive")}>
        Archiwizuj ofertę
      </Button>
    </div>
  );
};

export default RemoveOffer;
