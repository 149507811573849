import { staffTypes } from "constants/staffTypes";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import React from "react";

export const NewBlogButton = ({ user }) => {
  if (user?.data?.group_type?.includes(staffTypes.BLOG_CREATOR)) {
    return (
      <LinkContainer to={"/blog/newPost"}>
        <Button className="blogs-edit-button">
          <span className="align-self-center">DODAJ NOWY POST</span>
        </Button>
      </LinkContainer>
    );
  } else return null;
};
