import React from "react";
import { Col, Row } from "react-bootstrap";
import DetailsItem from "components/DetailsItem/DetailsItem";
import ButtonsContainer from "./ButtonsContainer";

const UserInfo = ({
  user,
  setUser,
  deleteUser,
  mapType,
  mapStatus,
  ...rest
}) => {
  return (
    <Row {...rest}>
      <Col>
        <Row className="m-0 d-flex align-items-center">
          <h5 className="m-0 mr-3">{`${user.username} (${user.firstName} ${user.lastName})`}</h5>

          {user.newsletter && <p className="text-success mb-0">newsletter</p>}
        </Row>
        <Row className="mt-2">
          <DetailsItem md="4" xl="3" label="Adres email">
            {user.email}
          </DetailsItem>
          <DetailsItem md="4" xl="3" label="Typ">
            {mapType(user.type)}
          </DetailsItem>
          <DetailsItem md="4" xl="3" label="Data rejestracji">
            {user.dateJoined.toLocaleDateString?.()}
          </DetailsItem>
          <DetailsItem md="4" xl="3" label="Status">
            {mapStatus(user.status)}
          </DetailsItem>
        </Row>
        <ButtonsContainer
          user={user}
          setUser={setUser}
          deleteUser={deleteUser}
        />
      </Col>
    </Row>
  );
};

export default UserInfo;
