import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const sendKey = async (token, keyObject, id) => {
  const url = id ? `${proxy.external}${id}/` : `${proxy.external}new/`;
  const headers = {
    Authorization: `Token ${token}`,
  };
  const method = id ? "PATCH" : "POST";

  const formData = new FormData();

  formData.append("name", keyObject.name);
  if (keyObject.logo !== undefined) {
    formData.append("logo", keyObject.logo);
  }

  const res = await fetch(url, {
    headers,
    method,
    body: formData,
  });

  if (res.status === 201 && !id) {
    return res.json();
  } else if (res.status === 200 && id) {
    return true;
  }
  throw new Error(await errorMessageText(res));
};

export const getKeys = async (token, signal) => {
  const url = `${proxy.external}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  const method = "GET";

  const res = await fetch(url, { signal, headers, method });
  if (res.status === 200) {
    return res.json();
  }
  throw new Error(await errorMessageText(res));
};

export const deleteKey = async (token, id) => {
  const url = `${proxy.external}${id}/`;
  const headers = {
    Authorization: `Token ${token}`,
  };
  const method = "DELETE";

  const res = await fetch(url, { headers, method });
  if (res.status !== 204) {
    throw new Error(await errorMessageText(res));
  }
};
