import React, { useEffect, useContext, useState, useRef } from "react";
import { Container, Card, Form, Button, Alert } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { getUserDetails } from "Views/UserApprovalPage/functions/apiCalls";
import { UserContext, AlertContext } from "context";
import { adminGroup } from "constants/roles";
import { staffTypes } from "constants/staffTypes";
import { changePermissions } from "./PermissionChange.api";
import { paths } from "constants/paths";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const PermissionChange = () => {
  const { id } = useParams();
  const history = useHistory();
  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [role, setRole] = useState("");
  const [groupTypes, setGroupTypes] = useState([]);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    const fetchUserData = async () => {
      if (!abortController.signal.aborted) {
        setLoading(true);
      }
      try {
        const data = await getUserDetails(
          user.token,
          id,
          abortController.signal
        );
        if (!abortController.signal.aborted) {
          setRole(data.role);
          setUsername(`${data.first_name} ${data.last_name}`);
          setGroupTypes(data.group_type);
        }
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(err.message);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    };
    fetchUserData();
    return () => abortController.abort();
  }, [id, user.token]);

  const handleChange = (name) => {
    if (groupTypes.includes(name)) {
      setGroupTypes((prevState) => {
        const newArray = [...prevState];
        const indexOfItem = newArray.indexOf(name);
        newArray.splice(indexOfItem, 1);
        return newArray;
      });
    } else {
      setGroupTypes((prevState) => [...prevState, name]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let data = {
        group_type: groupTypes,
      };
      if (role) {
        data.role = role;
      }
      await changePermissions(user.token, id, data);
      alertC.current.showAlert("Pomyslnie edytowano uprawnienia", "success");
      setLoading(false);
      history.push(paths.USER_LIST);
    } catch (err) {
      setLoading(false);
      alertC.current.showAlert(err.message);
    }
  };

  const message = error ? (
    <Alert variant="danger">{error}</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : null;

  return (
    <Container>
      <Card>
        <Card.Header className="messagesList__header">
          <Button
            variant="outline-warning"
            className="messagesList__close ml-2"
            onClick={() => history.push(paths.USER_LIST)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <h1>{`Zmiana uprawnień użytkownika ${username}`}</h1>
        </Card.Header>
        <Card.Body as={Form} onSubmit={handleSubmit}>
          <Form.Group className="register_account_type">
            <Form.Label>{"Uprawnienia nowego administratora:"}</Form.Label>
            {adminGroup.map((item) => (
              <Form.Check
                data-testid="typeSelector"
                className="register_radio_type"
                type="checkbox"
                label={item.placeholder}
                checked={groupTypes?.includes(item.name)}
                onChange={() => handleChange(item.name)}
                key={item.name}
                name={item.name}
              />
            ))}
            <Form.Control
              type="text"
              disabled={!groupTypes.includes(staffTypes.CHAT)}
              placeholder="Pełniona rola na czacie (opcjonalne)"
              value={role}
              className="mt-2"
              onChange={(e) => setRole(e.target.value)}
            />
          </Form.Group>
          {message}
          <Button disabled={loading} type="submit">
            Prześlij
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PermissionChange;
