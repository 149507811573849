import proxy from "config/api";
import { errorMessage } from "constants/other";

export const getDocuments = async (signal) => {
  const res = await fetch(`${proxy.contactFoundation}documents/`, {
    method: "GET",
    signal,
    headers: {
      "Content-Type": "application/json",
      Origin: null,
    },
  });
  if (res.status !== 200) {
    throw new Error(await errorMessage(res));
  } else {
    return res.json();
  }
};

export const deleteDocument = async (token, id) => {
  const url = `${proxy.contactFoundation}document/${id}/`;

  const headers = {
    "Content-Type": "application/json",
    Origin: null,
    Authorization: `Token ${token}`,
  };

  const res = await fetch(url, {
    method: "DELETE",
    headers,
  });
  if (res.status !== 204) {
    throw new Error(await errorMessage(res));
  }
};

export const sendDocument = async (token, documentObj, idDoc) => {
  const url = `${proxy.contactFoundation}document/${
    idDoc ? `${idDoc}/` : "new/"
  }`;

  const { document, id } = documentObj;

  const formData = new FormData();
  formData.append("id", id);
  formData.append("document", document);

  const headers = {
    Origin: null,
    Authorization: `Token ${token}`,
  };

  const res = await fetch(url, {
    method: idDoc ? "PATCH" : "POST",
    headers,
    body: formData,
  });

  if (res.status !== 201 && !idDoc) {
    throw new Error(await errorMessage(res));
  }
  if (res.status !== 200 && idDoc) {
    throw new Error(await errorMessage(res));
  } else {
    return true;
  }
};
