import proxy from "config/api";
import { errorMessageText } from "constants/other.js";

const changeUrl = async (token, video) => {
  const urlChange = `${proxy.videos}video/${video.name}/`;
  const headers = {
    Authorization: "token " + token,
    "Content-Type": "application/json",
  };
  const res = await fetch(urlChange, {
    method: "PUT",
    body: JSON.stringify(video),
    headers,
  });
  if (res.status !== 200) {
    throw new Error(await errorMessageText(res));
  }
  return res.status;
};

const getVideos = async (signal) => {
  const urlChange = `${proxy.videos}all-videos/`;
  let headers = {
    "Content-Type": "application/json",
  };
  const res = await fetch(urlChange, {
    signal,
    method: "GET",
    headers,
  });

  if (res.status !== 200) {
    throw new Error(await errorMessageText(res));
  }
  return res.json();
};

const deleteVideo = async (token, id) => {
  const urlChange = `${proxy.videos}video/${id}/`;
  let headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };
  const res = await fetch(urlChange, {
    method: "DELETE",
    headers,
  });

  if (res.status !== 204) {
    throw new Error(await errorMessageText(res));
  }
  return true;
};

const addVideo = async (token, video) => {
  const urlChange = `${proxy.videos}new-video/`;
  let headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };
  const res = await fetch(urlChange, {
    method: "POST",
    headers,
    body: JSON.stringify(video),
  });

  if (res.status !== 201) {
    throw new Error(await errorMessageText(res));
  }
  return true;
};

export { changeUrl, getVideos, deleteVideo, addVideo };
