import React from "react";
import { getMonthId, months } from "constants/calendarDays";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";

const CalendarNavigation = ({ setMonth, setYear, month, year }) => {
  const handleMoveYear = (direction) => {
    if (direction === 1) {
      setYear((prevYear) => prevYear + 1);
    } else {
      setYear((prevYear) => prevYear - 1);
    }
  };

  const handleMoveMonth = (direction) => {
    const monthId = getMonthId(month);
    if (direction === 1) {
      if (monthId === 11) {
        setMonth(months[0]);
        setYear((prevYear) => prevYear + 1);
      } else {
        setMonth(months[monthId + 1]);
      }
    } else {
      if (monthId === 0) {
        setMonth(months[11]);
        setYear((prevYear) => prevYear - 1);
      } else {
        setMonth(months[monthId - 1]);
      }
    }
  };

  return (
    <Card.Header className="d-flex align-items-center justify-content-center">
      <Button variant="info" onClick={() => handleMoveYear(-1)}>
        <FontAwesomeIcon icon={faAngleDoubleLeft} />
      </Button>
      <Button variant="info" onClick={() => handleMoveMonth(-1)}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </Button>
      <h3 className="mx-2 mb-0 d-inline-block">{`${month} ${year}`}</h3>
      <Button variant="info" onClick={() => handleMoveMonth(1)}>
        <FontAwesomeIcon icon={faAngleRight} />
      </Button>
      <Button variant="info" onClick={() => handleMoveYear(1)}>
        <FontAwesomeIcon icon={faAngleDoubleRight} />
      </Button>
    </Card.Header>
  );
};

export default CalendarNavigation;
