import React from "react";
import { ButtonToPDF } from "../";

const RegistrationInfo = ({ link }) => {
  return (
    <small className="text-muted">
      Administratorem danych osobowych jest Fundacja One Day z siedzibą w
      Warszawie. Twoje dane będą przetwarzane m.in. w celach świadczenia Ci
      usług. Możesz skorzystać ze swoich praw takich jak m.in. prawo do dostępu
      i sprostowania Twoich danych osobowych. Więcej informacji znajdziesz w
      <ButtonToPDF small text={"Polityce Prywatności i Cookies"} link={link} />.
    </small>
  );
};

export default RegistrationInfo;
