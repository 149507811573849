import React, { useState, useEffect, useRef, useContext } from "react";
import { Card, Button, Alert, Accordion } from "react-bootstrap";

import { DeletionModal } from "components";
import { deleteType } from "../../functions";
import { AlertContext } from "context";
import { ListItem } from "../";

const TreeOfSelectors = ({ topics, refresh, loadingObj, errorObj, token }) => {
  const alertC = useRef(useContext(AlertContext));
  // category
  const [showModalCat, setShowModalCat] = useState(false);
  const [deletionConfirmedCat, setDeletionConfirmedCat] = useState(false);

  const [idDel, setIdDel] = useState(-1);

  const handleDelModalCat = (id) => {
    setShowModalCat(true);
    setIdDel(id);
  };

  const { error, errorC } = errorObj;
  const { loading, loadingC } = loadingObj;

  const message =
    error || errorC ? (
      <Alert variant="danger">Nie udało się załadować listy</Alert>
    ) : loading || loadingC ? (
      <Alert variant="info">Ładowanie...</Alert>
    ) : topics.length === 0 ? (
      <Alert variant="info">Brak kategorii.</Alert>
    ) : null;

  useEffect(() => {
    const handleDelete = async (type, id) => {
      try {
        await deleteType(token, id, type);
        alertC.current.showAlert("Poprawnie usunięto kategorię.", "success");
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDeletionConfirmedCat(false);
      refresh((prevState) => !prevState);
    };

    if (deletionConfirmedCat) handleDelete("category", idDel);
  }, [deletionConfirmedCat, idDel, refresh, token]);

  return (
    <Accordion>
      <DeletionModal
        show={showModalCat}
        setShow={setShowModalCat}
        delConfirmed={setDeletionConfirmedCat}
        question={"Czy na pewno chcesz usunąć tą kategorię?"}
      />

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h4>Lista kategoriami wraz z tematami</h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Accordion>
            <Card.Body className="px-0">
              {message ||
                topics.map((object, i) => (
                  <ListItem
                    object={object}
                    deleteCat={handleDelModalCat}
                    refresh={refresh}
                    categories={topics.map(({ category }) => category)}
                    i={i}
                    key={object.category.id}
                  />
                ))}
            </Card.Body>
          </Accordion>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default TreeOfSelectors;
