import React, { useState, useRef, useContext } from "react";
import { Card, Form, Button, Accordion } from "react-bootstrap";
import { FormGroup } from "components";
import { AlertContext } from "context";
import { sendLabel } from "../../functions";

const LabelCreator = ({ token, data, refresh }) => {
  const [year, setYear] = useState(data?.year || "");
  const [description, setDescription] = useState(data?.description || "");

  const alertC = useRef(useContext(AlertContext));

  const [disable, setDisable] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    try {
      await sendLabel(token, { year, description }, data?.id);
      alertC.current.showAlert(
        data
          ? "Pomyślnie edytowano rok warsztatów."
          : "Pomyślnie dodano nowy rok warsztatów.",
        "success"
      );
      refresh((prevState) => !prevState);
    } catch (err) {
      alertC.current.showAlert(err.message);
    }
    setDisable(false);
  };

  return (
    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h4>{data ? "Edytuj rok warsztatów" : "Dodaj rok warsztatów"}</h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body as={Form} onSubmit={handleSubmit}>
            <FormGroup
              header="Podaj rok"
              type="number"
              val={year}
              setVal={setYear}
              length={{ min: 1 }}
              id="label_year"
            />
            <FormGroup
              header="Podaj opis"
              type="textarea"
              val={description}
              setVal={setDescription}
              length={{ min: 1 }}
              id="description"
            />
            <Button disabled={disable} type="submit">
              {disable ? "Trwa dodawanie..." : "Prześlij"}
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default LabelCreator;
