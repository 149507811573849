import React from "react";
import { VideoField } from "components";
import tv from "assets/tv.png";
import leftShape from "assets/left_tv.svg";
import rightShape from "assets/right_tv.svg";

const TvContainer = () => {
  return (
    <div className="tvContainer">
      <div className="tvContainer__shapes">
        <div className="tvContainer__shapes__left">
          <img
            alt=""
            className="shapes__image shapes__image--left"
            src={leftShape}
          />
        </div>
        <div className="tvContainer__shapes__right">
          <img
            alt=""
            className="shapes__image shapes__image--rigth"
            src={rightShape}
          />
        </div>
      </div>
      <div className="tvContainer__tv">
        <img alt="" className="tvContainer__tv__img" src={tv} />
        <div className="tvContainer__tv__video__container">
          <div className="tvContainer__tv__video">
            <div className="tvContainer__tv__video__space">
              <VideoField id="main" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TvContainer;
