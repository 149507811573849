import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import background from "assets/oferty.jpg";
import qs from "query-string";
import { UserContext } from "context";
import { JobOfferInfo, Filter } from "./_components";
import proxy from "config/api";
import { Pagination } from "components";

const getOffers = async (filters, signal) => {
  const {
    page,
    pageSize,
    voivodeship,
    minExpirationDate,
    category,
    type,
    city,
    offer_name,
  } = filters;
  const cityQ = city ? `&city=${city}` : "";
  const voivodeshipQ = voivodeship ? `&voivodeship=${voivodeship}` : "";
  const categoryQ = category ? `&categories=${category}` : "";
  const typeQ = type ? `&type=${type}` : "";
  const nameQ = offer_name ? `&offer_name=${offer_name}` : "";

  const expirationDateQ = minExpirationDate
    ? `&min_expiration_date=${minExpirationDate}`
    : "";
  const query = `?page=${page}&page_size=${pageSize}${voivodeshipQ}${expirationDateQ}${categoryQ}${typeQ}${cityQ}${nameQ}`;
  const url = proxy.job + "job-offers/" + query;
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers, signal });
  if (response.status === 200) {
    return response.json().then((res) => {
      return mapGetOffersRes(res);
    });
  } else {
    throw response.status;
  }
};

const mapGetOffersRes = (res) => ({
  offers: res.results.map((offer) => ({
    id: offer.id,
    title: offer.offer_name,
    companyName: offer.company_name,
    companyAddress: offer.company_address,
    voivodeship: offer.voivodeship,
    expirationDate: offer.expiration_date,
    description: offer.description,
    companyLogo: offer.company_logo,
    salaryMin: offer.salary_min,
    salaryMax: offer.salary_max,
    offer_image: offer.offer_image,
  })),
  count: res.count,
});

const JobOffersPage = (props) => {
  const [offers, setOffers] = useState([]);
  const [count, setCount] = useState(0);
  const [isOffersLoading, setIsOffersLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 10,
    city: qs.parseUrl(props.location.search, { parseNumbers: true })?.query
      ?.city,
  });
  const [disabled, setDisabled] = useState(false);
  const [err, setErr] = useState(false);
  const user = useContext(UserContext);
  const scrollToElement = useRef(null);
  const executeScroll = () => scrollToElement.current.scrollIntoView();

  const queryParams = qs.parse(props.location.search, { parseNumbers: true });
  if (
    typeof queryParams.page === "number" &&
    queryParams.page !== filters.page
  ) {
    setFilters({ ...filters, page: queryParams.page });
  }

  useEffect(() => {
    setDisabled(true);
    const abortController = new AbortController();
    const loadOffers = async () => {
      setIsOffersLoading(true);
      let res;
      executeScroll();
      try {
        res = await getOffers(filters, abortController.signal);
      } catch (e) {
        if (!abortController.signal.aborted) {
          res = { offers: [], count: 0 };
          setErr(true);
        }
      }
      if (!abortController.signal.aborted) {
        setOffers(res.offers);
        setCount(res.count);
        setIsOffersLoading(false);
        setDisabled(false);
      }
    };
    loadOffers();
    return () => abortController.abort();
  }, [filters]);

  const msg = isOffersLoading ? (
    <Alert variant="info" className="mt-3">
      Ładowanie ofert...
    </Alert>
  ) : err ? (
    <Alert variant="danger" className="mt-3">
      Wystąpił błąd podczas ładowania ofert.
    </Alert>
  ) : (
    offers.length === 0 && (
      /*<Alert variant="info" className="mt-3">
        Brak ofert spełniających podane wymagania.
      </Alert>*/
      <div className="job-offers-filter mt-3 mb-5 w-100 p-3 text-align-center">
        Brak ofert spełniających podane wymagania.
      </div>
    )
  );
  return (
    <Container className="font-only job-offers-container">
      <img src={background} alt="jobOffersHeader" />
      <div className="mt-3">
        <p className="descriptions">
          W tej zakładce czekają na Ciebie oferty pracy, praktyk oraz stażu.
          Jesteśmy pewni, że znajdziesz tu coś odpowiedniego dla siebie.
          Pracodawca, do którego wyślesz CV, będzie wiedział, że korzystasz z
          naszej aplikacji!
        </p>
        <div ref={scrollToElement}></div>
        <Filter
          setFilters={setFilters}
          count={count}
          disabled={disabled}
          filtersMain={filters}
        />

        {offers.map((offer) => (
          <JobOfferInfo offer={offer} context={user} key={offer.id} />
        ))}

        {offers.length > 0 ? (
          <Pagination
            current={filters.page}
            max={Math.ceil(count / filters.pageSize)}
          />
        ) : null}
        {msg ? <>{msg}</> : null}
      </div>
    </Container>
  );
};

export default withRouter(JobOffersPage);
