import proxy from "config/api";
import { errorMessage, errorMessageText } from "constants/other";

const sendData = async (offer, token, id) => {
  const url = `${proxy.job}job-offer/${id ? `${id}/` : ""}`;
  const res = await fetch(url, {
    method: id ? "PUT" : "POST",
    body: JSON.stringify(offer),
    headers: {
      "Content-Type": "application/json",
      Origin: null,
      Authorization: `Token ${token}`,
    },
  });

  if ((res.status !== 201 && !id) || (res.status !== 200 && id)) {
    throw new Error(await errorMessageText(res));
  }
  if (res.status === 201 && !id) return res.json();
  else return true;
};

const sendPhoto = async (token, id, photo) => {
  const formData = new FormData();
  formData.append("file", photo, photo.name);

  const url = `${proxy.job}job-offer/${id}/image/`;
  const headers = {
    Authorization: "Token " + token,
  };

  const response = await fetch(url, {
    method: "POST",
    body: formData,
    headers,
  });

  if (response.status !== 200) {
    throw response.status;
  }

  return await response.json();
};

const getCategories = async (signal) => {
  const urlCategories = proxy.job + "enums/categories/";
  const res = await fetch(urlCategories, {
    signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json",

      Origin: null,
    },
  });
  if (res.status !== 200) {
    throw Error("getCategories");
  }
  return res.json().then((res) => res.categories);
};

const getTypes = async (signal) => {
  const urlTypes = proxy.job + "enums/types/";

  const res = await fetch(urlTypes, {
    method: "GET",
    signal,
    headers: {
      "Content-Type": "application/json",
      Origin: null,
    },
  });
  if (res.status !== 200) {
    throw Error("getTypes");
  }
  return res.json().then((res) => res.offer_types);
};

const getSelects = async (signal) => {
  const [categories, types] = await Promise.all([
    getCategories(signal),
    getTypes(signal),
  ]);
  return { categories, types };
};

const getOffer = async (token, id, signal) => {
  const url = `${proxy.job}job-offer/${id}/`;

  const res = await fetch(url, {
    method: "GET",
    signal,
    headers: {
      "Content-Type": "application/json",
      Origin: null,
      Authorization: "Token " + token,
    },
  });
  if (res.status !== 200) {
    throw new Error(await errorMessage(res));
  }
  return res.json().then((res) => ({
    ...res,
    expiration_date: new Date(res.expiration_date),
  }));
};

export { sendData, getSelects, getCategories, getTypes, getOffer, sendPhoto };
