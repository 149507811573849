import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const getWorkshopPost = async (id) => {
  let url = proxy.plain + "/workshops/" + id + "/";
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw response.status;
  }
};

export const removeWorkshop = async (id, token) => {
  let url = proxy.plain + "/workshops/" + id + "/";
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + token,
  };

  const response = await fetch(url, { method: "DELETE", headers });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const postWorkshopComment = async (id, token, comment) => {
  let url = proxy.plain + "/workshops/" + id + "/comment/";
  let data = {
    content: comment,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + token,
  };

  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  if (response.status === 201) {
    return await response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const removeWorkshopComment = async (id, token) => {
  let url = proxy.plain + "/workshops/comments/" + id + "/";
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + token,
  };

  const response = await fetch(url, { method: "DELETE", headers });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};
