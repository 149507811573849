import React from "react";
import { Button, Accordion, Card, Alert } from "react-bootstrap";
import PropTypes from "prop-types";

const MappedEnums = ({
  array,
  eventKey,
  handleEdit,
  type,
  handleDelete,
  error,
  loading,
}) => {
  const createHeader = (() =>
    type === "category" ? "Lista kategorii" : "Lista rodzajów umów")();
  const errorMessage =
    type === "category"
      ? "Nie udało się pobrać kategorii."
      : "Nie udało się pobrać rodzajów umów.";
  const message = error ? (
    <Alert variant="danger">{errorMessage}</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : null;
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={eventKey}
          className="p-0"
        >
          <h3>{createHeader}</h3>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className="d-flex flex-column">
          {message ||
            array.map((item) => (
              <div className="mb-2" key={item}>
                <h5 className="d-inline-block">{item}</h5>
                <Button
                  variant="primary"
                  className="mx-1"
                  onClick={() => handleEdit(item, type)}
                >
                  Edytuj
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(item, type)}
                >
                  Usuń
                </Button>
              </div>
            ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

MappedEnums.propTypes = {
  array: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventKey: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MappedEnums;
