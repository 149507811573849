import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const attachmentUpload = async (token, step, file) => {
  const url = `${proxy.steps}attachment-upload/`;
  const headers = {
    Authorization: `Token ${token}`,
  };

  const formData = new FormData();
  formData.append("step", step);
  formData.append("image", file);

  const response = await fetch(url, {
    headers,
    body: formData,
    method: "POST",
  });

  if (response.status === 201) {
    return response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const attachmentGet = async (step) => {
  const url = `${proxy.steps}step/${step}/attachment/`;

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const attachmentDelete = async (token, step, id) => {
  const url = `${proxy.steps}step/${id}/attachment/`;

  const headers = {
    Authorization: `Token ${token}`,
  };

  const response = await fetch(url, {
    headers,
    method: "PATCH",
    body: JSON.stringify(step),
  });

  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const attachmentChange = async (step) => {
  const url = `${proxy.steps}step/${step}/attachment/`;

  const response = await fetch(url, {
    method: "DELETE",
  });

  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};

export const choiceUpload = async (token, choice, id) => {
  const url = id ? `${proxy.steps}choice/${id}/` : `${proxy.steps}choice/`;
  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    body: JSON.stringify(choice),
    method: id ? "PATCH" : "POST",
  });

  if (response.status === 200 && id) {
    return response.json();
  } else if (response.status === 201) {
    return response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const getChoice = async (choice) => {
  const url = `${proxy.steps}choice/${choice}/`;
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: "GET",
  });

  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const deleteChoice = async (token, choice) => {
  const url = `${proxy.steps}choice/${choice}/`;
  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: "DELETE",
  });
  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};

export const moveChoice = async (token, relation, dir) => {
  const url = `${proxy.steps}relation/${relation}/${dir}/`;
  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: "PATCH",
  });
  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};

export const getStart = async (signal) => {
  const url = `${proxy.steps}start/`;
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    signal,
    method: "GET",
  });

  if (response.status === 200) {
    return response.json();
  } else if (response.status === 404) {
    throw new Error(response.status);
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const postStart = async (token, title) => {
  const url = `${proxy.steps}start/`;
  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: "POST",
    body: JSON.stringify({ title }),
  });

  if (response.status !== 201) {
    throw new Error(await errorMessageText(response));
  }
  return response.json();
};

export const postStep = async (token, step, id) => {
  const url = id ? `${proxy.steps}step/${id}/` : `${proxy.steps}step/`;
  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: id ? "PATCH" : "POST",
    body: JSON.stringify(step),
  });

  if (response.status === 200 && id) {
    return response.json();
  } else if (response.status === 201 && !id) {
    return response.json();
  }
  throw new Error(await errorMessageText(response));
};

export const deleteStep = async (token, step) => {
  const url = `${proxy.steps}step/${step}/`;
  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: "DELETE",
  });

  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};

export const moveStep = async (token, step, direction) => {
  const url = `${proxy.steps}step/${step}/${direction}/`;
  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: "PATCH",
  });

  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};

// relacje

const createUrl = (url, filters) => {
  let newUrl = url;
  Object.keys(filters).forEach((key, i) => {
    if (typeof filters[key] === "object") {
      filters[key].forEach((value) => {
        newUrl += `&${key}=${value}`;
      });
    } else if (i !== 0) newUrl += `&${key}=${filters[key]}`;
    else newUrl += `${key}=${filters[key]}`;
  });

  return newUrl;
};

export const getRelations = async (token, filters) => {
  const url = filters
    ? createUrl(`${proxy.steps}relations/?`, filters)
    : `${proxy.steps}relations/`;

  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: "GET",
  });
  if (response.status !== 200) {
    throw new Error(await errorMessageText(response));
  } else {
    return response.json();
  }
};

export const getChoices = async (token, filters, signal) => {
  const url = filters
    ? createUrl(`${proxy.steps}choices/?`, filters)
    : `${proxy.steps}choices/`;

  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, signal, {
    headers,
    method: "GET",
  });

  if (response.status !== 200) {
    throw new Error(await errorMessageText(response));
  } else {
    return response.json();
  }
};

export const postRelation = async (token, child, parent) => {
  const url = `${proxy.steps}relation/`;

  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: "POST",
    body: JSON.stringify({ child, parent }),
  });
  if (response.status !== 201) {
    throw new Error(await errorMessageText(response));
  } else {
    return true;
  }
};

export const deleteRelation = async (token, id) => {
  const url = `${proxy.steps}relation/${id}/`;
  const headers = {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    headers,
    method: "DELETE",
  });

  if (response.status !== 204) {
    throw new Error(await errorMessageText(response));
  }
};
