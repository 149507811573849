import React, { useContext, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { staffTypes } from "constants/staffTypes";
import { DeletionModal } from "components";
import { AlertContext } from "context";
import { removeWorkshopComment } from "../api";

const DeleteCommentButton = ({
  user,
  date,
  author,
  commentId,
  refresh,
  setRefresh,
}) => {
  const [deletionModal, setDeletionModal] = useState(false);
  const alert = useRef(useContext(AlertContext));
  const deletionWindow = new Date(date);
  deletionWindow.setMinutes(deletionWindow.getMinutes() + 1);

  const deleteComment = async () => {
    try {
      await removeWorkshopComment(commentId, user.token);
      alert.current.showAlert("Usunięto komentarz.", "success");
      setRefresh(!refresh);
    } catch (err) {
      alert.current.showAlert(err.message);
    }
  };

  if (
    user?.data?.group_type?.includes(staffTypes.BLOG_CREATOR) ||
    (new Date().getTime() < deletionWindow.getTime() &&
      user?.data?.id === author.id)
  ) {
    return (
      <>
        <Button
          onClick={() => setDeletionModal(true)}
          className="blog-delete-comment-button"
          variant="danger"
        >
          usuń komentarz
        </Button>
        <DeletionModal
          question={"Czy chcesz usunąć ten komentarz?"}
          delConfirmed={deleteComment}
          show={deletionModal}
          setShow={setDeletionModal}
        />
      </>
    );
  } else return null;
};

export default DeleteCommentButton;
