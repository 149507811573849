import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { FormGroup } from "components";
import { sendEnum } from "Views/JobOffersEnumsCreator/functions";
import { UserContext, AlertContext } from "context";
import PropTypes from "prop-types";

const EnumForm = ({ type, enumName, refresh }) => {
  const [name, setName] = useState("");
  const [validated, setValidated] = useState(false);

  const user = useContext(UserContext);
  const alertContext = useRef(useContext(AlertContext));

  useEffect(() => {
    if (enumName) {
      setName(enumName);
    }
  }, [enumName]);

  const createTitle = useMemo(() => {
    if (type === "category") {
      if (enumName) {
        return "Edytuj kategorię";
      }
      return "Utwórz kategorię";
    } else {
      if (enumName) {
        return "Edytuj rodzaj umowy";
      }
      return "Utwórz rodzaj umowy";
    }
  }, [enumName, type]);

  const createHeader = (() =>
    type === "category" ? "Nazwa kategorii" : "Podaj rodzaj umowy")();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        const res = await sendEnum(user.token, type, { name }, enumName);
        alertContext.current.showAlert(res.message, "success");
        refresh();
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
    }
    setValidated(true);
  };

  return (
    <Form
      validated={validated}
      noValidate
      onSubmit={(e) => handleSubmit(e)}
      className="mb-3"
    >
      <h3 className="text-center">{createTitle}</h3>
      <FormGroup
        header={createHeader}
        val={name}
        setVal={setName}
        length={{ min: 1, max: 30 }}
        required
        incorrect="To pole jest wymagane"
        id="enum_name"
      />
      <Button variant="primary" type="submit">
        Prześlij
      </Button>
    </Form>
  );
};

EnumForm.propTypes = {
  type: PropTypes.string.isRequired,
  enumName: PropTypes.string,
  refresh: PropTypes.func.isRequired,
};

export default EnumForm;
