import { errorMessageText } from "constants/other.js";
import proxy from "config/api";

export const changePermissions = async (token, id, data) => {
  const url = `${proxy.account}admin/user_details/change_permissions/${id}/`;
  const header = {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Origin: null,
      Authorization: `Token ${token}`,
    },
  };
  const res = await fetch(url, header);
  if (res.status !== 200) {
    throw new Error(await errorMessageText(res));
  }
  return res.status;
};
