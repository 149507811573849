import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import YouTube from "react-youtube";
import { YouTubeGetID } from "constants/other";

const HeaderPreview = ({ url, header }) => {
  const [isPhoto, setIsPhoto] = useState(false);
  useEffect(() => {
    if (
      url.includes("https://www.youtube.com/") ||
      url.includes("https://youtu.be/")
    ) {
      setIsPhoto(false);
    } else {
      setIsPhoto(true);
    }
  }, [url]);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return url || header ? (
    isPhoto ? (
      <Card.Img
        variant="top"
        src={url || URL.createObjectURL(header)}
        alt="Nagłówek posta"
      />
    ) : (
      <div className="videoField__container my-3">
        <YouTube
          className="videoField__video"
          videoId={YouTubeGetID(url)}
          opts={opts}
          onReady={onReady}
        />
      </div>
    )
  ) : null;
};

export default HeaderPreview;
