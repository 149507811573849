import React, { useContext, useRef, useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import { postComment } from "Views/BlogPost/api";
import { AlertContext } from "context";
import DeleteCommentButton from "./DeleteCommentButton";

const BlogComments = ({ comments, user, postId, refresh, setRefresh }) => {
  const alert = useRef(useContext(AlertContext));
  const [newComment, setNewComment] = useState("");

  const addComment = async (e) => {
    e.preventDefault();
    try {
      await postComment(postId, user.token, newComment);
      alert.current.showAlert("Dodano komentarz.", "success");
      setRefresh(!refresh);
    } catch (err) {
      alert.current.showAlert("Wystąpił błąd.");
    }
  };

  return (
    <Row className="blog-comments">
      <Col>
        <Row className="mb-3">Komentarze:</Row>
        {comments.map((comment) => (
          <Row key={comment.id} className="blog-comment-row">
            <div className="blog-comment-author">
              <span className="blog-comment-author">
                {comment.author.username}
              </span>
              <span className="blog-comment-time">
                {" "}
                {new Date(comment.date_created).toLocaleDateString("pl-PL", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
              <DeleteCommentButton
                user={user}
                date={comment.date_created}
                author={comment.author}
                commentId={comment.id}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </div>
            <div className="blog-comment-content">{comment.content}</div>
          </Row>
        ))}
      </Col>
      {user?.token !== undefined ? (
        <Form onSubmit={addComment} className="d-flex comments__form">
          <FormControl
            placeholder="Dołącz do dyskusji..."
            aria-label="Dołącz do dyskusji..."
            aria-describedby="comment-box"
            className="blog-comment-add"
            onChange={(event) => setNewComment(event.target.value)}
          />
          <Button
            variant="primary"
            type="submit"
            className="blog-comment-add-button"
          >
            Dodaj komentarz
          </Button>
        </Form>
      ) : (
        <Row className="p-3 blog-comment-not-logged">
          Zaloguj się, aby móc dodać komentarz.
        </Row>
      )}
    </Row>
  );
};

export default BlogComments;
