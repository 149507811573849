import React, { useState, useRef, useContext, useEffect } from "react";
import { Card, Accordion, Button, Alert, Modal } from "react-bootstrap";
import { getKeys, deleteKey } from "Views/GenerateApiKeys/functions";
import { AlertContext, UserContext } from "context";
import { KeyObject, KeysForm } from "../";
import { DeletionModal } from "components";
import PropTypes from "prop-types";

const KeysList = ({ refresh, handleRefresh }) => {
  const [keys, setKeys] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const alertContext = useRef(useContext(AlertContext));
  const user = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [idActive, setIdActive] = useState(-1);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleDelete = (id) => {
    setShowModal(true);
    setIdActive(id);
  };

  const handleEdit = (id) => {
    setIdActive(id);
    handleShow();
  };

  useEffect(() => {
    const abortController = new AbortController();
    const fetchKeys = async () => {
      if (!abortController.signal.aborted) setLoading(true);
      try {
        const res = await getKeys(user.token, abortController.signal);
        setKeys(res);
      } catch (err) {
        if (!abortController.signal.aborted) setError(true);
      }
      if (!abortController.signal.aborted) setLoading(false);
    };
    fetchKeys();
    return () => abortController.abort();
  }, [user.token, refresh]);

  useEffect(() => {
    const handleDelete = async (id) => {
      try {
        await deleteKey(user.token, id);
        alertContext.current.showAlert("Poprawnie usunięto klucz.", "success");
      } catch (err) {
        alertContext.current.showAlert(err.message);
      }
      setDeletionConfirmed(false);
      handleRefresh();
    };

    if (deletionConfirmed) handleDelete(idActive);
  }, [deletionConfirmed, handleRefresh, idActive, user.token]);

  const message = error ? (
    <Alert variant="danger">Nie udało się pobrać kluczy</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : null;

  return (
    <Accordion defaultActiveKey="0">
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDeletionConfirmed}
        question={"Czy na pewno chcesz usunąć ten klucz?"}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        <KeysForm
          handleRefresh={handleRefresh}
          keyObject={keys.find((key) => key.id === idActive)}
        />
      </Modal>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <h3>Lista istniejących kluczy</h3>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {message ||
              keys.map((key) => (
                <KeyObject
                  item={key}
                  key={key.id}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              ))}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

KeysList.propTypes = {
  refresh: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
};

export default KeysList;
