import React, { useState } from "react";
import { FormGroup } from "components";
import { Form, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const ChatFilter = ({ filter, setFilter, disabled }) => {
  const [full_name, setFullName] = useState("");
  const history = useHistory();

  const filterChat = (e) => {
    e.preventDefault();
    history.push("/chats");
    const fullNameFilter = full_name ? full_name : undefined;
    setFilter({
      ...filter,
      full_name: fullNameFilter,
      page: 1,
    });
  };

  const deleteFilter = () => {
    history.push("/chats");
    setFullName("");
    setFilter({
      ...filter,
      full_name: "",
      page: 1,
    });
  };

  return (
    <Form className="search__form" onSubmit={filterChat}>
      <div>
        <Form.Row>
          <FormGroup
            as={Col}
            xs={12}
            showHeader={false}
            header="Imię i nazwisko"
            val={full_name}
            setVal={setFullName}
            type="text"
            id="full_name"
          />
        </Form.Row>
        <div>
          <Button
            type="submit"
            className="mr-3"
            variant="primary"
            disabled={disabled}
          >
            {disabled ? "Ładowanie..." : "Filtruj użytkowników"}
          </Button>
          <Button
            variant="outline-primary"
            onClick={deleteFilter}
            disabled={disabled}
          >
            {disabled ? "Ładowanie..." : "Wyczyść filtr"}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ChatFilter;
