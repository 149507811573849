import React from "react";
import { Alert } from "react-bootstrap";

export const loadingMessage = (
  isLoadingImg,
  loadingHeader,
  loadingCover,
  loading
) =>
  isLoadingImg ? (
    <Alert variant="info">Ładowanie obrazka...</Alert>
  ) : loadingHeader ? (
    <Alert variant="info">Ładowanie nagłówka...</Alert>
  ) : loadingCover ? (
    <Alert variant="info">Ładowanie covera...</Alert>
  ) : loading ? (
    <Alert variant="info">Wysyłanie posta...</Alert>
  ) : null;
