import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { staffTypes } from "constants/staffTypes";
import { userTypes } from "constants/userTypes";
import { paths } from "constants/paths";

const NewSelectorButton = ({ user }) =>
  user.type === userTypes.STAFF &&
  user.data.group_type.includes(staffTypes.BLOG_MODERATOR) ? (
    <LinkContainer to={paths.BLOG_SELECT}>
      <Button variant="primary">Zarządzanie blogami</Button>
    </LinkContainer>
  ) : null;

export default NewSelectorButton;
