import React from "react";
import { Container, Button, Card, Form } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import LoginForm from "./components/loginForm";
import { UserContext } from "context";
import { sendData, checkOffersCount } from "./functions/sendData";
import proxy from "config/api";
import { withAlertContext } from "components";
import { userTypes } from "constants/userTypes";

class LoginPage extends React.Component {
  state = {
    credentials: null,
    redirect: false,
    validated: false,
    disabled: false,
  };

  handleIncorrectResponse = (status) => {
    status === 400
      ? this.props.alertContext.showAlert("Niepoprawny login lub hasło.")
      : this.props.alertContext.showAlert(
          "Błąd serwera. Proszę spróbować później."
        );
    this.setState({
      redirect: false,
    });
  };

  componentDidMount() {
    if (this.context.token) {
      this.props.history.push("/user");
    }
  }

  handleSubmit = async (event) => {
    this.setState({ disabled: true });
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      this.setState({
        validated: true,
      });

      try {
        const response = await sendData(this.state.credentials);
        const { status } = response;
        if (status === 200) {
          const { token, type } = response; //do poprawy
          const dataRes = await fetch(proxy.account + "data/", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + token,
            },
          });

          if (dataRes.status === 200) {
            const dataValue = await dataRes.json();
            const { data } = dataValue;
            if (type === userTypes.STANDARD) {
              try {
                const response = await checkOffersCount(token);
                this.context.changeOffersForUser(response?.job_count);
              } catch (err) {
                console.log(err);
              }
            }
            this.context.login(token, type, data);
            this.props.history.push("/user");
          } else {
            this.setState({
              validated: false,
            });
            this.props.alertContext.showAlert("Coś poszło nie tak");
          }
        }
      } catch (response) {
        this.handleIncorrectResponse(response);
      }
    }
    this.setState({ disabled: false });
  };

  render() {
    const { validated, disabled } = this.state;
    const { handleSubmit } = this;

    return (
      <Container className="loginPage">
        <Card className="loginPage__card loginPage__card--login">
          <Card.Header as="h2" className="loginPage__header">
            Logowanie
          </Card.Header>
          <Card.Body className="loginPage__body">
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit(e)}
            >
              <LoginForm
                data-testId="loginForm"
                data={this.state.credentials}
                onBlur={(credentials) => this.setState({ credentials })}
              />
              <Button
                data-testid="loginBtn"
                variant="primary"
                className="loginPage__button"
                type="submit"
                disabled={disabled}
              >
                {disabled ? "Ładowanie..." : "Zaloguj"}
              </Button>
            </Form>
            <div className="loginPage__links">
              <Link to="/newPassword">Zapomniałeś hasła?</Link>
              <Link to="/newAccount">Załóż konto!</Link>
            </div>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

LoginPage.contextType = UserContext;

export default withRouter(withAlertContext(LoginPage));
