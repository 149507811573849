import React, { useState, useContext, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FormGroup } from "components";
import { sendDocument } from "Views/DocumentsEditor/DocumentsEditor.api";
import { AlertContext } from "context";
import documents from "constants/documents";
import { approveFileSize } from "utils/approveFile/approveFile";

const DocumentsForm = ({
  handleClose,
  show,
  token,
  prevDocument,
  handleRefresh,
  disabled,
  setDisabled,
}) => {
  const { GENERAL_POLICY, COOKIES_POLICY } = documents;
  const [id, setId] = useState(prevDocument?.id || GENERAL_POLICY);
  const [document, setDocument] = useState(null);

  const alertC = useRef(useContext(AlertContext));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (document === null) {
      alertC.current.showAlert("Musisz podać plik, który chcesz przesłać.");
      return;
    }
    try {
      setDisabled(true);
      await sendDocument(
        token,
        {
          id,
          document,
        },
        prevDocument?.id
      );
      alertC.current.showAlert(
        prevDocument
          ? "Pomyślnie edytowano dokument."
          : "Pomyślnie przesłano dokument.",
        "success"
      );
      handleClose();
      handleRefresh();
    } catch (err) {
      alertC.current.showAlert(err.message);
    }
    setDisabled(false);
  };

  const handlePhotoChange = (file) => {
    if (approveFileSize(file[0])) {
      setDocument(file[0]);
    } else {
      alertC.current.showAlert("Przekazany plik jest za duży(max 15mb)");
    }
    setDocument(file[0]);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {prevDocument ? "Edytuj dokument" : "Dodaj dokument"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body as={Form} onSubmit={handleSubmit}>
        {!prevDocument && (
          <FormGroup
            header="Typ dokumentu"
            val={id}
            type="select"
            array={[GENERAL_POLICY, COOKIES_POLICY]}
            setVal={setId}
            required
            id="document_id"
            length={{ min: 1, max: 100 }}
          />
        )}
        <Form.Group controlId="pdf_file">
          <Form.Label>{"Wybierz plik"}</Form.Label>
          <Form.File
            name="photo"
            label={document !== null ? document?.name : "Wybierz plik"}
            custom
            onChange={(e) => handlePhotoChange(e.target.files)}
            accept="application/pdf"
            data-browse="Dodaj"
            className="overflow-hidden"
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={disabled}>
          Prześlij
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default DocumentsForm;
