import React, { useRef, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { approveFileSize } from "utils/approveFile/approveFile";
import { AlertContext } from "context";

const Cover = ({ setCover }) => {
  const fileInput = useRef(null);
  const [photoName, setPhotoName] = useState("");

  const alertC = useRef(useContext(AlertContext));

  const onPhotoChange = async (files) => {
    if (approveFileSize(files[0])) {
      setPhotoName(files[0].name);
      setCover(files[0]);
    } else {
      alertC.current.showAlert(
        "Wybrany plik jest za duży. Maksymalna wielkość to 15 mb."
      );
    }
  };

  return (
    <Form.File
      name="photo"
      id="blogspot_header_photo"
      label={photoName !== "" ? photoName : "Wybierz zdjęcie (cover) posta"}
      custom
      ref={fileInput}
      onChange={(e) => onPhotoChange(e.target.files)}
      className="mb-3"
      accept="image/*"
      data-browse="Dodaj"
    />
  );
};

export default Cover;
