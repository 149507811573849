import React, { useEffect, useState } from "react";
import { getTopics } from "Views/Blog/api";
import BlogTopic from "../BlogTopic";

const BlogPosts = ({ category }) => {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const loadTopics = async (category) => {
      try {
        let res = await getTopics(category, abortController.signal);
        res.sort((a, b) => a.index - b.index);

        setTopics(res);
      } catch (e) {
        console.log(e);
      }
    };
    loadTopics(category);
    return () => abortController.abort();
  }, [category]);

  return (
    <>
      {topics.map((topic) => (
        <BlogTopic category={category} topic={topic} key={topic.id} />
      ))}
      <BlogTopic
        category={category}
        topic={{ id: null, name: topics.length === 0 ? "" : "Pozostałe posty" }}
      />
    </>
  );
};

export default BlogPosts;
