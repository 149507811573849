import dayjs from "dayjs";

export const weekDays = ["P", "W", "Ś", "C", "PT", "S", "N"];
export const months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];
export const getMonthId = (month) => {
  return months.findIndex((monthName) => monthName === month);
};

export const getISODate = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const TAG_PLACEHOLDER = "--- Tag ---";

export const EVENT_PLACEHOLDER = "--- Link do wydarzenia ---";

export const filterWorkshops = (workshops, labels, year) => {
  let newWorkshops = [];
  workshops.forEach((workshop) => {
    newWorkshops.push({
      ...workshop,
      label: labels.find((label) => label.id === workshop.label)?.year,
    });
  });
  return newWorkshops
    .filter((workshop) => workshop.label >= year)
    .map(({ id, label, title }) => ({
      url: `/workshops/post/${id}`,
      year: label,
      title,
    }));
};
