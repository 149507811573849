import React from "react";
import { Form } from "react-bootstrap";
import { CVEditorTab } from "..";
import movie_1 from "assets/movie_1.png";
import proxy from "config/api";
import { UserContext } from "context";
import { approveFileSize } from "utils/approveFile/approveFile";

class PhotoTab extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      error: false,
    };
  }

  onChange = (e) => {
    const file = this.fileInput.files[0];
    if (!file?.type?.includes("image")) {
      this.setState({ error: true });
    } else if (approveFileSize(file) === true) {
      this.props.onChange(this.fileInput.files[0]);
      this.setState({ error: false });
    } else {
      this.props.alertContext.showAlert(
        "Wybrany plik jest za duży. Maksymalny rozmiar pliku to 15 MB."
      );
      this.setState({ error: false });
      this.fileInput = React.createRef();
    }
  };

  setLabel = () => {
    const label =
      this.props.data !== null
        ? this.props.data.name
        : this.props.hasPhoto
        ? "Poprzednie zdjęcie"
        : "Wybierz zdjęcie";
    return label;
  };

  render() {
    return (
      <CVEditorTab
        title="Zdjęcie"
        movie={movie_1}
        onPrevClick={this.props.onPrevClick}
        onSubmit={this.props.onSubmit}
        comments={this.props.comments}
        loading={this.props.loading}
        error={this.props.error}
        showComments={this.props.showComments}
        disabled={this.props.disabled}
        isNew={this.props.isNew}
        group_type={this.context?.data?.group_type}
        videoName={this.props.videoName}
        formTab={this.props.formTab}
      >
        <Form
          ref={this.props.refValue}
          noValidate
          validated={this.props.validated}
        >
          <Form.Group controlId="photo">
            <Form.Label
              className={
                this?.props?.validated &&
                this?.fileInput?.files?.length === 0 &&
                "is-invalid"
              }
              htmlFor="custom-file"
            >
              Zdjęcie:
            </Form.Label>
            <Form.File
              id="custom-file"
              custom
              label={this.setLabel()}
              ref={(ref) => (this.fileInput = ref)}
              onChange={this.onChange}
              accept="image/*"
              data-browse="Dodaj"
              required={!this.props.hasPhoto}
              // value="this.props.data"
            />
            {!this.props.hasPhoto && (
              <Form.Control.Feedback type="invalid">
                Zdjęcie jest wymagane.
              </Form.Control.Feedback>
            )}
            {this.state.error && (
              <small className="text-danger">
                Wymagane zdjęcie w formacie np. *.jpg lub *.png.
              </small>
            )}
          </Form.Group>
          {this.props.templateList?.length > 0 && (
            <Form.Group>
              <Form.Label>
                Szablon CV: (
                <a
                  target="_blank"
                  href={
                    proxy.plainFiles +
                    "/static/cv/examples/" +
                    this.props.template +
                    ".pdf"
                  }
                  rel="noopener noreferrer"
                >
                  Kliknij <u>tutaj</u> żeby zobaczyć przykładowe CV z użyciem
                  tego szablonu.
                </a>
                )
              </Form.Label>
              <Form.Control
                as="select"
                id="template"
                value={this.props.template}
                onChange={(e) => this.props.setTemplate(e.target.value)}
              >
                {this.props.templateList.map((item) => (
                  <option
                    key={item}
                    value={item}
                    style={{ backgroundColor: item }}
                  >
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
        </Form>
      </CVEditorTab>
    );
  }
}

PhotoTab.contextType = UserContext;

export default PhotoTab;
