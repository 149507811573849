import React, { useEffect, useState } from "react";
import { getLabels } from "Views/BlogSelectorsCreator/functions";
import { FormGroup } from "components";
import { Alert, Button, Form } from "react-bootstrap";
import { voivodeships } from "constants/voivodeships";
import { Link } from "react-router-dom";

const LabelChooser = ({ post, setPost, token }) => {
  const [labels, setLabels] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLabels = async () => {
      setLoading(true);
      try {
        const res = await getLabels(token);
        setLabels(res);
        setPost((prevState) => ({
          ...prevState,
          voivodeship: voivodeships[0],
          label: res[0]?.id,
          city: "",
        }));
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    };
    fetchLabels();
  }, [setPost, token]);

  const message = error ? (
    <Alert variant="danger">Nie udało się załadować lat</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : labels.length === 0 ? (
    <Alert variant="info">
      Brak dodanych warsztatów. Przejdź{" "}
      <Link
        to="/blog/create/newSelect"
        rel="noopener noreferrer"
        target="_blank"
      >
        tutaj
      </Link>{" "}
      aby dodać nowy rok.
    </Alert>
  ) : null;

  return (
    <div className="postCreator__workshop">
      {message}
      <p className="d-inline mr-1">
        Czy można aplikować na warsztat:
        {post.can_apply ? (
          <span className="text-success ml-1">TAK</span>
        ) : (
          <span className="text-danger ml-1">NIE</span>
        )}
      </p>
      <Button
        onClick={() =>
          setPost((prevState) => ({
            ...prevState,
            can_apply: !prevState.can_apply,
          }))
        }
      >
        Zmień
      </Button>
      <Form.Group controlId="post_label">
        <Form.Label>Wybierz rok w którym odbyły się warsztaty</Form.Label>
        <Form.Control
          as="select"
          value={post.label}
          onChange={(e) => {
            const label = e.target.value;
            return setPost((prevState) => ({ ...prevState, label }));
          }}
        >
          {labels.map((label) => (
            <option key={label.id} value={label.id}>
              {label.year}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <FormGroup
        header="Wybierz województwo w którym odbyły się warsztaty"
        type="select"
        array={voivodeships}
        val={post.voivodeship}
        setVal={(voivodeship) =>
          setPost((prevState) => ({ ...prevState, voivodeship }))
        }
        id="post_voivodeship"
      />
      <FormGroup
        header="Wpisz miasto w którym odbyły się warsztaty"
        val={post.city || ""}
        setVal={(city) => setPost((prevState) => ({ ...prevState, city }))}
        id="post_city"
      />
    </div>
  );
};

export default LabelChooser;
