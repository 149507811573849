import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { getExperts } from "./api";
import background from "assets/backgrounds/zapytaj.jpg";
import Expert from "./components/Expert";
import { UserContext } from "context";

const AskExpert = () => {
  const user = useContext(UserContext);
  const [experts, setExperts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const loadExperts = async () => {
      if (!abortController.signal.aborted) {
        setLoading(true);
      }
      try {
        let res = await getExperts(abortController.signal);
        setExperts(res);
      } catch (e) {
        if (!abortController.signal.aborted) {
          setError(true);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    };
    loadExperts();
    return () => abortController.abort();
  }, []);

  return (
    <Container className="ask-expert-container">
      <img src={background} alt="background" className="w-100" />

      <p className="descriptions d-flex flex-column">
        <span className="mb-3">
          Jesteśmy do Twojej dyspozycji: psycholog, prawnik, księgowy, ekspert
          ds. usamodzielnienia, doradca zawodowy. Wiemy jakie są Twoje prawa i
          obowiązki w placówce.{" "}
        </span>{" "}
        <span> Jesteśmy tu po to, żeby Ci pomóc. Napisz!</span>
      </p>
      {loading ? <div className="ask-expert-banner">ŁADOWANIE</div> : null}
      {error ? <div className="ask-expert-banner">WYSTĄPIŁ BŁĄD</div> : null}
      {experts.length === 0 && !loading && !error ? (
        <div className="ask-expert-banner">BRAK EKSPERTÓW</div>
      ) : (
        <div className="ask-expert-experts">
          {experts.map((expert) => (
            <Expert expert={expert} user={user} key={expert.id} />
          ))}
        </div>
      )}
    </Container>
  );
};

export default AskExpert;
