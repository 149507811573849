import React from "react";
import { Badge } from "react-bootstrap";

const CalendarTile = ({ children, handleClick, events }) => {
  return (
    <div onClick={handleClick} className={`calendar__tile`}>
      <p className="calendar__number">{children}</p>
      {events?.map(({ id, label, name }) => (
        <Badge
          variant="primary"
          className="calendar__label"
          key={id}
          style={{ backgroundColor: label?.color }}
        >
          {name}
        </Badge>
      ))}
    </div>
  );
};

export default CalendarTile;
