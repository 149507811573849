import React, { useEffect, useState, useContext, useRef } from "react";
import { getContacts, deleteContact } from "Views/ContactPage/functions";
import { Button, Alert } from "react-bootstrap";
import { Contact, NewContact } from "../";
import { UserContext, AlertContext } from "context";
import { DeletionModal } from "components";
import { staffTypes } from "constants/staffTypes";
import PropTypes from "prop-types";
import getBase64Image from "utils/converters/imageToBase64";

const ContactList = ({ refresh, refreshData }) => {
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);

  const [editedContact, setEditedContact] = useState(null);
  const [idDel, setIdDel] = useState(-1);

  const [show, setShow] = useState(false);

  const user = useContext(UserContext);
  const alertC = useRef(useContext(AlertContext));

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (!navigator.onLine) {
      const localContacts = JSON.parse(window.localStorage.getItem("contacts"));
      setContacts(localContacts);
    }
  }, []);

  useEffect(() => {
    const fetchContacts = async () => {
      setLoading(true);
      try {
        const res = await getContacts();
        setContacts(res);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    };
    if (navigator.onLine) {
      fetchContacts();
    }
  }, [refresh]);

  useEffect(() => {
    const getItem = async (item) => {
      let newRes = { ...item };
      if (item.logo_url) {
        const newSrc = await getBase64Image(item.logo_url);
        newRes.logo_url = newSrc;
      }
      return newRes;
    };
    const setLocalStorage = async () => {
      try {
        const localContacts = await Promise.all(contacts.map(getItem));
        window.localStorage.setItem("contacts", JSON.stringify(localContacts));
      } catch (err) {}
    };
    if (navigator.onLine) {
      setLocalStorage();
    }
  }, [contacts]);

  useEffect(() => {
    const handleDelete = async () => {
      setLoading(true);
      try {
        await deleteContact(user.token, idDel);
        alertC.current.showAlert("Pomyślnie usunięto kontakt.", "success");
        setDeletionConfirmed(false);
        refreshData();
      } catch (err) {
        setDeletionConfirmed(false);
        alertC.current.showAlert(err.message);
      }
    };
    if (deletionConfirmed) handleDelete();
  }, [deletionConfirmed, idDel, refreshData, user.token]);

  const message = error ? (
    <Alert variant="danger">Nie udało się załadować kontaktów</Alert>
  ) : loading ? (
    <Alert variant="info">Ładowanie...</Alert>
  ) : null;

  return (
    <div>
      <DeletionModal
        show={showModal}
        setShow={setShowModal}
        delConfirmed={setDeletionConfirmed}
        question={"Czy na pewno chcesz usunąć ten kontakt?"}
      />
      <NewContact
        show={show}
        handleClose={handleClose}
        token={user.token}
        contact={editedContact}
        refresh={refreshData}
      />
      {message ||
        contacts.map((contact) => (
          <div className="contactList__wrapper" key={contact.id}>
            {user?.data?.group_type?.includes(staffTypes.BLOG_MODERATOR) && (
              <>
                <Button
                  className="mr-1 contact__button"
                  onClick={() => {
                    setShow(true);
                    setEditedContact(contact);
                  }}
                >
                  Edytuj kontakt
                </Button>
                <Button
                  className="contact__button"
                  variant="danger"
                  onClick={() => {
                    setShowModal(true);
                    setIdDel(contact.id);
                  }}
                >
                  Usuń kontakt
                </Button>
              </>
            )}
            <Contact contact={contact} />
          </div>
        ))}
    </div>
  );
};

ContactList.propTypes = {
  refresh: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default ContactList;
