import React from "react";
import { Card, Container, Tab, Tabs } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import logo from "assets/backgrounds/kreatorcv.jpg";
import {
  PersonalDataTab,
  EducationTab,
  WorkExperienceTab,
  LanguagesTab,
  SkillsTab,
  PhotoTab,
} from "./components";
import { UserContext } from "context";
import { sendData, getFeedback } from "Views/CVEditorPage/functions/other.js";
import { createCVObject } from "Views/CVEditorPage/functions/createCVObject.js";
import { withRouter } from "react-router-dom";
import { fetchTemplateList, getCVdata } from "./functions/other";
import { mapData, mapFeedback } from "./functions/mapData";
import { withAlertContext } from "components";
import { staffTypes } from "constants/staffTypes";

class CVEditorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formTab: "personalData",
      tabs: {
        personalData: {
          data: null,
          refValue: React.createRef(),
          comments: undefined,
        },
        education: { data: null, comments: undefined },
        workExperience: { data: null, comments: undefined },
        skills: { data: null, comments: undefined },
        languages: { data: null, comments: undefined },
        interests: { data: null, comments: undefined },
        photo: { data: null, refValue: React.createRef(), comments: undefined },
      },
      loading: false,
      commentsError: false,
      showComments: true,
      disabled: false,
      validated: false,
      fetchError: false,
      method: "POST",
      cv_id: undefined,
      has_photo: false,
      template: "",
      templateList: [],
    };
    this.tabs = [];
  }

  abortController = new AbortController();

  onPrevClick = () => {
    const { formTab } = this.state;
    const tabIndex = this.tabs.findIndex((tab) => tab.id === formTab);
    this.setState({ formTab: this.tabs[tabIndex - 1].id });
  };

  onNextClick = () => {
    const { formTab } = this.state;
    const tabIndex = this.tabs.findIndex((tab) => tab.id === formTab);
    this.setState({ formTab: this.tabs[tabIndex + 1].id });
  };

  checkValidity = () => {
    const { tabs } = this.state;

    if (tabs.personalData.refValue.current.checkValidity() === false) {
      this.setState({ formTab: "personalData" });
      return false;
    }
    if (!tabs.education.data?.length) {
      this.setState({ formTab: "education" });
      return false;
    }
    if (!tabs.skills.data?.length) {
      this.setState({ formTab: "skills" });
      return false;
    }
    if (!tabs.languages.data?.length) {
      this.setState({ formTab: "languages" });
      return false;
    }
    if (!tabs.interests.data?.length) {
      this.setState({ formTab: "interests" });
      return false;
    }

    if (tabs.photo.refValue.current.checkValidity() === false) {
      this.setState({ formTab: "photo" });
      return false;
    }
    return true;
  };

  handleCVSubmit = async (e) => {
    this.setState({ disabled: true, validated: true });
    e.preventDefault();
    const validity = this.checkValidity();
    if (!validity) {
      this.props.alertContext.showAlert(
        "Nie wszystkie pola zostały poprawnie uzupełnione."
      );
      e.stopPropagation();
      this.setState({ disabled: false });
    } else {
      let cv = createCVObject(
        this.state.tabs.personalData.data,
        this.state.tabs.education.data,
        this.state.tabs.workExperience.data,
        this.state.tabs.skills.data,
        this.state.tabs.languages.data,
        this.state.tabs.interests.data
      );

      cv = { ...cv, template: this.state.template };
      try {
        await sendData(
          cv,
          this.state.tabs.photo.data,
          this.context.token,
          this.state.method,
          this.state.cv_id
        ).then(() => this.setState({ disabled: false }));
        this.props.history.push("/myCVs");
        this.props.alertContext.showAlert("Pomyślnie utworzono CV", "success");
      } catch (err) {
        this.setState({ disabled: false });
        this.props.alertContext.showAlert(err.message);
      }
    }
  };

  getTabs = () => {
    const getTabProps = (key) => {
      return {
        ...this.state.tabs[key],
        onChange: (data) =>
          this.setState((prevState) => ({
            tabs: {
              ...prevState.tabs,
              [key]: { ...prevState.tabs[key], data },
            },
          })),
        onPrevClick: this.onPrevClick,
        onNextClick: this.onNextClick,
        loading: this.state.loading,
        error: this.state.commentsError,
        showComments: this.state.showComments,
        validated: this.state.validated,
        isNew: this.state.method === "POST",
        formTab: this.state.formTab === key,
      };
    };
    return [
      {
        id: "personalData",
        name: "Dane osobowe",
        component: (
          <PersonalDataTab
            {...getTabProps("personalData", 1)}
            onPrevClick={undefined}
            videoName={"personal"}
          />
        ),
      },
      {
        id: "education",
        name: "Edukacja",
        component: (
          <EducationTab
            {...getTabProps("education", 2)}
            videoName={"education"}
          />
        ),
      },
      {
        id: "workExperience",
        name: "Doświadczenie zawodowe",
        component: (
          <WorkExperienceTab
            {...getTabProps("workExperience", 3)}
            videoName={"workExperience"}
          />
        ),
      },
      {
        id: "skills",
        name: "Umiejętności",
        component: (
          <SkillsTab
            {...getTabProps("skills")}
            skills
            videoName={"skillsReal"}
          />
        ),
      },
      {
        id: "languages",
        name: "Języki obce",
        component: (
          <LanguagesTab
            {...getTabProps("languages", 2)}
            videoName={"languages"}
          />
        ),
      },
      {
        id: "interests",
        name: "Zainteresowania",
        component: (
          <SkillsTab {...getTabProps("interests")} videoName={"skills"} />
        ),
      },
      {
        id: "photo",
        name: "Zdjęcie",
        component: (
          <PhotoTab
            {...getTabProps("photo", 1)}
            onNextClick={undefined}
            onSubmit={this.handleCVSubmit}
            disabled={this.state.disabled}
            hasPhoto={this.state.has_photo}
            template={this.state.template}
            setTemplate={(t) => this.setState({ template: t })}
            templateList={this.state.templateList}
            alertContext={this.props.alertContext}
            videoName={"photo"}
          />
        ),
      },
    ];
  };

  autofillEditor = async (id) => {
    let feedbackRes, cvRes, feedback, data;
    const { abortController } = this;
    try {
      this.setState({
        loading: true,
        cv_id: id,
        method: "PUT",
      });
      cvRes = await getCVdata(this.context.token, id, abortController.signal);

      if (cvRes.was_reviewed && !cvRes.is_verified) {
        try {
          feedbackRes = await getFeedback(
            this.context.token,
            id,
            abortController.signal
          );

          feedback = mapFeedback(feedbackRes);
          Object.keys(feedback).forEach((item) => {
            this.setState((prevState) => ({
              tabs: {
                ...prevState.tabs,
                [item]: { ...prevState.tabs[item], comments: feedback[item] },
              },
            }));
          });
        } catch (err) {
          if (!abortController.signal.aborted) {
            this.props.alertContext.showAlert(err.message);
          }
        } finally {
          if (!abortController.signal.aborted) {
            this.setState({
              loading: false,
            });
          }
        }
      } else {
        this.setState({
          showComments: false,
        });
      }
      data = mapData(cvRes);
      Object.keys(data).forEach((item) => {
        this.setState((prevState) => ({
          tabs: {
            ...prevState.tabs,
            [item]: { ...prevState.tabs[item], data: data[item] },
          },
        }));
      });
      this.setState({
        template: cvRes.template,
        has_photo: cvRes.has_picture,
      });
    } catch (err) {
      if (!abortController.signal.aborted) {
        this.setState({
          loading: false,
        });
        this.props.alertContext.showAlert(err.message);
      }
    }
  };

  getTemplates = async () => {
    const { abortController } = this;
    let res;
    try {
      const { templates } = await fetchTemplateList(abortController.signal);
      res = templates;
      this.setState({
        template: res[0],
        templateList: res,
      });
    } catch (err) {
      if (!abortController.signal.aborted) {
        this.props.alertContext.showAlert(err.message);
        res = [];
      }
    }
  };

  componentDidMount() {
    let cvId = this.props.match.params.id;

    this.getTemplates();

    if (cvId) {
      this.autofillEditor(cvId);
    } else {
      if (!this.abortController.signal.aborted) {
        this.setState({ showComments: false });
      }
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    this.tabs = this.getTabs();
    return (
      <Container>
        <img src={logo} alt="Logo - kreator cv" />
        <h1 className="text-align-center mt-3">Stwórz swoje CV</h1>

        <p className="descriptions-cv d-flex flex-column">
          <span className="mb-2">
            HEJ! Z pomocą tutoriali filmowych nagranych przez Sebastiana
            Kulczyka napiszesz swoje CV w kilka minut!
          </span>
          <span className="mb-2">
            Prześlij nam dokument, a my go sprawdzimy i odeślemy z sugestiami
            zmian.
          </span>
          <span className="mt-4">Pisanie CV nigdy nie było tak proste!</span>
        </p>
        <Card className="w-100">
          <Card.Body>
            <Tabs
              transition={false}
              activeKey={this.state.formTab}
              onSelect={(e) => {
                this.setState({ formTab: e });
              }}
              className="CVEditorPage_tabs mb-1" // https://github.com/react-bootstrap/react-bootstrap/issues/4771
            >
              {this.tabs.map((tab) => (
                <Tab eventKey={tab.id} key={tab.id} title={tab.name}>
                  {tab.component}
                </Tab>
              ))}
            </Tabs>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

CVEditorPage.contextType = UserContext;

export default withRouter(withAlertContext(CVEditorPage));
