import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { StepForm } from "Views/Steps/components";
import PropTypes from "prop-types";

const AddStepButton = ({ choice, token, refresh }) => {
  const [showCreateStep, setShowCreateStep] = useState(false);
  return (
    <>
      <StepForm
        show={showCreateStep}
        choice={choice?.id}
        token={token}
        refresh={refresh}
        handleHide={() => setShowCreateStep(false)}
      />
      <Button
        className="mr-1 steps-choice-edit-button"
        onClick={() => setShowCreateStep(true)}
      >
        Dodaj krok
      </Button>
    </>
  );
};

AddStepButton.propTypes = {
  choice: PropTypes.shape({
    id: PropTypes.number,
    level: PropTypes.number.isRequired,
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        level: PropTypes.number.isRequired,
      })
    ),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        description: PropTypes.string,
        attachment_url: PropTypes.string,
      })
    ),
    previous: PropTypes.number,
    title: PropTypes.string.isRequired,
  }),
  token: PropTypes.string.isRequired,
  refresh: PropTypes.func.isRequired,
};

export default AddStepButton;
