import proxy from "config/api";
import { errorMessageText } from "constants/other";

export const getWorkshops = async (signal) => {
  let url = proxy.plain + "/workshops/labels/";
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers, signal });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw response.status;
  }
};

export const getWorkshopPosts = async (year, signal) => {
  let url = proxy.plain + "/workshops/?label=" + year;
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers, signal });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw response.status;
  }
};

export const sendWorkshopApplication = async (application) => {
  let url = proxy.plain + "/workshops/send-application/";
  let data = {
    content: application,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data.content),
  });
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};
