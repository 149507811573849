import React, { useState, useContext, useRef, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FormGroup } from "components";
import { sendContact } from "Views/ContactFoundation/ContactFoundation.api";
import { AlertContext } from "context";

const ContactForm = ({
  handleClose,
  show,
  token,
  sections,
  contact,
  handleRefresh,
  disabled,
  setDisabled,
}) => {
  const [name, setName] = useState(contact?.name || "");
  const [phone_number, setPhoneNumber] = useState(contact?.phone_number || "");
  const [email, setEmail] = useState(contact?.email || "");
  const [validated, setValidated] = useState(false);
  const [section, setSection] = useState(1);

  useEffect(() => {
    setSection(contact?.section || sections[0]?.id);
  }, [contact, sections]);

  const alertC = useRef(useContext(AlertContext));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        setDisabled(true);
        await sendContact(
          token,
          {
            name,
            phone_number: phone_number || null,
            email: email || null,
            section,
          },
          contact?.id
        );
        alertC.current.showAlert(
          contact
            ? "Pomyślnie edytowano kontakt."
            : "Pomyślnie przesłano kontakt.",
          "success"
        );
        handleClose();
        handleRefresh();
      } catch (err) {
        alertC.current.showAlert(err.message);
      }
      setDisabled(false);
    }
    setValidated(true);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {contact ? "Edytuj kontakt" : "Dodaj kontakt"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        as={Form}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="formSection">
          <Form.Label>Sekcja</Form.Label>
          <Form.Control
            as="select"
            value={section}
            onChange={(e) => setSection(e.target.value)}
            required
          >
            {sections.map((section) => (
              <option key={section.id} value={section.id}>
                {section.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <FormGroup
          header="Imię i nazwisko"
          val={name}
          setVal={setName}
          required
          id="name"
          incorrect="Imię i nazwisko jest wymagane."
          length={{ min: 1, max: 100 }}
        />

        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Podaj swój adres mailowy"
            value={email}
            minLength={1}
            maxLength={50}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <FormGroup
          header="Numer telefonu"
          type="tel"
          id="phone_tel"
          val={phone_number}
          setVal={setPhoneNumber}
          length={{ min: 1, max: 50 }}
        />
        <Button variant="primary" type="submit" disabled={disabled}>
          Prześlij
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ContactForm;
