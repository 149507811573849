import proxy from "config/api";
import { errorMessageText } from "constants/other";

export * from "./helpers";

export const deleteImages = async (
  token,
  post,
  imgs,
  headersToDelete,
  isPost
) => {
  let imagesToDelete = [];

  const headers = {
    Authorization: "Token " + token,
  };

  imgs.forEach(({ attachment_url, id }) =>
    post.includes(attachment_url) ? null : imagesToDelete.push(id)
  );

  const toDelete = imagesToDelete.concat(headersToDelete);

  await Promise.all(
    toDelete.map((imgID) =>
      fetch(
        isPost
          ? `${proxy.blog}attachments/${imgID}/`
          : `${proxy.workshops}attachments/${imgID}/`,
        { method: "DELETE", headers }
      )
    )
  );
};

export const getPost = async (token, id, isPost) => {
  let url = isPost
    ? `${proxy.blog}blogpost/${id}/`
    : `${proxy.workshops}${id}/`;
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(url, { method: "GET", headers });

  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const getTopics = async (token, categoryIds, signal) => {
  const headers = {
    Authorization: "Token " + token,
    "Content-Type": "application/json",
  };

  const responses = await Promise.all(
    categoryIds.map((id) =>
      fetch(`${proxy.blog}category/${id}/topics/`, {
        signal,
        method: "GET",
        headers,
      })
    )
  );

  const responseArr = await Promise.all(
    responses.map(async (topic, i) => {
      const topicsArr = await topic.json();
      return { topics: topicsArr, category: categoryIds[i] };
    })
  );

  return responseArr;
};

export const getCategories = async (token, signal) => {
  const urlC = proxy.blog + "categories/";
  const headers = {
    Authorization: "Token " + token,
    "Content-Type": "application/json",
  };
  const response = await fetch(urlC, { signal, method: "GET", headers });
  if (response.status === 200) {
    return response.json();
  }
  throw new Error(await errorMessageText(response));
};

export const postBlogPost = async (data, token, id, isEdited, isPost) => {
  let url = isPost ? proxy.blog + "blogpost/" : `${proxy.workshops}new/`;
  let method = "";
  if (isEdited && isPost) {
    url = `${url}${id}/`;
    method = "PUT";
  } else if (isEdited && !isPost) {
    url = `${proxy.workshops}${id}/`;
    method = "PUT";
  } else {
    data = { ...data, id: id };
    method = "POST";
  }
  const headers = {
    Authorization: "Token " + token,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: method,
    headers,
    body: JSON.stringify(data),
  });

  let status = method === "PUT" ? 200 : 201;

  if (response.status === status) {
    return true;
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const deletePhoto = async (id, token, isPost) => {
  const responseDel = await fetch(
    isPost
      ? `${proxy.blog}attachments/${id}/`
      : `${proxy.workshops}attachments/${id}/`,
    {
      method: "DELETE",
      headers: { Authorization: "Token " + token },
    }
  );
  if (responseDel.status === 200) {
    return responseDel.json();
  } else {
    throw new Error(await errorMessageText(responseDel));
  }
};

export const uploadPhoto = async (id, photo, token, signal, isPost, flag) => {
  const formData = new FormData();
  formData.append("file", photo, photo.name);
  if (flag) {
    formData.append(flag, true);
  }

  const url = isPost
    ? `${proxy.blog}blogpost/${id}/attachment-upload/`
    : `${proxy.workshops}${id}/attachment-upload/`;
  const headers = {
    Authorization: "Token " + token,
  };

  const response = await fetch(url, {
    signal,
    method: "POST",
    headers,
    body: formData,
  });

  if (response.status === 200) {
    return response.json();
  } else {
    throw new Error(await errorMessageText(response));
  }
};

export const reserveSpace = async (token, isPost) => {
  const url = isPost
    ? proxy.blog + "blogpost/reservation/"
    : `${proxy.workshops}reservation/`;
  const headers = {
    Authorization: "Token " + token,
    "Content-Type": "application/json",
  };
  const res = await fetch(url, { method: "POST", headers });
  if (res.status === 201) {
    return await res.json();
  } else {
    throw new Error(await errorMessageText(res));
  }
};

export const getAttachments = async (token, id, isPost) => {
  const url = isPost
    ? `${proxy.blog}blogpost/${id}/attachment-list/`
    : `${proxy.workshops}${id}/attachments/`;
  const headers = {
    Authorization: "Token " + token,
    "Content-Type": "application/json",
  };
  const res = await fetch(url, { method: "GET", headers });
  if (res.status === 200) {
    return await res.json();
  } else {
    throw new Error(await errorMessageText(res));
  }
};

export const getShapes = async (token) => {
  const url = `${proxy.blog}shapes/`;
  const headers = {
    Authorization: "Token " + token,
    "Content-Type": "application/json",
  };
  const res = await fetch(url, { method: "GET", headers });
  if (res.status === 200) {
    return await res.json();
  } else {
    throw new Error(await errorMessageText(res));
  }
};
