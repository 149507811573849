import * as React from "react";
import { Button } from "react-bootstrap";

import { resendEmail } from "./ResendAfter16.api";

import { useUserContext } from "context";
import { useMutation } from "hooks";

export const TERMS_NOT_ACCEPTED = "terms_not_accepted";

const ResendAfter16 = () => {
  const {
    token,
    data: { status, over_16_years },
  } = useUserContext();

  const { loading, onSubmit } = useMutation(
    () => resendEmail(token),
    "Pomyślnie przesłano regulamin na maila."
  );

  return (
    status === TERMS_NOT_ACCEPTED &&
    over_16_years && (
      <Button onClick={onSubmit} data-testid="submit" disabled={loading}>
        Prześlij maila z prośbą o akceptacje regulaminu
      </Button>
    )
  );
};

export default ResendAfter16;
