import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "context";
import { Button, Card, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDocuments } from "Views/DocumentsEditor/DocumentsEditor.api";
import documents from "constants/documents";
import proxy from "config/api";

const CookiesContainer = () => {
  const { COOKIES_POLICY } = documents;
  const user = useContext(UserContext);
  const [cookies, setCookies] = useState(user.cookiesAccepted);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [documentLink, setDocumentLink] = useState("/error");

  const acceptCookies = () => {
    user.changeCookiesAccepted(true);
    setCookies(true);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDocument = async () => {
      if (!abortController.signal.aborted) {
        setLoading(true);
      }
      try {
        const response = await getDocuments(abortController.signal);

        const cookiesDoc = response.find(
          (document) => document.id === COOKIES_POLICY
        )?.document_url;
        if (!cookiesDoc) {
          setError(true);
        } else {
          setDocumentLink(cookiesDoc);
        }
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(true);
        }
      }
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    };
    fetchDocument();

    return () => abortController.abort();
  }, [user.token, COOKIES_POLICY]);

  const message = loading ? <Alert variant="info">Ładowanie...</Alert> : null;

  return (
    <>
      {cookies === undefined ? (
        <Card className="cookiesModal">
          <Card.Header>
            <Card.Title>Używamy plików cookies</Card.Title>
          </Card.Header>

          <Card.Body>
            W celu administrowania, świadczenia usług, analizy ruchu na stronie
            oraz dostosowywania jej treści do Twoich potrzeb, używamy plików
            cookies. Poniżej możesz się zgodzić na przechowywanie niektórych z
            nich. Żeby dowiedzieć się więcej, sprawdź naszą
            {error ? (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className=""
                to={"/error"}
                disabled={loading}
              >
                {" "}
                Politykę Prywatności i Cookies
              </Link>
            ) : (
              <Button
                className="p-0 ml-2"
                variant="link"
                target="_blank"
                rel="noopener noreferrer"
                disabled={loading}
                href={`${proxy.plain}${documentLink}`}
              >
                Politykę Prywatności i Cookies
              </Button>
            )}
          </Card.Body>
          <Card.Footer as={Row} className="justify-content-end m-0">
            {message || (
              <Button variant="primary" onClick={acceptCookies}>
                Akceptuje i przechodzę do strony
              </Button>
            )}
          </Card.Footer>
        </Card>
      ) : null}
    </>
  );
};

export default CookiesContainer;
