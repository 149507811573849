import React from "react";
import placeholder from "assets/expert.png";
import button from "assets/expert-button.svg";
import proxy from "config/api";
import { LinkContainer } from "react-router-bootstrap";
import YouTube from "react-youtube";
import { YouTubeGetID } from "constants/other";
const Expert = ({ expert, user }) => {
  return (
    <div className="ask-expert-row">
      <div className="ask-expert-info-col">
        <div className="d-flex align-items-center mb-3">
          <div className="ask-expert-pic-col">
            <img
              src={
                expert.picture === null
                  ? placeholder
                  : proxy.plainFiles + expert.picture
              }
              alt="expert_pic"
              className="ask-expert-pic"
            />
          </div>

          <div className="d-flex flex-column">
            <div className="ask-expert-info-role">
              <h4>{expert.role}</h4>
            </div>
            <div className="ask-expert-info-name">
              <h5>
                {expert.first_name} {expert.last_name}
              </h5>
            </div>
          </div>
        </div>
        <div className="ask-expert-info-presentation">
          {expert.presentation}
        </div>
        <LinkContainer
          to={user.token !== undefined ? "/chats/" + expert.username : "/login"}
          className={"ask-expert-link"}
        >
          <div className="d-flex flex-row align-items-center">
            <img
              src={button}
              alt={"btn"}
              className="ask-expert-info-button-img"
            />
            <span className="ask-expert-button-text">Napisz do mnie</span>
          </div>
        </LinkContainer>
      </div>
      {expert.video && (
        <div className="ask-expert-video-col">
          <YouTube
            videoId={YouTubeGetID(expert.video)}
            className={"ask-expert-video"}
          />
        </div>
      )}
    </div>
  );
};

export default Expert;
